import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { DatePicker, notification } from 'antd';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input/input';

import { accountTypes, onboardTypes, isValidEmail } from '../../helper';
import { getCampDetailService, onBoardingService } from '../../services/userServices';
import SecureInput from '../payment/SecureInput';

/** *
const  disabledDate = (current) => {
    return current && (current > moment().startOf('day') || current < moment(`${new Date().getFullYear() - 100}-10-23`).startOf('day'));
}
/** */

const OnBoarding = props => {
    const [onboardType, setOnboardType] = useState('');
    const [dob, setDob] = useState('');
    const [address_line1, setAddress_line1] = useState('');
    const [address_city, setAddress_city] = useState('');
    const [address_state, setAddress_state] = useState('');
    const [address_postal_code, setAddress_postal_code] = useState('');
    const [address_country, setAddress_country] = useState('');
    const [ssn, setSsn] = useState('');
    const [taxId, setTaxId] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [routing_number, setRouting_number] = useState('');
    const [account_number, setAccount_number] = useState('');
    const [account_nickname, setAccount_nickname] = useState('');
    const [account_owner_name, setAccount_owner_name] = useState('');
    const [subAccountName, setSubAccountName] = useState('');
    const [url, setUrl] = useState('');
    const [type, setType] = useState('');
    const [industry, setIndustry] = useState('');
    const [structure, setStructure] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [businessEmail, setBusinessEmail] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [legalName, setLegalName] = useState('');
    const [legalCity, setLegalCity] = useState('');
    const [legalState, setLegalState] = useState('');
    const [legalPostal, setLegalPostal] = useState('');
    const [legalCountry, setLegalCountry] = useState('');
    const [title, setTitle] = useState('');
    const [legalLine, setLegalLine] = useState('');
    const [owners, setOwners] = useState([
        {
            name: '',
            email: '',
            nameErr: '',
            emailErr: '',
        },
    ]);

    const [onboardTypeErr, setOnboardTypeErr] = useState('');
    const [dobErr, setDobErr] = useState('');
    const [address_line1Err, setAddress_line1Err] = useState('');
    const [address_cityErr, setAddress_cityErr] = useState('');
    const [address_stateErr, setAddress_stateErr] = useState('');
    const [address_postal_codeErr, setAddress_postal_codeErr] = useState('');
    const [address_countryErr, setAddress_countryErr] = useState('');
    const [ssnErr, setSsnErr] = useState('');
    const [taxIdErr, setTaxIdErr] = useState('');
    const [accountNameErr, setAccountNameErr] = useState('');
    const [accountTypeErr, setAccountTypeErr] = useState('');
    const [routing_numberErr, setRouting_numberErr] = useState('');
    const [account_numberErr, setAccount_numberErr] = useState('');
    const [account_nicknameErr, setAccount_nicknameErr] = useState('');
    const [account_owner_nameErr, setAccount_owner_nameErr] = useState('');
    const [urlErr, setUrlErr] = useState('');
    // const [typeErr, setTypeErr] = useState('');
    const [industryErr, setIndustryErr] = useState('');
    const [subAccountNameErr, setSubAccountNameErr] = useState('');
    const [structureErr, setStructureErr] = useState('');
    const [businessPhoneErr, setBusinessPhoneErr] = useState('');
    const [businessEmailErr, setBusinessEmailErr] = useState('');
    // const [businessNameErr, setBusinessNameErr] = useState('');
    // const [legalNameErr, setLegalNameErr] = useState('');
    const [legalCityErr, setLegalCityErr] = useState('');
    const [legalStateErr, setLegalStateErr] = useState('');
    const [legalPostalErr, setLegalPostalErr] = useState('');
    const [legalCountryErr, setLegalCountryErr] = useState('');
    const [titleErr, setTitleErr] = useState('');
    const [ownersErr, setOwnersErr] = useState('');
    const [legalLineErr, setLegalLineErr] = useState('');


    const [btnLoader, setBtnLoader] = useState(false);
    const [resErr, setResErr] = useState('');
    const [isHasData, setIsHasData] = useState(true);
    const [rnView, setRnView] = useState(false);
    // const [anView, setAnView] = useState(false);



   const handleBankAccountNumberChange =  e =>{ 
    setAccount_number(e.target.value)
     setAccount_numberErr('')
    }
    const getCampDetail = () => {
        getCampDetailService(`?campCode=${props.campCode}`).then(res => {
            if(res?.data?.statusCode){
                let _res = res.data.responseData.result;
                if(_res.seller_account_id){
                    setDob(`${_res.day}/${_res.month}/${_res.year}`);
                    setAddress_line1(_res.line);
                    setAddress_city(_res.city);
                    setAddress_state(_res.state);
                    setAddress_postal_code(_res.postal_code);
                    setAddress_country(_res.country);
                    setSsn(_res.ssn);
                    setAccountName(_res.bank_name);
                    setAccountType({label: _res.type, value: _res.type});
                    setRouting_number(_res.routing_number);
                    setAccount_number(_res.account_number);
                    setAccount_nickname(_res.account_nickname);
                    setAccount_owner_name(_res.account_owner_name);
                    setSubAccountName(_res.subAccountName);
                    setUrl(_res.url);
                    setType({value: _res.businessType ,label: _res.businessType});
                    setIndustry(_res.industry);
                }else{
                    setIsHasData(false);
                }
            }
        })
    }


    useEffect(() => {
        if(props.campCode){
            getCampDetail(); 
        }

    }, [props.campCode])




    const handleValidate = () => {
        let validate = true;

        if(onboardType === '' || onboardType === 'undefined' || onboardType === null){
            setOnboardTypeErr('Onnboard type is required');
            validate = false
        }

        if(dob === '' || dob === 'undefined' || dob === null){
            setDobErr('Date of birth is required');
            validate = false
        }

        if(address_line1 === '' || address_line1 === 'undefined' || address_line1 === null){
            setAddress_line1Err('Address line is required');
            validate = false
        }

        if(address_city === '' || address_city === 'undefined' || address_city === null){
            setAddress_cityErr('Address city is required');
            validate = false
        }

        if(address_state === '' || address_state === 'undefined' || address_state === null){
            setAddress_stateErr('Address state is required');
            validate = false
        }

        if(address_postal_code === '' || address_postal_code === 'undefined' || address_postal_code === null){
            setAddress_postal_codeErr('Address postal code is required');
            validate = false
        }

        if(address_country === '' || address_country === 'undefined' || address_country === null){
            setAddress_countryErr('Address country is required');
            validate = false
        }

        if(onboardType.value !== 4){
            if(ssn === '' || ssn === 'undefined' || ssn === null){
                setSsnErr('SSN last 4 digit is required');
                validate = false
            }else if(ssn.length !== 4){
                setSsnErr('SSN is invalid');
            }
        }

     

        if(accountName === '' || accountName === 'undefined' || accountName === null){
            setAccountNameErr('Account name is required');
            validate = false
        }

        if(accountName === '' || accountName === 'undefined' || accountName === null){
            setAccountNameErr('Account name is required');
            validate = false
        }

        if(accountType === '' || accountType === 'undefined' || accountType === null){
            setAccountTypeErr('Account type is required');
            validate = false
        }

        if(routing_number === '' || routing_number === 'undefined' || routing_number === null){
            setRouting_numberErr('Account routing number is required');
            validate = false
        }else if(routing_number.length !== 9){
            setRouting_numberErr('Account routing number is invalid');
        }

        if(account_number === '' || account_number === 'undefined' || account_number === null){
            setAccount_numberErr('Account number is required');
            validate = false
        }else {
            if(account_number.length < 9){
                setAccount_numberErr('Account number is invalid');  
            }
            if(account_number.length > 14){
                setAccount_numberErr('Account number is invalid');  
            }
        } 

        if(account_nickname === '' || account_nickname === 'undefined' || account_nickname === null){
            setAccount_nicknameErr('Account nickname is required');
            validate = false
        }

        if(account_owner_name === '' || account_owner_name === 'undefined' || account_owner_name === null){
            setAccount_owner_nameErr('Account owner name is required');
            validate = false
        }

        if(url === '' || url === 'undefined' || url === null){
            setUrlErr('URL is required');
            validate = false
        }

        if(industry === '' || industry === 'undefined' || industry === null){
            setIndustryErr('Industry is required');
            validate = false
        }

        if(subAccountName === '' || subAccountName === 'undefined' || subAccountName === null){
            setSubAccountNameErr('Sub account name is required');
            validate = false
        }


        if(onboardType.value === 2 || onboardType.value === 3 || onboardType.value === 4){

            if(structure === '' || structure === 'undefined' || structure === null){
                setStructureErr('Structure is required');
                validate = false
            }

            if(legalLine === '' || legalLine === 'undefined' || legalLine === null){
                setLegalLineErr('Legal address line is required');
                validate = false
            }

            if (businessPhone === '' || businessPhone === null) {
                setBusinessPhoneErr('Business phone is required');
                validate = false
            }

            if (taxId === '' || taxId === null) {
                setTaxIdErr('Tax ID is required');
                validate = false
            }

            if (businessEmail === '') {
                setBusinessEmailErr('Business email is required');
                validate = false
            } else if (!isValidEmail(businessEmail)) {
                setBusinessEmailErr('Business email is not valid');
                validate = false
            }

            // if(businessName === '' || businessName === 'undefined' || businessName === null){
            //     setBusinessNameErr('Business name is required');
            //     validate = false
            // }

            // if(legalName === '' || legalName === 'undefined' || legalName === null){
            //     setLegalNameErr('Legal name is required');
            //     validate = false
            // }

            if(legalCity === '' || legalCity === 'undefined' || legalCity === null){
                setLegalCityErr('Legal city is required');
                validate = false
            }

            if(legalState === '' || legalState === 'undefined' || legalState === null){
                setLegalStateErr('Legal state is required');
                validate = false
            }

            if(legalPostal === '' || legalPostal === 'undefined' || legalPostal === null){
                setLegalPostalErr('Legal postal is required');
                validate = false
            }

            if(legalCountry === '' || legalCountry === 'undefined' || legalCountry === null){
                setLegalCountryErr('Legal country is required');
                validate = false
            }

            if(title === '' || title === 'undefined' || title === null){
                setTitleErr('Title is required');
                validate = false
            }

        }

        if(onboardType.value === 3){
            if(owners.length === 0 || owners === 'undefined' || owners === null){
                setOwnersErr('Owners is required');
                validate = false
            }

            let _owners = owners.map(i => ({
                ...i,
                nameErr: (i.name === '' || i.name === null) ? 'Owner name is required' : '',
                emailErr: (i.email === '' || i.email === null) ? 'Owner email is required' : !isValidEmail(i.email) ? 'Owner email is not valid' : ''
            }))

            setOwners(_owners);

            let has_owners_err = _owners.filter(i => i.emailErr || i.nameErr);

            if(has_owners_err.length){
                validate = false;
            }
                
        }

        return validate;
    }


    const handleOwnerChange = (e, ind, inputName) => {
        let _owners = [...owners];
        _owners[ind][inputName] = e.target.value;
        _owners[ind][`${inputName}Err`] = '';
        setOwners(_owners);
    }


    const addMoreOwner = () => {
        let _owners = [...owners];
        _owners.push({
            name: '',
            email: '',
            nameErr: '',
            emailErr: '',
        },)
        setOwners(_owners);
    }


    const ownerRemove = i => {
        let _owners = [...owners];
        _owners.splice(i, 1)
        setOwners(_owners);
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setBtnLoader(true);
            setResErr('');

            let params = {
                    onboardType: onboardType.value,
                    campCode: props.campCode,
                    taxId: onboardType.value === 1 ? ssn : taxId,
                    subAccountName,
                    ssn: onboardType.value === 4 ? '1234' : ssn,
                    routing_number,
                    account_number,
                    account_nickname,
                    account_owner_name,
                    url,
                    industry,
                    line: address_line1,
                    city: address_city,
                    state: address_state,
                    postal_code: address_postal_code,
                    country: address_country,
                    month: moment(dob).format('MM'),
                    day: moment(dob).format('DD'),
                    year: moment(dob).format('YYYY'),
                    bank_name: accountName,
                    type : 'checking', 
            };



            if(onboardType.value === 2 || onboardType.value === 3 || onboardType.value === 4){
                params.structure = structure;
                params.legalLine = legalLine;
                params.businessPhone = businessPhone;
                params.businessEmail = businessEmail;
                params.businessName = subAccountName;
                params.legalName = subAccountName;
                params.legalCity = legalCity;
                params.legalState = legalState;
                params.legalPostal = legalPostal;
                params.legalCountry = legalCountry;
                params.title = title;
            }


            if(onboardType.value === 3){
                params.owners = owners;
            }


           

            onBoardingService(params).then(res => {
                setBtnLoader(false);
                if(res?.data?.statusCode){
                    notification.open({
                        description:
                            'Onboarding Complete',
                    });
                   setTimeout(() => {
                    window.location.reload(false);
                   }, 1000)
                }else{
                    setResErr(res?.data?.error?.responseMessage || 'Please try after sometime')
                }
            });
        }
    }


    return(
            <div className="card mb-4 pro_wrapper pl-5 pr-5 input_info_pos_change">

                <h4 className="text-center mb-4">Org Details</h4>

                <hr />

                <h5 className='mb-4'>Account Owner</h5>
                <div className='row'>

                {
                    isHasData ? '' : <Fragment>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Onboard Type</label>
                                <Select
                                    value={onboardType}
                                    isDisabled={isHasData}
                                    onChange={e => (setOnboardType(e), setOnboardTypeErr(''))}
                                    placeholder="Onboard Type"
                                    className={`form-control ${onboardTypeErr ? 'is-invalid' : ''}`}
                                    classNamePrefix="cm_select"
                                    options={onboardTypes} />
                                {onboardTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{onboardTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>
                        <div className='col-lg-6' />
                    </Fragment>
                }

                    

                

                   

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Account Owner Name</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="Account Owner Name"
                                className={`form-control ${account_owner_nameErr ? 'is-invalid' : ''}`}
                                value={account_owner_name}
                                onChange={e => (setAccount_owner_name(e.target.value), setAccount_owner_nameErr(''))} />
                            {account_owner_nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{account_owner_nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>


                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Date of birth</label>
                            {isHasData ? <input
                                type="url"
                                placeholder="Date of birth"
                                disabled={isHasData}
                                className={`form-control ${urlErr ? 'is-invalid' : ''}`}
                                value={dob}
                                onChange={e => (setUrl(e.target.value), setUrlErr(''))} /> : <DatePicker
                                className={`form-control ${dobErr ? 'is-invalid' : ''}`}
                                format="MM/DD/YYYY"
                                placeholder="Date of birth"
                                onChange={e => (setDob(e._d), setDobErr(''))} />}
                            
                            {dobErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{dobErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>


                    {onboardType.value !== 4 ? <div className='col-lg-6'>
                        <div className="form-group">
                            <label>SSN Last 4</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="SSN Last 4"
                                className={`form-control ${ssnErr ? 'is-invalid' : ''}`}
                                value={ssn}
                                onChange={e => (setSsn(e.target.value), setSsnErr(''))} />
                            {ssnErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{ssnErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                        </div>
                    </div> : ''}



                    

                    

                    {onboardType.value === 2 || onboardType.value === 3 || onboardType.value === 4 ?  <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="Title"
                                    className={`form-control ${titleErr ? 'is-invalid' : ''}`}
                                    value={title}
                                    onChange={e => (setTitle(e.target.value), setTitleErr(''))} />
                                {titleErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{titleErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                            </div>
                        </div> : ''}

                   



                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Address Line 1</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="Address Line 1"
                                className={`form-control ${address_line1Err ? 'is-invalid' : ''}`}
                                value={address_line1}
                                onChange={e => (setAddress_line1(e.target.value), setAddress_line1Err(''))} />
                            {address_line1Err ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_line1Err}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}     
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="City"
                                className={`form-control ${address_cityErr ? 'is-invalid' : ''}`}
                                value={address_city}
                                onChange={e => (setAddress_city(e.target.value), setAddress_cityErr(''))} />
                            {address_cityErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_cityErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>State</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="State"
                                className={`form-control ${address_stateErr ? 'is-invalid' : ''}`}
                                value={address_state}
                                onChange={e => (setAddress_state(e.target.value), setAddress_stateErr(''))} />
                            {address_stateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_stateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}    
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Postal Code</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="Postal Code"
                                className={`form-control ${address_postal_codeErr ? 'is-invalid' : ''}`}
                                value={address_postal_code}
                                onChange={e => (setAddress_postal_code(e.target.value), setAddress_postal_codeErr(''))} />
                            {address_postal_codeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_postal_codeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''} 
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Country</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="Country"
                                className={`form-control ${address_countryErr ? 'is-invalid' : ''}`}
                                value={address_country}
                                onChange={e => (setAddress_country(e.target.value), setAddress_countryErr(''))} />
                            {address_countryErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_countryErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                        </div>
                    </div>



                </div>

                <hr />

                <h5 className='mb-4'>Business Details</h5>
                <div className='row'>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Business Name</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="Business Name"
                                className={`form-control ${subAccountNameErr ? 'is-invalid' : ''}`}
                                value={subAccountName}
                                onChange={e => (setSubAccountName(e.target.value), setSubAccountNameErr(''))} />
                            {subAccountNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{subAccountNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Business URL</label>
                            <input
                                type="url"
                                disabled={isHasData}
                                placeholder="Business URL"
                                className={`form-control ${urlErr ? 'is-invalid' : ''}`}
                                value={url}
                                onChange={e => (setUrl(e.target.value), setUrlErr(''))} />
                            {urlErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{urlErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    {/* {isHasData ? <div className='col-lg-6'>
                        <div className='form-group'>
                            <label>Business Type</label>
                            <Select
                                value={type}
                                isDisabled={isHasData}
                                onChange={e => (setType(e), setTypeErr(''))}
                                placeholder="Business Type"
                                className={`form-control ${typeErr ? 'is-invalid' : ''}`}
                                classNamePrefix="cm_select"
                                options={businessTypes} />
                            {typeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{typeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div> : ''} */}

                    
                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Industry</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="Industry"
                                className={`form-control ${industryErr ? 'is-invalid' : ''}`}
                                value={industry}
                                onChange={e => (setIndustry(e.target.value), setIndustryErr(''))} />
                            {industryErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{industryErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                        </div>
                    </div>

                    {
                        onboardType.value === 2 || onboardType.value === 3 || onboardType.value === 4 ?  <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Tax Id</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="Tax Id"
                                    className={`form-control ${taxIdErr ? 'is-invalid' : ''}`}
                                    value={taxId}
                                    onChange={e => (setTaxId(e.target.value), setTaxIdErr(''))} />
                                {taxIdErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{taxIdErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                            </div>
                        </div> : ''
                    }

                    

                </div>




                {
                    (onboardType.value === 2 || onboardType.value === 3 || onboardType.value === 4) ? <Fragment>

                      
                        <div className='row'>

                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Structure</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="Structure"
                                    className={`form-control ${structureErr ? 'is-invalid' : ''}`}
                                    value={structure}
                                    onChange={e => (setStructure(e.target.value), setStructureErr(''))} />
                                {structureErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{structureErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}     
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Business Phone</label>
                                <PhoneInput
                                    country="US"
                                    placeholder="Business Phone"
                                    className={`form-control ${businessPhoneErr ? 'is-invalid' : ''}`}
                                    onChange={e => (setBusinessPhone(e), setBusinessPhoneErr(''))}
                                    value={businessPhone}
                                    maxLength={16} />
                                {businessPhoneErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{businessPhoneErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Business Email</label>
                                <input
                                    type="email"
                                    disabled={isHasData}
                                    placeholder="Business Email"
                                    className={`form-control ${businessEmailErr ? 'is-invalid' : ''}`}
                                    value={businessEmail}
                                    onChange={e => (setBusinessEmail(e.target.value), setBusinessEmailErr(''))} />
                                {businessEmailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{businessEmailErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}    
                            </div>
                        </div>

                        {/* <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Business Name</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="Business Name"
                                    className={`form-control ${businessNameErr ? 'is-invalid' : ''}`}
                                    value={businessName}
                                    onChange={e => (setBusinessName(e.target.value), setBusinessNameErr(''))} />
                                {businessNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{businessNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''} 
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="Name"
                                    className={`form-control ${legalNameErr ? 'is-invalid' : ''}`}
                                    value={legalName}
                                    onChange={e => (setLegalName(e.target.value), setLegalNameErr(''))} />
                                {legalNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{legalNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Address Line</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="Address Line"
                                    className={`form-control ${legalLineErr ? 'is-invalid' : ''}`}
                                    value={legalLine}
                                    onChange={e => (setLegalLine(e.target.value), setLegalLineErr(''))} />
                                {legalLineErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{legalLineErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>City</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="City"
                                    className={`form-control ${legalCityErr ? 'is-invalid' : ''}`}
                                    value={legalCity}
                                    onChange={e => (setLegalCity(e.target.value), setLegalCityErr(''))} />
                                {legalCityErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{legalCityErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                            </div>
                        </div>


                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>State</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="State"
                                    className={`form-control ${legalStateErr ? 'is-invalid' : ''}`}
                                    value={legalState}
                                    onChange={e => (setLegalState(e.target.value), setLegalStateErr(''))} />
                                {legalStateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{legalStateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                            </div>
                        </div>


                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Postal Code</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="Postal Code"
                                    className={`form-control ${legalPostalErr ? 'is-invalid' : ''}`}
                                    value={legalPostal}
                                    onChange={e => (setLegalPostal(e.target.value), setLegalPostalErr(''))} />
                                {legalPostalErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{legalPostalErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label>Country</label>
                                <input
                                    type="text"
                                    disabled={isHasData}
                                    placeholder="Country"
                                    className={`form-control ${legalCountryErr ? 'is-invalid' : ''}`}
                                    value={legalCountry}
                                    onChange={e => (setLegalCountry(e.target.value), setLegalCountryErr(''))} />
                                {legalCountryErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{legalCountryErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}  
                            </div>
                        </div>

                        

                        </div>

                        <hr />

                    </Fragment> : ''
                }

                <h5 className='mb-4'>Account Details</h5>
                <div className='row'>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Bank Name</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="Bank Name"
                                className={`form-control ${accountNameErr ? 'is-invalid' : ''}`}
                                value={accountName}
                                onChange={e => (setAccountName(e.target.value), setAccountNameErr(''))} />
                            {accountNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{accountNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <label>Account Type</label>
                            <Select
                                value={accountType}
                                isDisabled={isHasData}
                                onChange={e => (setAccountType(e), setAccountTypeErr(''))}
                                placeholder="Account Type"
                                className={`form-control ${accountTypeErr ? 'is-invalid' : ''}`}
                                classNamePrefix="cm_select"
                                options={accountTypes} />
                            {accountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{accountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>



                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Routing Number</label>
                            <div className='eye_wrapper' style={{position: 'relative'}}>
                                <input
                                    type={rnView ? 'text' : 'password'}
                                    placeholder="Routing Number"
                                    disabled={isHasData}
                                    className={`form-control ${routing_numberErr ? 'is-invalid' : ''}`}
                                    value={routing_number}
                                    onChange={e => (setRouting_number(e.target.value), setRouting_numberErr(''))} />
                                {/* <i className={`icon-eye ${rnView ? 'slash' : ''}`} onClick={() => setRnView(!rnView)} /> */}
                            </div>
                           
                            {routing_numberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routing_numberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Account Number</label>
                            <SecureInput
                                type='number'
                                error={account_numberErr}
                                value={account_number}
                                name={"bankAccountNum"}
                                placeholder={"Account Number"}
                                maxLength={30}
                                onChange={handleBankAccountNumberChange}
                            />
                            
                            {account_numberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{account_numberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Account Nickname</label>
                            <input
                                type="text"
                                disabled={isHasData}
                                placeholder="Account Nickname"
                                className={`form-control ${account_nicknameErr ? 'is-invalid' : ''}`}
                                value={account_nickname}
                                onChange={e => (setAccount_nickname(e.target.value), setAccount_nicknameErr(''))} />
                            {account_nicknameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{account_nicknameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    

                    

                </div>

                {
                    onboardType.value === 3 ? <Fragment>
                        <h5 className='mb-4'>Additional Owners</h5>
                        {
                            owners.map((item, i) => (
                                <div className='row position-relative pr-4' key={i}>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label>Owner Name</label>
                                            <input
                                                type="text"
                                                disabled={isHasData}
                                                placeholder="Owner Name"
                                                className={`form-control ${item.nameErr ? 'is-invalid' : ''}`}
                                                value={item.name}
                                                onChange={e => handleOwnerChange(e, i, 'name')} />
                                            {item.nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}    
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label>Owner Email</label>
                                            <input
                                                type="email"
                                                disabled={isHasData}
                                                placeholder="Owner Email"
                                                className={`form-control ${item.emailErr ? 'is-invalid' : ''}`}
                                                value={item.email}
                                                onChange={e => handleOwnerChange(e, i, 'email')} />
                                            {item.emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.emailErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}    
                                        </div>
                                    </div>

                                    {isHasData ? '' : <span className="owner_remove" onClick={() => ownerRemove(i)}><i className="icon-delete" /></span>}
                                    

                                    
                                </div>
                            ))
                        }


                    <div className='text-right'>
                        <span className='btn btn-dark btn-sm' onClick={addMoreOwner}>Add Owner</span>
                    </div>

                    </Fragment> : ''
                }

                {isHasData ? '' : <div className='pb-4 mt-4'>
                    {btnLoader ? '' : <span className='btn btn-primary' onClick={handleSubmit}>Submit</span>}
                    
                </div>}

                
                {resErr? <p className="error_msg">{resErr}</p> : ''}

            </div>
    )
}
    


const mapStateToProps = state => {
    let {campCode, UserType, email } = state.user.userProfile;
    return {
        campCode,
        UserType,
        email
    };
}
  
const mapDispatchToProps = dispatch => ({

});
  
export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);