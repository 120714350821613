import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createNoteService, deleteNoteService, editNoteService, getNotesService } from '../../services/campersServices';
import { getHtml, removeHtmlTags, resHandle } from '../../helper';
import { connect } from 'react-redux';
import { Empty, notification } from 'antd';
import moment from 'moment';
import ConfirmDiloag from '../modals/ConfirmDiloag';



const UserNotes = props => {

    const [showModal, setShowModal] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);

    const [desc, setDesc] = useState('');
    const [descErr, setDescErr] = useState('');


    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [activeNoteId, setActiveNoteId] = useState('');
    const [activeNoteIdForDelete, setActiveNoteIdForDelete] = useState('');


    const closeModal = () => {
        setShowModal(false);
        setDesc('');
        setDescErr('');
        setBtnLoader(false);
        setActiveNoteIdForDelete({});
        setActiveNoteId('');
    }


    const handleSubmit = () => {

        if(removeHtmlTags(desc) === '' || removeHtmlTags(desc).trim() === ''){
            setDescErr('Description is required');
        }else{
            let params = {
                userId: props.match.params.id,
                campCode: props.userProfile.campCode,
                desc
            }

            if(activeNoteIdForDelete){
                params.noteId = activeNoteIdForDelete
            }

            let _service = activeNoteIdForDelete ? editNoteService : createNoteService

            setBtnLoader(true);
            _service(params).then(res => {
                let { status, data } = resHandle(res);
                setBtnLoader(false);
                if(status){
                    closeModal();
                    getList();
                    props.callback()
                    notification.open({
                        message: 'Note',
                        description: `Note ${activeNoteIdForDelete ? 'updated' : 'created'} successfully`,
                    });
                }
            })
        }

    }



    const getList = () => {
        let query = `?userId=${props.match.params.id}&campCode=${props.userProfile.campCode}`;

        getNotesService(query).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setList(data.result);
                setTotalCount(data.count);
            }
        })
    }



    useEffect(() => {
        if(props?.match?.params?.id){
            getList();
        }

    }, [props])


    const handleDeleteNote = () => {
        let params = {noteId: activeNoteId};
        deleteNoteService(params).then(res => {
            if(res.data.statusCode){
                closeModal();
                getList(); 
                props.callback()
            }
        })
    }

    return(
        <div className="mt-3 ml-3 mr-3">
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h4>Notes</h4>
                <button className='btn btn-primary' onClick={() => setShowModal(true)}>Add Note</button>
            </div>


            <ConfirmDiloag
                show={activeNoteId}
                hideModal={() => setActiveNoteId('')}
                action={handleDeleteNote}
                Message={["Are You Sure you want to Delete this Note"]} />


            <ul className='my_nots'>
                {
                    list.length ? list.map(item => (
                        <li key={item._id}>                         
                            <small>{moment(item.created).format('DD MMM, YYYY : HH:mm')}, Created By <i>{item.name} - {item.email}</i></small>
                            {getHtml(item.desc)}

                            <div className='note_actions'>
                                <i className="icon-delete" onClick={() => setActiveNoteId(item._id)} />
                                <i className="icon-pancil" onClick={() => (setShowModal(true), setActiveNoteIdForDelete(item._id), setDesc(item.desc))} />
                            </div>
                        </li>
                    )) : <div className="cm_empty m-auto"><Empty description="Data Not Found" /> </div> 
                }
            </ul>



            <Modal
                size="md"
                show={showModal}
                dialogClassName="remove_header_line"
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>{activeNoteIdForDelete ? 'Edit' : 'Create'} Note</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                
                    <div className="form-group">
                        <p className='a_label mb-2'>Description</p>
                        <ReactQuill
                            className={`inbox_editer ${descErr ? 'is-invalid' : ''}`}
                            theme="snow"
                            value={desc}
                            onChange={e => (setDesc(e), setDescErr(''))} />

                        {descErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{descErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>

                    <div className="text-center">
                        <button onClick={handleSubmit} style={{minWidth: '130px'}} disabled={btnLoader} className={`btn btn-primary pl-5 pr-5 ${btnLoader ? 'btnLoader' : ''}`}>{activeNoteIdForDelete ? 'Save' : 'Create'}</button>
                    </div>


                </Modal.Body>
            </Modal>

        </div>
    )
}

const mapStateToProps = state => {
    let userProfile = state.user.userProfile;
    return {
        userProfile
    };
}


export default connect(mapStateToProps)(UserNotes);