import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { Modal, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import { Empty, notification} from 'antd';

import { getProgramBookingDetail, createbookingcamperfolder, deleteBookingCamperFolder, counselordetail } from '../../services/directorServices';
import CamperCard from './camperCard';
import { ProgramLoader,FolderIcon } from '../reuse/SVG';
import {  getProgramType, getprogramdatesinCart, genders2, ageArr } from '../../helper';
import {fmedicalBookingFilterAction, tsizeBookingFilterAction, ssizeBookingFilterAction, fallergyBookingFilterAction, fageBookingFilterAction, fgenderBookingFilterAction } from '../../store/allFilterState'
import UserImg from '../../assets/img/user.png'



const BookingDetail = props => {
    const [loader, setLoader]                        = useState(true);
    const [camperList, setCamperList]                = useState([]);
    const [camperListLoader, setCamperListLoader]    = useState(false);
    const [filterCamperList, setFilterCamperList]    = useState([]);
    const [programName, setProgramName]              = useState('');
    const [programCode, setProgramCode]              = useState('');
    const [startDate, setStartDate]                  = useState('');
    const [time, setTime]                            = useState('');
    const [gender, setGender]                        = useState('');
    const [ageGroup, setAgeGroup]                    = useState('');
    // const [genderF, setGenderF]                        = useState('');
    // const [ageGroupF, setAgeGroupF]                    = useState('');
    const [programType, setProgramType]              = useState('');
    const [bookingDate, setBookingDate]              = useState('');
    const [folderPopup, setFolderPopup]              = useState(false);    
    const [folderName, setFoldername]                = useState();
    const [addFoder, setAddFolder]                   = useState(false);
    const [selectAll, setSelectAll]                  = useState(false);
    const [campersIds, setCampersIds]                = useState([]);
    const [campersWarning, setCampersWarning]        = useState(false); 
    const [btnloder, setBtnLoader]                   = useState(false);
    const [deleteFolder, setDeleteFolder]            = useState(false);
    const [deleteFolderId, setDeleteFolderId]        = useState();
    const [folders, setFolderList]                   = useState([]);
    const [folderalreadyExit, setFolderAlreadyExit]  = useState(false);
    const [filterOptions, setFilterOptions]  = useState({});


    const [search ,setSearch]                        = useState('');
    const [additionalDates, setAdditionalDates]      = useState([]);
    const [byDates, setByDates]                      = useState(2);
    const [selectedDate, setSelectedDate]            = useState('');
    const [permissions, setPermissions]              = useState({});
    const [isSearchToched, setIsSearchToched]        = useState(false);
    const [isCancelled, setIsCancelled]              = useState(false);


    useEffect(() => {
        if(selectedDate._id){
            let temp = [...filterCamperList];
            let res = temp.filter(item => item.selectedDates.includes(selectedDate._id));
            setCamperList(res);
        }else{
            setCamperList(filterCamperList);
        }
   

    }, [selectedDate])


    useEffect(() => {
        if(props.UserType === 4){
            counselordetail(`/?counselorId=${props._id}`).then((res) => {
                if(res.data.statusCode === 1){
                    setPermissions(res.data.responseData.result.permissions || {}) 
                }
            })
        }
    }, [])


    const getCamperList = () => {
        let query = `?programId=${props.match.params.id}&search=${search}`;

        if(props.fmedical){
            query += `&medical=${props.fmedical.value}`
        }
        if(props.tsize){
            query += `&tshirtSize=${props.tsize.value}`
        }
        if(props.ssize){
            query += `&shortsSize=${props.ssize.value}`
        }
        if(props.fallergy){
            query += `&allergy=${props.fallergy.value}`
        }

        if(props.fage){
            query += `&age=${props.fage.value}`
        }
        if(props.fgender){
            query += `&gender=${props.fgender.value}`
        }

        setCamperListLoader(true);

        getProgramBookingDetail(query).then(res => {
            if(res?.data?.statusCode){
                let _camperList = res.data.responseData.result.camperList || [];
                let _filterOptions = res.data.responseData.filters || {};
                setFilterOptions(_filterOptions);
                setCamperListLoader(false);
                if(_camperList.length){
                    let _sort = _camperList.sort((a, b) => a.bookingDate - b.bookingDate);
                    let _res = _sort.map((i, ind) => ({...i, bookingSerial: ind + 1 }));
                    setCamperList(_res);
                    setFilterCamperList(_res);
                }else{
                    setCamperList([]);
                    setFilterCamperList([]);
                }

                setAdditionalDates(res.data.responseData.programData.additionalDates || []);
                setByDates(res.data.responseData.programData.byDates || 2);
                setProgramName(res.data.responseData.result.programName);
                setProgramCode(res.data.responseData.result.programCode);
                setStartDate(res.data.responseData.result.startDate);
                setGender(res.data.responseData.result.gender);
                setAgeGroup(`${res.data.responseData.result.minAge} - ${res.data.responseData.result.maxAge}`);
                setBookingDate(res.data.responseData.result.bookingDate);
                setProgramType(res.data.responseData.result.programType);
                setTime(`${res.data.responseData.result.checkInTime} - ${res.data.responseData.result.checkOutTime}`);
                res.data.responseData.result.folder&& setFolderList(res.data.responseData.result.folder)
                
            }
            setLoader(false);
        })
    }
    
    const handelCreateFolder = () => {
        if(folderName){setFolderPopup(false); setAddFolder(true)}
    }


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getCamperList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])


    const selectAllCamper = () =>{
        let listcamper = [...campersIds];
        listcamper.length = 0;
        camperList.map(item=>{
            listcamper.push(item.camperId);
            return null;
        })
        setCampersIds(listcamper);
        setCampersWarning(false)
    }

    const deselectAllCamper = () =>{
        let listcamper = [...campersIds];
        listcamper.length = 0;
        setCampersIds(listcamper)
    }
    
    const handelCamper = (e) =>{
        let listcamper = [...campersIds];
        setCampersWarning(false);
        if(listcamper.includes(e.target.value))
        {
            setSelectAll(false)
            let newcamperlist = listcamper.filter(item => item!==e.target.value) 
            setCampersIds(newcamperlist)
        }
        else
        {
            listcamper.push(e.target.value);
            if(listcamper.length===camperList.length){ setSelectAll(true)}
            setCampersIds(listcamper)
        }
    }  

    const addFolder = () =>{
        if(campersIds.length > 0){
                let params = {
                    "folderName":folderName,
                    "programId":props.match.params.id,
                    "camperIds": JSON.stringify(campersIds)
                }

                createbookingcamperfolder(params).then((res) => {
                    if(res?.data?.statusCode)
                    {
                        //let query = `?programId=${props.match.params.id}`;
                        let query = `?programId=${props.match.params.id}&search=${search}`;
                        if(props.fmedical){
                            query += `&medical=${props.fmedical.value}`
                        }
                        if(props.tsize){
                            query += `&tshirtSize=${props.tsize.value}`
                        }
                        if(props.ssize){
                            query += `&shortsSize=${props.ssize.value}`
                        }
                        if(props.fallergy){
                            query += `&allergy=${props.fallergy.value}`
                        }

                        if(props.fage){
                            query += `&age=${props.fage.value}`
                        }
                        if(props.fgender){
                            query += `&gender=${props.fgender.value}`
                        }
                        getProgramBookingDetail(query).then(res => {
                            if(res?.data?.statusCode){
                                let _camperList = res.data.responseData.result.camperList || [];

                                if(_camperList.length){
                                    let _sort = _camperList.sort((a, b) => b.bookingDate - a.bookingDate)
                                    let _res = _sort.map((i, ind) => ({...i, bookingSerial: _sort.length - ind}))
                                    setCamperList(_res);
                                }else{
                                    setCamperList([]);
                                }

                                //setCamperList(res.data.responseData.result.camperList);
                                res.data.responseData.result.folder && setFolderList(res.data.responseData.result.folder)
                            }
                            setAddFolder(false)
                            notification.open({
                                description:
                                  'Folder Created Successfully',
                              });
                            
                        }) 
                    }
                    else if(res.data.statusCode===0){
                        setBtnLoader(false);
                        setFolderAlreadyExit(true)
                    }
                    else
                    {
                        setBtnLoader(false);
                    }
                })
        }
        else
        {
            setCampersWarning(true);
            setBtnLoader(false);
        }
    }

    const deleteFolderAction = () => {
        // let foldersList =[...folders];
        let params = `/?folderId=${deleteFolderId}`;
        deleteBookingCamperFolder(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                // let newfolderlist = foldersList.filter(item => item._id !== deleteFolderId)
                setDeleteFolder(false);
                //let query = `?programId=${props.match.params.id}`;
                let query = `?programId=${props.match.params.id}&search=${search}`;
                if(props.fmedical){
                    query += `&medical=${props.fmedical.value}`
                }
                if(props.tsize){
                    query += `&tshirtSize=${props.tsize.value}`
                }
                if(props.ssize){
                    query += `&shortsSize=${props.ssize.value}`
                }
                if(props.fallergy){
                    query += `&allergy=${props.fallergy.value}`
                }

                if(props.fage){
                    query += `&age=${props.fage.value}`
                }
                if(props.fgender){
                    query += `&gender=${props.fgender.value}`
                }
                
                getProgramBookingDetail(query).then(res => {
                    if(res?.data?.statusCode){
                        let _camperList = res.data.responseData.result.camperList || [];

                        if(_camperList.length){
                            let _sort = _camperList.sort((a, b) => b.bookingDate - a.bookingDate)
                            let _res = _sort.map((i, ind) => ({...i, bookingSerial: _sort.length - ind}))
                            setCamperList(_res);
                        }else{
                            setCamperList([]);
                        }
                        //setCamperList(res.data.responseData.result.camperList);
                        setFolderList(res.data.responseData.result.folder)
                    }
                })
                notification.open({
                    description:
                      'Folder Deleted Successfully',
                  });
            }
        })   
    }


    const getCamperListFilter = () => {
        setCamperListLoader(true);
        let query = `?programId=${props.match.params.id}`;
        if(props.fmedical){
            query += `&medical=${props.fmedical.value}`
        }
        if(props.tsize){
            query += `&tshirtSize=${props.tsize.value}`
        }
        if(props.ssize){
            query += `&shortsSize=${props.ssize.value}`
        }
        if(props.fallergy){
            query += `&allergy=${props.fallergy.value}`
        }
        if(props.fage){
            query += `&age=${props.fage.value}`
        }
        if(props.fgender){
            query += `&gender=${props.fgender.value}`
        }
        getProgramBookingDetail(query).then(res => {
            if(res?.data?.statusCode){
                let _camperList = res.data.responseData.result.camperList || [];

                if(_camperList.length){
                    let _sort = _camperList.sort((a, b) => b.bookingDate - a.bookingDate)
                    let _res = _sort.map((i, ind) => ({...i, bookingSerial: _sort.length - ind}))
                    setCamperList(_res);
                }else{
                    setCamperList([]);
                }
            }
            setCamperListLoader(false);
        })
    }


    const getCamperListClearFilter = () => {
        setCamperListLoader(true);
        props.fmedicalBookingFilterAction('');
        props.tsizeBookingFilterAction('');
        props.ssizeBookingFilterAction('');
        props.fallergyBookingFilterAction(''); 
        props.fgenderBookingFilterAction(''); 
        props.fageBookingFilterAction(''); 
        let query = `?programId=${props.match.params.id}`;
        
        getProgramBookingDetail(query).then(res => {
            if(res?.data?.statusCode){
                let _camperList = res.data.responseData.result.camperList || [];

                if(_camperList.length){
                    let _sort = _camperList.sort((a, b) => b.bookingDate - a.bookingDate)
                    let _res = _sort.map((i, ind) => ({...i, bookingSerial: _sort.length - ind}))
                    setCamperList(_res);
                }else{
                    setCamperList([]);
                }
            }
            setCamperListLoader(false);
        })
    }
    


    return(
        <div className="body_container without_overflow  pb-4 director_wrapper">
        <Modal
            show={folderPopup}
            animation={false}  
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setFolderPopup(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <h3>Create a Folder</h3>
                </div>
                <h5>Folder Name</h5>
                <input
                    type="text"
                    onChange={e => setFoldername(e.target.value)}
                    className="form-control" />

                <div className="text-center p-5"> 
                    <button onClick={handelCreateFolder} style={{width: '100%'}} className="btn btn-primary">Done</button>
                </div>
            </Modal.Body>
            </Modal>

            <Modal
            show={deleteFolder}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setDeleteFolder(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to delete this folder</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setDeleteFolderId(''); setDeleteFolder(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button  onClick={deleteFolderAction} style={{width: '120px'}} className="btn btn-primary ml-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal>    

        
            <div className="container">
                

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4 className="mb-0">Participant Info</h4>
                    <div className="btn_group">
                        {
                        !addFoder ?  
                        <>
                        <button className="btn fix_btn btn-outline-primary mr-2"><i className="icon-upload-2" /> Download PDF</button>
                        <button className="btn fix_btn btn-outline-primary mr-2"><i className="icon-print" /> Print</button>
                        <button className="btn btn-primary"  onClick={()=>{ setFolderPopup(true) }}><i className="icon-folder-2" /> Add Folder</button>
                        </>
                        :
                        selectAll ?
                            <button className="btn btn-primary" onClick={()=>{deselectAllCamper(); setSelectAll(false)}}> Deselect All </button>
                            :
                            <button className="btn btn-primary" onClick={()=>{selectAllCamper(); setSelectAll(true)}}> Select All </button>                                   
                        }

                    </div>
                </div>


                <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg-10">
                            <div className="page_card_header cm_border_0">
                               
                                <p><b>{programName}</b> &nbsp; &nbsp; Program Code: {programCode}<br />{moment.utc(startDate).format('MM/DD/YYYY')} &nbsp; &nbsp; Time : {time} &nbsp; &nbsp; 
                                                    Gender : {gender === 1 ? 'Male' : ''}
                                                    {gender === 2 ? 'Female' : ''}
                                                    {gender === 3 ? 'Co-Ed' : ''} <br/>Age : {ageGroup} &nbsp; &nbsp; {getProgramType(programType)}</p>
                            
                                                    <div className="d-flex align-items-center justify-content-between page_card_header cm_border_0">
                                                    <Link to="/bookings" className="btn btn-primary">Back</Link>
                            {/* <Dropdown className="cm_filter">
                                    <Dropdown.Toggle variant="primary" className="pl-4 pr-4">
                                       <i className="icon-filter" /> Status
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>

                                        <div className="form-group">
                                            <label> Choose Status</label>
                                            <Select
                                                isClearable
                                                className="form-control"
                                                placeholder="Select"
                                                classNamePrefix="cm_select"
                                                onChange={e => props.fstatusBookingFilterAction(e)} 
                                                value={props.fstatus ? ({label: props.fstatus.label, value: props.fstatus.value}) : ''}
                                                options={statusArr.map((item, i) => ({label: item, value: i+1}))} />
                                           
                                        </div>

                                        <div className="d-flex justify-content-between cm_round_btn">
                                            <button onClick={() => getCamperListClearFilter()} className="btn btn-light w-100 mr-1 rounded-5">Clear all</button>
                                            <button onClick={() => getCamperListFilter()} className="btn btn-primary w-100 ml-1 rounded-5">Apply Filter</button>
                                        </div>

                                    </Dropdown.Menu>
                                </Dropdown> */}
                            <div className="btn_group" style={{display:"flex"}}>
                                <div style={{marginRight:'5%'}}>
                                    <button onClick = {() => setIsCancelled(prev => !prev)} className="btn btn-primary" >{isCancelled ? "All": "Cancelled"}</button>
                                </div>   
                              <Dropdown className="cm_filter">
                                    <Dropdown.Toggle variant="primary" className="pl-4 pr-4">
                                       <i className="icon-filter" /> Filter
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>

                                        <div className="form-group">
                                            <label>Age</label>
                                            <Select
                                                isClearable
                                                className="form-control"
                                                placeholder="Select"
                                                classNamePrefix="cm_select"
                                                onChange={e => props.fageBookingFilterAction(e)} 
                                                value={props.fage ? ({label: props.fage.label, value: props.fage.value}) : ''}
                                                options={ageArr.map(item => ({label: item, value: item}))} />
                                           
                                        </div>

                                        <div className="form-group">
                                            <label>Sex</label>
                                            <Select
                                                isClearable
                                                className="form-control"
                                                placeholder="Select"
                                                classNamePrefix="cm_select"
                                                value={props.fgender ? ({label: props.fgender.label, value: props.fgender.value}) : ''}
                                                onChange={e => props.fgenderBookingFilterAction(e)} 
                                                options={genders2.filter(j => filterOptions?.gender?.find(k => k === j.value)).map(item => ({label: item.label, value: item.value}))} />
                                        </div>
  
                                        <div className="form-group">
                                            <label>Medical Condition</label>
                                            <Select
                                                isClearable
                                                className="form-control"
                                                placeholder="Select"
                                                classNamePrefix="cm_select"
                                                onChange={e => props.fmedicalBookingFilterAction(e)} 
                                                value={props.fmedical ? ({label: props.fmedical.label, value: props.fmedical.value}) : ''}
                                                options={filterOptions?.medical?.map(item => ({label: item, value: item}))} />
                                        </div>

                                        <div className="form-group">
                                            <label>T-shirt Size</label>
                                            <Select
                                                isClearable
                                                className="form-control"
                                                placeholder="Select"
                                                classNamePrefix="cm_select"
                                                onChange={e => props.tsizeBookingFilterAction(e)} 
                                                value={props.tsize ? ({label: props.tsize.label, value: props.tsize.value}) : ''}
                                                options={filterOptions?.tshirt?.map(item => ({label: item, value: item}))} />
                                        </div>

                                        <div className="form-group">
                                            <label>Shorts Size</label>
                                            <Select
                                                isClearable
                                                className="form-control"
                                                placeholder="Select"
                                                classNamePrefix="cm_select"
                                                onChange={e => props.ssizeBookingFilterAction(e)} 
                                                value={props.ssize ? ({label: props.ssize.label, value: props.ssize.value}) : ''}
                                                options={filterOptions?.shortsSize?.map(item => ({label: item, value: item}))} />
                                        </div>


                                        <div className="form-group">
                                            <label>Allergy</label>
                                            <Select
                                                isClearable
                                                className="form-control"
                                                placeholder="Select"
                                                classNamePrefix="cm_select"
                                                onChange={e => props.fallergyBookingFilterAction(e)} 
                                                value={props.fallergy ? ({label: props.fallergy.label, value: props.fallergy.value}) : ''}
                                                options={filterOptions?.allergy?.map(item => ({label: item, value: item}))} />
                                        </div>

                                        <div className="d-flex justify-content-between cm_round_btn">
                                            <button onClick={() => getCamperListClearFilter()} className="btn btn-light w-100 mr-1 rounded-5">Clear all</button>
                                            <button onClick={() => getCamperListFilter()} className="btn btn-primary w-100 ml-1 rounded-5">Apply Filter</button>
                                        </div>

                                    </Dropdown.Menu>
                                </Dropdown>
                                
                                
                            </div>
                        </div>

                        {byDates === 1 ? <div>
                            <small>Program Dates:</small><br />
                            <span onClick={() => setSelectedDate('')} className={`cm_selected_date ${selectedDate === '' ? 'active' : ''}`}>All</span>
                            {
                                additionalDates.map(item => (
                                    <span
                                        className={`cm_selected_date ${selectedDate._id === item._id ? 'active' : ''}`}
                                        key={item._id}
                                        onClick={() => setSelectedDate(item)}>
                                        {getprogramdatesinCart(item.startDate, item.endDate)}
                                    </span>
                        ))}
                        </div> : ''}


                        




                            </div>

                            <div className="partner-form search_wrap" style={{maxWidth: '100%'}}>
                                <i className="icon-search" />
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    placeholder="Search"
                                    value={search}
                                    onChange={e => (setSearch(e.target.value), setIsSearchToched(true))} />
                            </div>
                            {
                            !addFoder ?  
                            folders.length > 0 ?
                            folders.map(folder =>(
                            <div className="d-flex align-items-center counselor_card folder_card" key={folder._id}>

                                    <div>
                                    <div className="folder_icon"> <Link to={{pathname:`/camper/folder/${folder._id}`, state:{"booking_id": props.match.params.id}}}><FolderIcon /></Link></div>
                                    <h4><Link to={{pathname:`/camper/folder/${folder._id}`, state:{"booking_id": props.match.params.id}}}>{folder.folderName}</Link></h4>

                                    <small>{moment(folder.created).format('MM/DD/YYYY hh:mm')}</small>
                                </div>

                            <div className="remove_counselor"><i onClick={()=>{setDeleteFolder(true); setDeleteFolderId(folder._id)}} className="icon-delete" /></div>
                            </div>
                            )) 
                            : ''
                            :''
                            }
                            {
                            !addFoder ?    
                            camperListLoader ? <div className="program_loader"><ProgramLoader /></div> : camperList.length > 0 ?
                                <div className="camp_list">
                                    {
                                        isCancelled ? camperList.filter(camper => camper.status === 2).slice().reverse().map((camper, index)=>(
                                            <CamperCard
                                                additionalDates={additionalDates}
                                                key={camper._id}
                                                index={index + 1}
                                                bookingDate={bookingDate}
                                                programdate={startDate}
                                                programId={props.match.params.id}
                                                CamperDetails={camper}
                                                viewCamper={permissions.viewCamper}
                                                messageCamper={permissions.messageCamper} />     
                                        )) : 
                                        camperList.slice().reverse().map((camper, index)=>(
                                            <CamperCard
                                                additionalDates={additionalDates}
                                                key={camper._id}
                                                index={index + 1}
                                                bookingDate={bookingDate}
                                                programdate={startDate}
                                                programId={props.match.params.id}
                                                CamperDetails={camper}
                                                viewCamper={permissions.viewCamper}
                                                messageCamper={permissions.messageCamper} />     
                                        ))
                                    }   
                                </div>

                            : <div className="cm_empty"><Empty description="No Camper Found" /></div>
                            :
                            <div className="camp_list">
                                {
                                    campersWarning ? <p className="waring_text">Please select the camper First</p> : ''  
                                }
                                {
                                 folderalreadyExit ? <p className="waring_text">Folder with same name already exit</p> : ''
                                }
                                {
                                    camperList.map((camper,index)=>(
                                        <div className="d-flex align-items-center camper_card">
                                        <div className="mr-3">
                                            <img src={camper.profileImage !== '' ? camper.profileImage : UserImg} /> 
                                        </div> 
                                
                                        <div className="d-camper-info">
                                            <Link className="link_color_black" to={`/bookings/${camper.bookingId}/${camper.camperId}/${camper.programId}`}>
                                                
                                                <div className="d-flex infos_icons">
                                                <b>Name: {camper.camperName}</b>
                                                    (<span>{(camper.gender===1 && 'M') || (camper.gender===2 && 'F') || (camper.gender===3 && 'O')}
                                                    {camper.medical ==='None' || camper.medical ==='"None"' ? '' : <i className="icon-hospital" />}
                                                    {camper.allergy ==='None' || camper.allergy ==='"None"' ? '' : <i className="icon-fish" />}
                                                    {camper.allergyDeadly===1 && <i className="icon-deadly-allergy" />}
                                                    {camper.allowDocumentCamper===1 && <i className="icon-handshake" />}
                                                    </span>
                                                    )
                                                </div>
                                                <p className="mb-0">Age: {camper.age} &nbsp; &nbsp; Booking Number: {camper.bookingNumber} &nbsp; &nbsp; Booking Date: {moment.utc(bookingDate).format('MM/DD/YYYY')} </p>
                                            </Link>
                                        </div> 
                                        <div className="ml-auto">
                                            <div className="check_box_wrapper cm_dark_check">
                                                <input type="checkbox" value={camper.camperId} onChange={(e)=>{handelCamper(e)}} checked={campersIds.includes(camper.camperId)}></input>
                                                <span className="check_box"><span></span></span>
                                            </div>  
                                        </div> 
                                    </div>     
                                    ))
                                }
                                <div className="col-sm-12 text-center mb-5">
                                    <span className="btn btn-light rounded-0 mr-2" onClick={()=>{ setAddFolder(false); setSelectAll(false); setFoldername(''); setCampersIds([]); setFolderAlreadyExit(false)}} style={{width: '130px'}}>Cancel</span>
                                    <button  onClick={()=>{setBtnLoader(true); addFolder()}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Submit</button>
                                </div>
                            </div>
                            }  
                        </div>
                </div>
            </div>
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let {campCode, _id, UserType } = state.user.userProfile;
    let {fmedical, tsize, ssize, fallergy, fgender, fage} = state.allFiterState;

    return {
        state,
        campCode,
        UserType,
        _id,
        fmedical,
        tsize,
        ssize,
        fallergy,
        fgender,
        fage
    };
}

const mapDispatchToProps = dispatch => ({
    fmedicalBookingFilterAction: params => dispatch(fmedicalBookingFilterAction(params)),
    tsizeBookingFilterAction: params => dispatch(tsizeBookingFilterAction(params)),
    ssizeBookingFilterAction: params => dispatch(ssizeBookingFilterAction(params)),
    fallergyBookingFilterAction: params => dispatch(fallergyBookingFilterAction(params)),
    fageBookingFilterAction: params => dispatch(fageBookingFilterAction(params)),
    fgenderBookingFilterAction: params => dispatch(fgenderBookingFilterAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail);

