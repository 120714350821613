import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { careerListService } from '../../services/otherService';
import CareerBanner from '../../assets/img/career-banner.png';
import CampJobImg from '../../assets/img/camp-job-img.jpg';


const Career = () => {
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState([]);
    const [currentJob, setCurrentJob] = useState('');
    const [showModal, setShowModal] = useState(false);


    const careerList = () => {

        careerListService('').then(res => {
            setLoader(false);
            if(res?.data?.statusCode){
                setList(res.data.responseData.result);
            }
        })        
    }


    useEffect(() => {
        careerList();
    }, [])


    const openModal = job => {
        setShowModal(true)
        setCurrentJob(job)
    }

    return(
        <div className="body_container career">

        <div className="container">
            <div className="section_header mb-3">
                <h3>Careers at <span className="font_raleway w300">CampsEZ</span></h3>
            </div>
            <div className="cm_banner_wrapper">
                <img src={CareerBanner} />
                <div className="cm_banner_text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <form>
                                    <div className="form-group d-flex btn_wrapper big_search_wrap">
                                        <i className="icon-search" />
                                        <input type="text" className="form-control border-0 rounded-0 career_search" id="search" placeholder="Search for job title or location" ></input>
                                        <button className="btn btn-primary rounded-0 input_explore_btn">Explore</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h4 className="mt-5 mb-4 color_red text-center">Browse jobs by team</h4>

            <div className="row">

                {
                list.filter(item => item.status === 1).map((item, i) => (
                    <div key={item._id} className="col-lg-4 col-md-6 mb-3">
                        <div className="card career_card cm_cursor" onClick={() => openModal(item)}>
                            <div className="card_img_wrap">
                                <img src={item.image} />
                            </div>
                            <div className="card_body text-center">
                                <h3>{item.jobTitle}</h3>
                            </div>
                        </div>
                    </div>
                ))
            }
            </div>

            <h4 className="mb-4 mt-4 color_red text-center">Jobs at <span className="font_raleway">CampsEZ</span></h4>


        </div>

            <div className="career_footer_section cm_banner_wrapper">
                    <img src={CampJobImg} />
                    <div className="cm_banner_text">
                        <div className="container text-center">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5 className="cm_color_white"><span className="font_raleway">CampsEZ</span> hasn’t added any post yet</h5>
                                    <p className="cm_color_white mt-3">Get Notified when <span className="font_raleway">CampsEZ</span> posts new jobs.</p>
                                    <div className="d-flex justify-content-center mt-lg-4">
                                        <button className="btn btn-white rounded-0 ml-2"><strong>Create job alerts</strong></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>



            <Modal
                show={showModal}
                animation={false}
                size="lg"
                centered
                >
                <Modal.Body>
                    <span className="modal_close" onClick={() => (setShowModal(false), setCurrentJob(''))}><i className="icon-cross" /></span>
                    
                    <div className="card career_card m-4">
                            <div className="card_img_wrap">
                                <img src={currentJob.image} />
                            </div>
                            <div className="card_body">
                                <h3>{currentJob.jobTitle}</h3>

                                <div className="mt-4">
                                    <p>{currentJob.desc}</p>
                                </div>

                                <div className="mt-5">
                                    <p>If you are interested in applying or learning more about a position, please send an intro email with the <b>Job Title</b> in the email Subject Line, and your resume to <a href="mailto: jobs@CampsEZ.com" target="_blank">jobs@CampsEZ.com</a>.  Inquiries that do not have the Job Title included in the subject line or do not have an attached resume will not be considered. Thank You!</p>
                                </div>
                            </div>
                        </div>
                </Modal.Body>
                </Modal>
        </div>
    )
}

export default Career;