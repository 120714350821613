import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Select, InputNumber, notification } from 'antd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';

import { isValidEmail } from '../../helper';
import { postYourCampService, getPageDataService } from '../../services/otherService';
import { ProgramLoader } from '../reuse/SVG';
import CampImg from '../../assets/img/camp.png';

const { Option } = Select;

const ListCamp = props => {
    const [loader, setLoader] = useState(false);
    const [list, setList] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [state, setState] = useState('');
    const [campName, setCampName] = useState('');
    const [category, setCategory] = useState('');
    const [averageAttendee, setAverageAttendee] = useState('');
    const [phone, setphone] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');


    const [firstNameErr, setFirstNameErr] = useState('');
    const [stateErr, setStateErr] = useState('');
    const [campNameErr, setCampNameErr] = useState('');
    const [categoryErr, setCategoryErr] = useState('');
    const [averageAttendeeErr, setAverageAttendeeErr] = useState('');
    const [phoneErr, setphoneErr] = useState('');
    const [emailErr, setEmailErr] = useState('');

    

    const handleValidate = () => {
        let validate = true;

        if(firstName === ''){
            setFirstNameErr('First name is required')
            validate = false
        }else if(firstName.length > 50){
            setFirstNameErr('First name is to large max limit 50')
            validate = false;
        }

        if(campName === ''){
            setCampNameErr('Camp name is required')
            validate = false
        }

        if(category === ''){
            setCategoryErr('Category is required')
            validate = false
        }

        if(email === ''){
            setEmailErr('Email is required')
            validate = false
        }else if(!isValidEmail(email)){
            setEmailErr('Email is not valid')
            validate = false
        }

        if(phone === ''){
            setphoneErr('Phone Number is required')
            validate = false
        }

        if(state === ''){
            setStateErr('State is required')
            validate = false
        }

        if(averageAttendee === ''){
            setAverageAttendeeErr('Average attendee is required')
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setLoader(true);

            let params = {
                firstName,
                lastName,
                state,
                campName,
                category,
                averageAttendee,
                phone,
                email,
                comment,
            }

            postYourCampService(params).then(res => {
                setLoader(false);
                if(res?.data?.statusCode){
                    setFirstName('');
                    setLastName('');
                    setState('');
                    setCampName('');
                    setCategory('');
                    setAverageAttendee('');
                    setphone('');
                    setEmail('');
                    setComment('');
                    

                    notification.open({
                        description:
                          'Partner added successfully',
                    });
                }
            })  
        }
    }


    const getPageData = () => {
        let query = `?page=lyc`;

        getPageDataService(query).then(res => {
            setLoader(false);
            if(res?.data?.statusCode);
            setList(res.data.responseData.result)
        })
    }

    useEffect(() => {
        getPageData();
    }, []);



    return(
        <div className="body_container list_camp">
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            

            {
                list.length ? list.filter(item => item.type === 'lycSection1').map(i => (
                    <div className="cm_banner_wrapper">
                        <img src={i.image} />
                        <div className="cm_banner_text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2>{i.title}</h2>
                                        <p className="line_h_2">{i.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ''
            }



            {
                list.length ? list.filter(item => item.type === 'lycSection2').map(i => (
                    <div className="cm_section mt-5">
                        <div className="container">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-6">
                                    <img className="img-fluid" src={i.image} />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="section_title_left">{i.title}</h2>
                                    <p>{i.content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ''
            }


            {
                list.length ? list.filter(item => item.type === 'lycSection3').map(i => (
                    <div className="cm_section ball_right">
                        <div className="container">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="section_title_left">{i.title}</h2>
                                    <p>{i.content}</p>
                                </div>
                                <div className="col-lg-6 order-first order-lg-2">
                                    <img className="img-fluid" src={i.image} />
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ''
            }



            
            {
                list.length ? list.filter(item => item.type === 'lycSection4').map(i => (
                    <div className="cm_section mt-5">
                        <div className="container">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-6">
                                    <img className="img-fluid" src={i.image} />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="section_title_left">{i.title}</h2>
                                    <p>{i.content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ''
            }


            {
                list.length ? list.filter(item => item.type === 'lycSection5').map(i => (
                    <div className="cm_section ball_right">
                        <div className="container">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="section_title_left">{i.title}</h2>
                                    <p>{i.content}</p>
                                </div>
                                <div className="col-lg-6 order-first order-lg-2">
                                    <img className="img-fluid" src={i.image} />
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ''
            }





            <div className="how_it_works_section">
                <div className="container">
                    <h2 className="section_title_center ">How it works</h2>

                    <div className="row text-center">

                        <div className="col-md-3">

                            <div className="ellipse_wrapper">
                                <img src={require('../../assets/img/Ellipse-yellow.png')} />
                                <img className="icon" src={require('../../assets/img/network.png')} />
                                <span className="ellipse_num">1</span>
                            </div>
                            <h5>Say Hello</h5>
                            <p>Fill out the information below and let us know about you!</p>
                        </div>

                        <div className="col-md-3">
                            <div className="ellipse_wrapper">
                                <img src={require('../../assets/img/Ellipse-yellow.png')} />
                                <img className="icon" src={require('../../assets/img/search.png')} />
                                <span className="ellipse_num">2</span>
                            </div>

                            <h5>We Reach Out To You</h5>
                            <p>We will reach back out and run through a quick introduction, so we can begin to get to know you and your organization personally.</p>
                        </div>

                        <div className="col-md-3">
                            <div className="ellipse_wrapper" >
                                <img src={require('../../assets/img/Ellipse-yellow.png')} />
                                <img className="icon" src={require('../../assets/img/notepad.png')} />
                                <span className="ellipse_num">3</span>
                            </div>

                            <h5>Enter Program Information</h5>
                            <p>Once an account is created you can begin entering in all your amazing activities and programs.</p>
                        </div>

                        <div className="col-md-3">
                            <div className="ellipse_wrapper">
                                <img src={require('../../assets/img/Ellipse-yellow.png')} />
                                <img className="icon" src={require('../../assets/img/class.png')} />
                                <span className="ellipse_num">4</span>
                            </div>

                            <h5>Greet Participants</h5>
                            <p>(The best part!) People enjoying the fun activities, programs, and camps that you provide!</p>

                        </div>

                    </div>
                </div>
            </div>



            <div className="know_about_you_wrapper">
                <img src={CampImg} />
                <div className="container list_camp_form_wrapper">
                    <div className="text-center">
                        <h3 className="white mb-md-5">Let us know about you</h3>
                    </div>
                <form onSubmit={handleSubmit} className="list_camp_form">

                
                    <div className="row">
                        <div className="col-lg-2" />
                        <div className="col-lg-8">
                            <div className="row">

                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                                        value={firstName}
                                        onChange={e => (setFirstName(e.target.value), setFirstNameErr(''))}
                                        placeholder="First Name" />

                                    {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        placeholder="Last Name" />
                                    
                                </div>

                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        className={`form-control ${campNameErr ? 'is-invalid' : ''}`}
                                        value={campName}
                                        onChange={e => (setCampName(e.target.value), setCampNameErr(''))}
                                        placeholder="Organization Name" />
                                    
                                    {campNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{campNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                                <div className="form-group col-md-6">
                                    <Select
                                        className={`form-control ${categoryErr ? 'is-invalid' : ''} pr-0`}
                                        placeholder="Category" 
                                        value={category ? category : null}
                                        onChange={e => (setCategory(e), setCategoryErr(''))} 
                                    >    
                                        {props?.preList?.category.map(item => <Option key={item._id} value={item._id}>{item.name}</Option>)}
                                    </Select>

                                    {categoryErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{categoryErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>


                                <div className="form-group col-md-6">
                                    <input
                                        type="email"
                                        className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                                        value={email}
                                        onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                                        placeholder="Email" />
                                    
                                    {emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                                <div className="form-group col-md-6">
                                    <PhoneInput
                                        country="US"
                                        placeholder="Phone Number"
                                        className={`form-control ${phoneErr ? 'is-invalid' : ''}`}
                                        onChange={e => (setphone(e), setphoneErr(''))}
                                        value={phone}
                                        maxLength={16}
                                        />

                                    {phoneErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{phoneErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>


                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        className={`form-control ${stateErr ? 'is-invalid' : ''}`}
                                        value={state}
                                        onChange={e => (setState(e.target.value), setStateErr(''))}
                                        placeholder="State" />

                                    {stateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{stateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                                <div className="form-group col-md-6">
                                    <InputNumber
                                        value={averageAttendee}
                                        placeholder="Average # Annual Attendees"
                                        className={`form-control ${averageAttendeeErr ? 'is-invalid' : ''}`}
                                        onChange={e => (setAverageAttendee(e), setAverageAttendeeErr(''))} />
                                    {averageAttendeeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{averageAttendeeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                                <div className="col-12 form-group">
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        value={comment}
                                        onChange={e => setComment(e.target.value)}
                                        placeholder="Comment" />
                                </div>

                                <div className="col-12 text-center">
                                    <button type="submit" className="btn btn-primary  w-25 m-auto">Submit</button>
                                </div>

                            </div>
                        </div>
                    </div>


                </form>
                </div>
            </div>


        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { preList } = state.program;
    return {
        state,
        preList
    };
}
  
export default connect(mapStateToProps)(ListCamp);

