import React, { useReducer, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import { verifyResetToken } from '../../services/authService';
import { resetPasswordAction } from '../../store/user';
import { closeResetPasswordModal } from '../../store/ui';
import { isValidPassword } from '../../helper';


const ResetPasswordModal = props => {
    const token = window.location.pathname.split('token_')[1];
    const [tokenVarify, setTokenVarify] = useState(1);
    const [showPass, setShowPass] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            password: '',
            confirmPassword: ''
        }
    );

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            password: '',
            confirmPassword: ''
        }
    );



    useEffect(() => {
        let params = {token};
        verifyResetToken(params).then(status => {
            setTokenVarify(status);
            if(status === 0){
                props.closeResetPasswordModal();
            }else{
                localStorage.removeItem('accessToken');
                localStorage.removeItem('user');
            }
            
        });
    }, []);



    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
    }


    const handleValidate = () => {
        let validate = true;
        let { password, confirmPassword } = userInput;
       

        if(password === ''){
            setError({password: 'Password is required'});
            validate = false
        }else if(!isValidPassword(password)){
            setError({password: 'Password needs to have at least one lower case, one uppercase, one number, one special character, and  must be atleast 8 characters but no more than 35. '});
            validate = false
        }

        if(password !== confirmPassword){
            setError({confirmPassword: 'Password not match'});
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let { password } = userInput;
            let params = {
                token,
                password
            }

           props.resetPasswordAction(params)
        }
    }


   
    

    return(
        <Modal
            dialogClassName="auth_modal h_auto"
            show={props.resetPasswordModal}
            animation={false}
            centered
        >
            <Modal.Body>
            {tokenVarify === 0 ? <Redirect to="/" /> : ''}

            <span className="modal_close" onClick={props.closeResetPasswordModal}><i className="icon-cross" /></span>
            <h3 className="text-center mt-3 mb-0">Reset your password</h3>


                <form onSubmit={handleSubmit} className="p-5">
                    <input type="email" name="email" style={{display: 'none'}} />

                            <div className="form-group eye_wrapper">
                                <input
                                    type={showPass ? 'text' : 'password'}
                                    placeholder="New password"
                                    className={`form-control ${error.password ? 'is-invalid' : ''}`}
                                    name="password"
                                    value={userInput.password}
                                    onChange={handleChange} />

                                    <i className={`icon-eye ${showPass ? 'slash' : ''}`} onClick={() => setShowPass(!showPass)} />

                                {error.password ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.password}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>

                            <div className="form-group eye_wrapper">
                                <input
                                    type={showPass2 ? 'text' : 'password'}
                                    placeholder="Re-enter new password"
                                    className={`form-control ${error.confirmPassword ? 'is-invalid' : ''}`}
                                    name="confirmPassword"
                                    value={userInput.confirmPassword}
                                    onChange={handleChange} />

                                    <i className={`icon-eye ${showPass2 ? 'slash' : ''}`} onClick={() => setShowPass2(!showPass2)} />

                                {error.confirmPassword ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.confirmPassword}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>

                            <div className="text-right">
                                <button
                                    type="submit"
                                    disabled={props.miniLoader}
                                    className={`btn btn-primary rounded-0 btn-block mt-3 btn_anim ${props.miniLoader ? 'show_anim' : ''}`}>{props.miniLoader ? 'Loading...' : 'Save'}</button>
                            </div>

                        </form>
            </Modal.Body>
        </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { miniLoader, resetPasswordModal } = state.ui;
    return {
        miniLoader,
        resetPasswordModal
    };
}
  
const mapDispatchToProps = dispatch => ({
    resetPasswordAction: params => dispatch(resetPasswordAction(params)),
    closeResetPasswordModal: () => dispatch(closeResetPasswordModal()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);