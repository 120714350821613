import React, {useState, useEffect, Fragment }from 'react';
import { DatePicker } from 'antd';
import { Controller, useForm } from "react-hook-form";
import { connect } from 'react-redux';
import moment from 'moment';

import {shortsAndTshirtSizes, medical, allergy, gradeArr, provinceList, shortsAndTshirtSizes2} from '../../../helper';
import MultiselectDropdownCommon from './MultiselectDropdownCommon';
import {uploadMedia} from '../../../services/campersServices';
import { PDFIcon } from '../../reuse/SVG';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DateSelector from '../../date/DateSelector';
import { CustomNumberInput, InputTextError } from '../../reuse/FormControls';

let currentDate = new Date();
const  disabledDate = (current) => {
    return current && current > moment(currentDate).startOf('day');
}


const CamperFormFileds = (props) => {
    const[profileimageurl, setProfileImageUrl]      = useState();
    const fieldNamePrev                             = `participants[${props.FormIndex}]`;
    const refprop                                   = props.refprops;
    const [fieldName, setFiedTestname]              = useState(fieldNamePrev);
    const [hippaform, setHippaForm]                 = useState();
    const [medicalform, setMedicalForm]             = useState();
    const [activityInterest, setActivityInterest]   = useState([]);
    const [servicepro, setServicePro]               = useState(1)
    const [allergyisDeadly, setAllergyisDeadly]     = useState('');
    const [permissionEmergencyMedication, setPermissionEmergencyMedication]                 = useState('');
    const [participantReceiveCounterMedication, setParticipantReceiveCounterMedication]     = useState('');
    const [participantBringingOwnMedication, setParticipantBringingOwnMedication]           = useState(''); 
    const dateFormatNew                             = 'MM/DD/YYYY';
    const [isSameAddress, setIsSameAddress]           = useState(false);
    const [dob, setDob] = useState(moment(currentDate).valueOf());
    const [dobErr, setDobErr] = useState('');
 


    const removeClass = e => {
        // let elm = e.target.id;
        // if(elm){
        //     const element = document.getElementById(elm);
        //     element.classList.remove('is-invalid')
        // }        
    }
    

    useEffect(() => {
        let filedNameprev = `participants[${props.FormIndex}]`;
        setFiedTestname(filedNameprev);
    },[props.FormIndex])


 
 
    const uploadHippaCompliance = (e, type) => {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        uploadMedia(data).then((res)=>{
            if(res.data.responseData.result.fileUrl!==undefined)
            if(type==='hippa')
            {
                setHippaForm(res.data.responseData.result.fileUrl)
            }
            if(type==='medical')
            {
                setMedicalForm(res.data.responseData.result.fileUrl)
            }
        })
    }


    const upload_image = (e) =>{
        if (e.target.files[0]) {
            const data = new FormData();
            data.append('file', e.target.files[0]);
            uploadMedia(data).then((res)=>{
                if(res.data.responseData.result.fileUrl!==undefined)
                {
                    setProfileImageUrl(res.data.responseData.result.fileUrl);
                }
            })
        }
    }
    

    const handelAllergydeadly = () =>{
        setAllergyisDeadly(2);
    }

    const handleSameAddress =() => {
    setIsSameAddress(prev => !prev)
    }
    const handleDateChange = (timestamp) => {
        const date = new Date(timestamp);
        let _timestamp = moment(date);
        setDob(_timestamp.valueOf());
        props.setValue(`${fieldName}.dob`, _timestamp)
      };

      useEffect(() => {
        if(isSameAddress && (props?.lastCamper || props.FormIndex > 0)){
            if(props?.lastCamper ){
            props.setValue(`${fieldName}.address`, props.lastCamper?.address)
            props.setValue(`${fieldName}.apartment`, props.lastCamper?.apartment)
            props.setValue(`${fieldName}.city`, props.lastCamper?.city)
            props.setValue(`${fieldName}.zipCode`, props.lastCamper?.zipCode)
            props.setValue(`${fieldName}.state`, props.lastCamper?.state)
        }else{
            const values = props.getValues()
            props.setValue(`${fieldName}.address`, values[`participants[${props.FormIndex -1}].address`])
            props.setValue(`${fieldName}.apartment`, values[`participants[${props.FormIndex -1}].apartment`])
            props.setValue(`${fieldName}.city`, values[`participants[${props.FormIndex -1}].city`])
            props.setValue(`${fieldName}.zipCode`, values[`participants[${props.FormIndex -1}].zipCode`])
            props.setValue(`${fieldName}.state`, values[`participants[${props.FormIndex -1}].state`])
        }
        }else{
            props.setValue(`${fieldName}.address`, '')
            props.setValue(`${fieldName}.apartment`, '')
            props.setValue(`${fieldName}.city`, '')
            props.setValue(`${fieldName}.zipCode`, '')
            props.setValue(`${fieldName}.state`, '')
        }
      }, [isSameAddress])
    return(
        
        <div className="row pro_wrapper" key={props.FormIndex+1}>

            <div className="col-lg-11">
                <div className="profile_upload">
                    <img src={profileimageurl ? profileimageurl : require('../../../assets/img/camps-user.png')} />
                    <input onChange={upload_image} type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    <i className="icon-camera-plus" />
                    {profileimageurl ?   <input  type="hidden" ref={refprop} name={`${fieldName}.profileImage`} value={profileimageurl} /> : '' } 
                </div>
            </div>

            
            <div className="col-lg-11">
                <div className="d-flex justify-content-between">
                    <div>
                        <p className="color_primary">
                            Participant {props.FormIndex+1} : 
                            <input
                                type="text"
                                name={`${fieldName}.groupcode`}
                                className={props.Errors.includes(`camper${props.FormIndex}groupcode`) ? 'is-invalid group_code' :'group_code'}
                                value={props.GroupCode}
                                ref={refprop}
                                placeholder="Group Code" />
                        </p>
                    </div>

                    <div>
                        {
                            props.RemoveCamper ? <span className="btn btn-primary btn-sm rounded-0" test={props.FormIndex} onClick={props.RemoveCamper(props.FormIndex)}><i className="icon-delete" /> Delete</span> : ''
                        }
                    </div>
                </div>
            </div> 


            <div className="col-lg-11">
                <div className="row">


                    <div className="col-md-6 form-group">
                        <label>First Name</label>
                        <input
                            type="text"
                            id={`${fieldName}.firstName`}
                            onChange={removeClass}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}firstName`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.firstName`}
                            placeholder="First Name"
                            ref={refprop} />
                        
                        {props.Errors.includes(`camper${props.FormIndex}firstName`) ? <OverlayTrigger placement="left" overlay={<Tooltip>{props.Errors.includes(`camper${props.FormIndex}firstName`)}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Last Name</label>
                        <input
                            type="text"
                            id={`${fieldName}.lastName`}
                            onChange={removeClass}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}lastName`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.lastName`}
                            placeholder="Last Name"
                            ref={refprop} />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Date of Birth </label>
                        <Controller
                            as={
                                <DateSelector
                                    dateValue={dob}
                                    dobErr={dobErr}
                                    onDateChange={handleDateChange}
                                    setDobErr={setDobErr}
                                    disabledDate={disabledDate}
                                />
                                }
                            onChange={handleDateChange}
                            control={props.Control} 
                            name={`${fieldName}.dob`}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}dob`) ? 'is-invalid' : ''}`} 
                            ref={refprop}
                            />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Select Current Grade As of Today</label>
                        <select
                            name={`${fieldName}.currentGrade`}
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}currentGrade`) ? 'is-invalid' : ''}`}>
                            <option value="">Select Grade</option>
                            {gradeArr.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                    </div>

                        
                    <div className="col-md-12 mb-3 d-flex cm_gendr">
                        <label>Sex</label>
                        <ul className="d-flex">
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    ref={refprop}
                                    type="radio"
                                    value="1" 
                                    name={`${fieldName}.gender`} />
                                <span className="check_box"><span /> Male</span> 
                            </li>
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    ref={refprop}
                                    type="radio"
                                    value="2"
                                    name={`${fieldName}.gender`}  />
                                <span className="check_box"><span /> Female</span> 
                            </li>
                        </ul>

                        {props.Errors.includes(`camper${props.FormIndex}gender`) ? <span className="cm_err_msg">Gender is Required</span> :''}
                    </div>
                   { (props?.lastCamper || props.FormIndex > 0) &&  
                    <div className="col-md-12 mb-3 d-flex cm_gendr">
                            <label>Same Address </label>
                                <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="checkbox"
                                        onChange={handleSameAddress}
                                        checked={isSameAddress} />
                                    <span className="check_box"><span /></span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                </li>
                                </ul>
                    </div>
                    }

                    <div className="col-md-6 form-group">
                        <label>Street Address</label>
                        <input
                            ref={refprop}
                            type="text"
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}address`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.address`}
                            placeholder="Street Address" />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Apt./Suite/Unit [optional]</label>
                        <input
                            ref={refprop}
                            type="text"
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}apartment`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.apartment`}
                            placeholder="Apt./Suite/Unit [optional]" />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>City</label>
                        <input
                            ref={refprop}
                            type="text"
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}city`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.city`}
                            placeholder="City" />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Zip Code</label>
                        <input
                            type='number'
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}zipCode`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.zipCode`}
                            placeholder="Zip Code" 
                            />
                        {props.Errors.includes(`camper${props.FormIndex}zipCode`) && <InputTextError error={'zip code must be at least 5 digits long.'}/>}

                    </div>


                    <div className="col-md-6 form-group">
                        <label>State/Province</label>
                        <select
                            name={`${fieldName}.state`}
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}state`) ? 'is-invalid' : ''}`}>
                            <option value="">Select State/Province</option>   
                            {provinceList.map(item => <option key={item.value} value={item.value}>{item.value} - {item.label}</option>)}
                        </select>
                    </div>
                    
              

                    <div className="col-sm-12" />

                    {
                        props.isSportInternationalCamp ? <div className="col-md-6 form-group">
                        <label>T-shirt Size</label>
                        <select
                            name={`${fieldName}.tshirtSize`}
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}tshirtSize`) ? 'is-invalid' : ''}`}>
                            <option value="">Select T-shirt Size</option>   
                            {shortsAndTshirtSizes2.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                    </div> : <div className="col-md-6 form-group">
                        <label>T-shirt Size</label>
                        <select
                            name={`${fieldName}.tshirtSize`}
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}tshirtSize`) ? 'is-invalid' : ''}`}>
                            <option value="">Select T-shirt Size</option>   
                            {shortsAndTshirtSizes.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                    </div>
                    }
                    

                

                    {
                        props.isSportInternationalCamp ? '' : (
                            <Fragment>

                                <div className="col-md-6 form-group">
                                    <label>Medical</label>
                                    <MultiselectDropdownCommon
                                        RequiredError={props.Errors.includes(`camper${props.FormIndex}medical`) ? true : false}
                                        Multiselectvalues={medical}
                                        Ref={refprop}
                                        Fieldname={`${fieldName}.medical`}
                                        PlaceHolder="Select Medical"/>
                                </div>


                                <div className="col-md-6 form-group">
                                    <label>Allergy</label>
                                    <MultiselectDropdownCommon
                                        RequiredError={props.Errors.includes(`camper${props.FormIndex}allergy`) ? true : false}
                                        Multiselectvalues={allergy}
                                        handelAllergydeadly={handelAllergydeadly}
                                        Ref={refprop}
                                        Fieldname={`${fieldName}.allergy`}
                                        PlaceHolder="Select Allergy"/> 
                                </div>


                                <div className="col-md-12 mb-3 d-flex cm_gendr">
                        {props.Errors.includes(`camper${props.FormIndex}allergyDeadly`) ? <span className="cm_err_msg">Field is required</span> : ''}
                        <label>Allergy is deadly</label>
                        <ul className="d-flex">
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={1}
                                    onChange={() => setAllergyisDeadly(1)}
                                    checked={allergyisDeadly === 1 ? 'checked' : ''}
                                    name={`${fieldName}.allergyDeadly`} />
                                <span className="check_box"><span /> Yes</span> 
                            </li>
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={2}
                                    onChange={() => setAllergyisDeadly(2)}
                                    checked={allergyisDeadly === 2 ? 'checked' : ''}
                                    name={`${fieldName}.allergyDeadly`} />
                                <span className="check_box"><span /> No</span> 
                            </li>
                        </ul>
                    </div>
                    
                    
                    <div className="col-sm-12 mb-3">
                        <h6>Insurance Information:</h6>
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Medical Insurance Company</label>
                        <input
                            type="text"
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}medicalInsuranceCompany`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.medicalInsuranceCompany`}
                            placeholder="Medical Insurance Company" />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Policy Number</label>
                        <input
                            type="text"
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}policyNumber`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.policyNumber`}
                            placeholder="Policy Number" />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Group #</label>
                        <input
                            type="text"
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}group`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.group`}
                            placeholder="Group #" />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Primary Physician</label>
                        <input
                            type="text"
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}primaryPhysician`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.primaryPhysician`}
                            placeholder="Primary Physician" />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Physician Address</label>
                        <input
                            type="text"
                            ref={refprop}
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}physicianAddress`) ? 'is-invalid' : ''}`}
                            name={`${fieldName}.physicianAddress`}
                            placeholder="Physician Address" />
                    </div>


                    <div className="col-md-6 form-group">
                        <label>Physician Phone #</label>
                        <div className="cm_phone_field">
                            <img className="us_flag" src={require('../../../assets/img/united-states-flag-icon.svg')} />
                            <input
                                type="number"
                                ref={refprop}
                                className={`form-control ${props.Errors.includes(`camper${props.FormIndex}physicianPhone`) ? 'is-invalid' : ''}`}
                                name={`${fieldName}.physicianPhone`}  />
                        </div>
                        
                    </div>


                    <div className="col-md-12 form-group">
                        <label>Please list any medications taken(please include dosage & timing) or any other special assistance necessary to attend a program. (Leave blank if none)</label>
                        <textarea
                            ref={refprop}
                            name={`${fieldName}.medicationsTaken`}
                            placeholder="Write something here…"
                            className={`form-control ${props.Errors.includes(`camper${props.FormIndex}medicationsTaken`) ? 'is-invalid' : ''}`} />
                    </div>


                    <div className="col-md-12 form-group">
                        <label>Parent Permission for EMERGENCY MEDICATIONS: (rescue inhaler, glucagon, epinephrine)</label>
                    </div>


                    <div className="col-md-12 mb-3 cm_gendr">
                        {props.Errors.includes(`camper${props.FormIndex}permissionEmergencyMedication`) ? <span className="cm_err_msg">Field is required</span> :''}
                        <label>Participant has permission to carry his/her own emergency medication, or inhaler:</label>
                        <ul className="d-flex">
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={1}
                                    onChange={() => setPermissionEmergencyMedication(1)}
                                    checked={permissionEmergencyMedication === 1 ? 'checked' : '' }
                                    name={`${fieldName}.permissionEmergencyMedication`} />
                                <span className="check_box"><span /> Yes</span> 
                            </li>
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={2}
                                    onChange={() => setPermissionEmergencyMedication(2)}
                                    checked={permissionEmergencyMedication === 2 ? 'checked' : ''}
                                    name={`${fieldName}.permissionEmergencyMedication`} />
                                <span className="check_box"><span /> No</span> 
                            </li>
                        </ul>

                    </div>



                    <div className="col-md-12 mb-3 cm_gendr">
                    {props.Errors.includes(`camper${props.FormIndex}participantReceiveCounterMedication`) ? <span className="cm_err_msg">Field is required</span> :''}
                        <label>May the participant receive over-the counter medication:</label>
                        <ul className="d-flex">
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={1}
                                    onChange={() => setParticipantReceiveCounterMedication(1)}
                                    checked={participantReceiveCounterMedication === 1 ? 'checked' : ''}
                                    name={`${fieldName}.participantReceiveCounterMedication`} />
                                <span className="check_box"><span /> Yes</span> 
                            </li>
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={2}
                                    onChange={() => setParticipantReceiveCounterMedication(2)}
                                    checked={participantReceiveCounterMedication === 2 ? 'checked' : ''}
                                    name={`${fieldName}.participantReceiveCounterMedication`} />
                                <span className="check_box"><span /> No</span> 
                            </li>
                        </ul>

                    </div>


                    <div className="col-md-12 mb-3 cm_gendr">
                        {props.Errors.includes(`camper${props.FormIndex}participantBringingOwnMedication`) ? <span className="cm_err_msg">Field is required</span> :''}
                        <label>Will the participant be bringing their own medication, or an inhaler to a program: </label>
                        <ul className="d-flex">
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={1}
                                    onChange={() => setParticipantBringingOwnMedication(1)}
                                    checked={participantBringingOwnMedication === 1 ? 'checked' : ''}
                                    name={`${fieldName}.participantBringingOwnMedication`} />
                                <span className="check_box"><span /> Yes</span> 
                            </li>
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={2}
                                    onChange={() => setParticipantBringingOwnMedication(2)}
                                    checked={participantBringingOwnMedication === 2 ? 'checked' : ''}
                                    name={`${fieldName}.participantBringingOwnMedication`} />
                                <span className="check_box"><span /> No</span> 
                            </li>
                        </ul>
                    </div>                            


                    <div className="col-md-12 form-group">
                        <label>If the Participant is bringing medication please note:</label>
                        <label>All Medication must be sent in the original container and must state the current instructions for dispensing.  All medications must be turned in when attendee is dropped off, if permission is not granted above for them to carry it.</label>  
                    </div>  
                            
                            </Fragment>
                        )
                    }


                    

                    {props.isSportInternationalCamp ? '' : <div className="col-md-12 mb-3 cm_gendr">
                        <label>Service Provider Staff may document participant with digital and film photography and/or video.</label>
                        <ul className="d-flex">
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={1}
                                    checked={servicepro === 1 ? 'checked' : ''}
                                    name={`${fieldName}.allowDocumentCamper`}
                                    onChange={() => setServicePro(1)} />
                                <span className="check_box"><span /> Yes</span> 
                            </li>
                            <li className="mr-2 check_box_wrapper">
                                <input
                                    type="radio"
                                    ref={refprop}
                                    value={2}
                                    checked={servicepro === 2 ? 'checked' : ''}
                                    name={`${fieldName}.allowDocumentCamper`}
                                    onChange={() => setServicePro(2)} />
                                <span className="check_box"><span /> No</span> 
                            </li>
                        </ul>
                        <div className="position-relative pt-2">
                            {props.Errors.includes(`camper${props.FormIndex}allowDocumentCamper`) ? <span className="cm_err_msg">Document is Required</span> :''} 
                        </div>
                    </div> }


                                              


                    


                    <div className="col-md-12 form-group">
                        <label>Please attach any accompanying documents that you feel are necessary for attendance (Additional Medical document etc).</label>
                        <div  className="attach_file_btn">Attach PDF <input type="file" value="" accept="application/pdf" onChange={(e)=>{uploadHippaCompliance(e,'medical')}} /></div>
                        
                        {medicalform ? <div className="cm_pdf_icon"><PDFIcon /><i className="icon-cross" onClick={() => setMedicalForm()} /> <p>{medicalform ? medicalform.slice(medicalform.lastIndexOf('_') + 1) : ''}</p></div> : ''}
                        
                        {medicalform && <input ref={refprop} value={medicalform} type="hidden" name={`${fieldName}.medicalDocument`} />}                                
                    </div>
                </div>
            </div>

        
            {
                props.loggedIn && <input type="hidden" name={`${fieldName}.camperId`}  ref={refprop} value={props.userID}/>                
            }    
            <input type="hidden"  name={`${fieldName}.activityInterest`} value={JSON.stringify(activityInterest)} ref={refprop}  />
        </div>



    )
}
const mapStateToProps = state => {
    let { preList, fSubcategory} = state.program;
    let { loggedIn } = state.user;
    let userID =false;

    if(state.user.userProfile!== undefined)
    {
        userID = state.user.userProfile._id;
    }
    
    return{
        preList,
        fSubcategory,
        loggedIn,
        userID
    }
}

export default connect(mapStateToProps, null)(CamperFormFileds);