import React from 'react'

const MessageTypeBadge = ({ type }) => {
    return (
        <div style={{ fontSize: '8px' }}>
            {
                type === 3 ?
                    <span className='p-1 bg-info rounded-pill fst-italic'>Email</span> :
                    type === 1 ?
                        <span className='p-1 bg-warning rounded-pill fst-italic'>Phone</span>
                        :
                        <span className='p-1 bg-danger rounded-pill text-white fst-italic'>App</span>
            }
        </div>
    )
}

export default MessageTypeBadge