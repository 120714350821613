import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Modal, Dropdown, OverlayTrigger, Tooltip, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { notification } from 'antd';
import Select from 'react-select';
import {
    directorbookinglist,
    directorCamperlist,
    createBookingfolder,
    deleteBookingfolder,
    counselordetail
} from '../../services/directorServices';
import { toggleAddFolderModal } from '../../store/ui';
import CampCard from './CampCard';
import { ProgramLoader, FolderIcon} from '../reuse/SVG';
import {  ageArr, genders2} from '../../helper';
import RewardPointsImg from '../../assets/img/rewardPoints.png';


const fixedDirListDiv = {
    maxHeight: "580px",
    overflowY: "scroll",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "#f9f9f9",
}

const Bookings = props => {
    const [bookings, setBookings]                     = useState([]);
    const [folders, setFolders]                       = useState([]);
    const [campers, setCampers]                       = useState([]);
    const [loading, setLoading]                       = useState(true);
    const [addFoder, setAddFolder]                    = useState(false);
    const [folderPopup, setFolderPopup]               = useState(false);    
    const [folderName, setFoldername]                 = useState('');
    const [folderNameErr, setFoldernameErr]           = useState('');
    const [selectAll, setSelectAll]                   = useState(false);  
    const [programsIds, setProgramsIds]               = useState([]);
    const [campersWarning, setCampersWarning]         = useState(false); 
    const [btnloder, setBtnLoader]                    = useState(false);
    const [deleteFolder, setDeleteFolder]             = useState(false);
    const [deleteFolderId, setDeleteFolderId]         = useState();
    const [folderalreadyExit, setFolderAlreadyExit]   = useState(false);
    const [agegroup, setAgeGroup]                     = useState();
    const [gender, setGender]                         = useState();
    const [search, setSearch]                         = useState('');
    const [bookingType, setBookingType]               = useState(2);
    const [permissions, setPermissions]               = useState({});
    const [isHideDropdown, setIsHideDropdown]         = useState(false);
    const [isSearchToched, setIsSearchToched]         = useState(false);


    useEffect(() => {
        if(props.UserType === 4){
            counselordetail(`/?counselorId=${props._id}`).then((res) => {
                if(res.data.statusCode === 1){
                    setPermissions(res.data.responseData.result.permissions || {}) 
                }
            })
        }
    }, [])

    useEffect(()=>{
        let params = `/?campCode=${props.campCode}`
        directorCamperlist(params).then(res => {
            if(res?.data?.statusCode){
                setCampers(res.data.responseData.result)
                setLoading(false);
            }else{
                setLoading(false);
            }
        })
    },[])


    const getDirectorbookinglist = () => {
        setLoading(true);
        setBookings([]);
        let params = `/?campCode=${props.campCode}&programType=${bookingType}&search=${search}`
        directorbookinglist(params).then(res => {
            if(res?.data?.statusCode){
                setBookings(res.data.responseData.result.programs)
                setFolders(res.data.responseData.result.folder)
                setLoading(false);
            }else{
                setLoading(false);
            }
        })
    }
    


    useEffect(() => {
        getDirectorbookinglist();
    }, [bookingType])


    useEffect(() => {
 
        const _setTimeout = setTimeout(() => {
            if(isSearchToched){
                getDirectorbookinglist();
            }
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])
    


    const handelCreateFolder = () => {
        if(folderName === '' || folderName.trim() === ''){
            setFoldernameErr('Folder name is required');
        }else{
            setFolderPopup(false);
            setAddFolder(true)
        }
    }
    


    const handelPrograms = e => {
        let listprograms = [...programsIds];
        setCampersWarning(false);
        if(listprograms.includes(e.target.value)){
            setSelectAll(false)
            let newProgramslist = listprograms.filter(item => item!==e.target.value) 
            setProgramsIds(newProgramslist)
        }else{
            listprograms.push(e.target.value);
            if(listprograms.length === bookings.length){setSelectAll(true)}
            setProgramsIds(listprograms)
        }
    }   

    const selectAllPrograms = () => {
        let listprograms = [...programsIds];
        listprograms.length = 0;
        bookings.map(item=>{
            listprograms.push(item._id);
        })
        setProgramsIds(listprograms)
    }

    const deselectAllPrograms = () => {
        let listprograms = [...programsIds];
        listprograms.length = 0;
        setProgramsIds(listprograms)
    } 
    
    
    const addFolder = () => {
        if(programsIds.length > 0){
                let params ={"folderName":folderName, "programIds": JSON.stringify(programsIds)}
                createBookingfolder(params).then((res) => {
                    if(res?.data?.statusCode)
                    {
                        let campCode = props.campCode
                        //let param = `/?campCode=${campCode}`
                        let params = `/?campCode=${props.campCode}&programType=${bookingType}&search=${search}`
                        directorbookinglist(params).then((res)=>{
                            if(res?.data?.statusCode)
                            {
                                setBookings(res.data.responseData.result.programs)
                                setFolders(res.data.responseData.result.folder)
                                setBtnLoader(false);
                                setAddFolder(false); 
                                setFoldername('');
                                setProgramsIds([]);
                                setSelectAll(false);
                                notification.open({
                                    description:
                                      'Folder created successfully ',
                                  });
                            }
                            else{}
                        })

                        directorCamperlist(params).then((res)=>{
                            if(res?.data?.statusCode)
                            {
                                setCampers(res.data.responseData.result)
                                setLoading(false);
                            }
                            else
                            {
                                setLoading(false);
                
                            }
                        })
                    }
                    else if(res.data.statusCode===0){
                        setBtnLoader(false);
                        setFolderAlreadyExit(true)
                    }
                    else
                    {

                    }
                })
        }
        else
        {
            setCampersWarning(true);
            setBtnLoader(false);
        }
    }



    const deleteFolderAction = () => {
        let foldersList =[...folders];
        let params = `/?folderId=${deleteFolderId}`;
        deleteBookingfolder(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                let newfolderlist = foldersList.filter(item => item._id !== deleteFolderId)
                    //  setFloderList(newfolderlist);
                setDeleteFolder(false);
                let campCode = props.campCode
                //let param = `/?campCode=${campCode}`
                let params = `/?campCode=${props.campCode}&programType=${bookingType}&search=${search}`
                directorbookinglist(params).then((res)=>{
                    if(res?.data?.statusCode)
                    {   
                        setBookings(res.data.responseData.result.programs)
                        setFolders(res.data.responseData.result.folder)
                        setLoading(false)  
                    }else{setLoading(false)}
                })


                notification.open({
                    description:
                      'Folder Deleted Successfully',
                  });
            }
        })   
    }
    

    const applyfilter = () => {

  
        setIsHideDropdown(false);

        setLoading(true);
        let campCode = props.campCode
        //let params = `/?campCode=${campCode}`
        let params = `/?campCode=${props.campCode}&programType=${bookingType}&search=${search}`
        if(agegroup!== undefined){ params += `&ageGroup=${agegroup}` }
        if(gender!==undefined){ params += `&gender=${gender}` }
        directorbookinglist(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                setBookings(res.data.responseData.result.programs)
                setFolders(res.data.responseData.result.folder)
                setLoading(false);
            }
            else
            {
                setLoading(false);

            }
        })
    }

    const clearfilter = () => {
        setIsHideDropdown(false);
        setAgeGroup('');
        setGender('');
        setLoading(true);
        let campCode = props.campCode
       // let params = `/?campCode=${campCode}`
        let params = `/?campCode=${props.campCode}&programType=${bookingType}&search=${search}`
        directorbookinglist(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                setBookings(res.data.responseData.result.programs)
                setFolders(res.data.responseData.result.folder)
                setLoading(false);
            }
            else
            {
                setLoading(false);

            }
        })  
    }


    return(
        <div className="body_container pb-4 director_wrapper cm_over_auto">
            
            <Modal
            show={folderPopup}
            animation={false}  
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setFolderPopup(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <h3 className="create_f_title">Create a Folder</h3>
                </div>


                <div className="form-group">
                    <input
                        type="text"
                        value={folderName}
                        onChange={e => (setFoldername(e.target.value), setFoldernameErr(''))}
                        className={`form-control form-control-lg ${folderNameErr ? 'is-invalid' : ''}`} />

                    {folderNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{folderNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                </div>

                <div className="text-center mb-4"> 
                <button onClick={handelCreateFolder} style={{width: '100%'}} className="btn btn-primary">Done</button>
                </div>
            </Modal.Body>
            </Modal>



            <Modal
            show={deleteFolder}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setDeleteFolder(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to delete this folder</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setDeleteFolderId(''); setDeleteFolder(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button  onClick={deleteFolderAction} style={{width: '120px'}} className="btn btn-primary ml-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal>

            {
            !addFoder ?
            <div className="container"
            style={{
                maxHeight: "800px",
            }}
            >
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Bookings</h4>
                    <div className="btn_group">
                       
                        <button className="btn fix_btn btn-outline-primary mr-2" onClick={() => window.print()}><i className="icon-print" /> Print</button>
                        <button className="btn btn-primary mr-2" onClick={() => setFolderPopup(true)}><i className="icon-folder-2" /> Add Folder</button>
                        {props.UserType === 4 && !permissions.createBooking ? '' : <Link to="/booking/create" className="btn fix_btn btn-outline-primary"><i className="icon-plus" /> Create Booking</Link>}
                        
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-1" />

                    <div className="col-lg-10">

                        <div className="d-flex align-items-center justify-content-between page_card_header cm_border_0">
                            <h4>Bookings Info</h4>
                            <div className="">
                                <button onClick={() => setBookingType(2)} className={`mr-2 btn btn-outline-dark ${bookingType === 2 ? 'active' : ''}`}>Upcoming</button>
                                <button onClick={() => setBookingType(1)} className={`mr-2 btn btn-outline-dark ${bookingType === 1 ? 'active' : ''}`}>Past</button>
                            </div>
                            <div className="btn_group">
                                
                            </div>
                        </div>

                        <div className="partner-form search_wrap" style={{maxWidth: '100%'}}>
                            <i className="icon-search" />
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                value={search}
                                onChange={e => (setSearch(e.target.value), setIsSearchToched(true))}
                                placeholder="Search" />
                        </div>
                    <Container
                            className='scrollable-div'
                            style={fixedDirListDiv}
                        >
                        { loading &&  <div className="program_loader"><ProgramLoader /></div>  }
                        {
                            folders.length > 0 &&
                            <Row className="p-1">
                                {folders.map((folder) => (
                                <Col 
                                    xs={12} sm={6} md={3}
                                    className="d-flex align-items-center counselor_card folder_card m-1" key={folder._id}
                                >
                                                        <div>
                                    <div className="folder_icon"> <Link to={`/bookings/folder/${folder._id}`}><FolderIcon /></Link></div>
                                    <h4><Link to={`/bookings/folder/${folder._id}`}>{folder.folderName}</Link></h4>
                                    <small>{moment(folder.created).format('MM/DD/YYYY hh:mm')}</small>
                                    </div>
                                        <div className="remove_counselor"><i onClick={()=>{setDeleteFolder(true); setDeleteFolderId(folder._id)}} className="icon-delete" /></div>
                                    </Col>
                                    ))}
                            </Row>
                        }

                        {  bookings.length ?  
                            <div className="camp_list" id="section-to-print">
                                {bookings.map((program, i) => <CampCard viewCamper={permissions.viewCamper} messageCamper={permissions.messageCamper} key={program._id} ProgramIndex={i} CampDetails={program}/>)}   
                            </div>
                            : (!loading && <div className="d-flex align-items-center counselor_card">No Programs Found</div>  )
                        }
                    </Container>
                       

                    </div>
                </div>            
            </div>
            :
            
            <div 
                className='container scrollable-div'
                style={fixedDirListDiv}
            >
            <div className="d-flex align-items-center justify-content-between page_card_header">
                <h4> Select Programs</h4>
                <div className="btn_group">
                    {
                        selectAll ?
                        <button className="btn btn-primary" onClick={()=>{deselectAllPrograms(); setSelectAll(false)}}> Deselect All </button>
                        :
                        <button className="btn btn-primary" onClick={()=>{selectAllPrograms(); setSelectAll(true)}}> Select All </button> 
                    }
                    
                </div>
            </div>

            <div className="row">
                <div className="col-lg-2" />
                <div className="col-lg-8">
                    <div className="partner-form search_wrap" style={{maxWidth: '100%'}}>
                        <i className="icon-search" />
                        <input type="text" className="form-control form-control-lg" placeholder="Search" />
                    </div>
                    {
                    campersWarning ? <p className="waring_text">Please select the Programs First</p> : ''
                    }
                    {
                             folderalreadyExit ? <p className="waring_text">Folder with same name already exit</p> : ''
                    }
                    {
                        bookings.length > 0 ? 
                        bookings.map(program => (
                            <div className="d-flex align-items-center justify-content-between dir_camp_card">
                            <div className="d-flex align-items-center">
                              <Link to={`/director/camp/${program._id}`} className="d-flex align-items-center">
                                <div>
                                    <img src={program.programImage || RewardPointsImg} />
                                </div> 
                                <div>
                                    <h3>{program.programName}</h3>
                                    <p>Timing : {`${program.checkInTime} - ${program.checkOutTime}` }</p>
                                    <p>Date : {moment.utc(program.startDate).format('DD MMMM, YYYY')} <br/> {moment.utc(program.endDate).format('DD MMMM, YYYY')} </p>
                                    <p>Program Code: {program.programCode}</p>
                                </div>
                               </Link>  
                            </div> 
                    
                    
                            <div className="d-flex align-items-center">
                                {/* <div className="cm_slots">
                                    <p>Balance Outstanding</p>
                                    <h4>5</h4>
                                </div>
                                <div className="cm_slots">
                                    <p>Grouped Campers</p>
                                    <h4>5</h4>
                                </div> */}
                                  <div className="cm_slots">
                                    <p>Available Slots</p>
                                    <h4>{program.availableSlots}</h4>
                                  </div>
                            </div> 
                    
                    
                            <div className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="check_box_wrapper cm_dark_check">
                                        <input type="checkbox" onChange={(e)=>{handelPrograms(e)}} value={program._id} checked={programsIds.includes(program._id)}  />
                                        <span className="check_box"><span></span></span>
                                    </div>
                                </div> 
                            </div> 
                    
                    
                        </div>
                        )) 
                        :
                        <div className="d-flex align-items-center counselor_card">
                            No Programs Found
                        </div>    
                    }

                <div className="col-sm-12 text-center mb-5">
                    <Link className="btn btn-light rounded-0 mr-2" onClick={()=>{setSelectAll(false); setFolderAlreadyExit(false);setAddFolder(false); setFoldername(''); setProgramsIds([])}} style={{width: '130px'}}>Cancel</Link>
                    <button  onClick={()=>{setBtnLoader(true); addFolder()}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Submit</button>
                </div>

                </div>
            </div>
            </div>
        }

        </div>
    )
} 



/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let {campCode, _id, UserType } = state.user.userProfile;
    return {
        state,
        campCode,
        UserType,
        _id
    };
}

const mapDispatchToProps = dispatch => ({
    toggleAddFolderModal: params => dispatch(toggleAddFolderModal(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Bookings);