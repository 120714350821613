import React, {useState, useEffect, useRef} from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';


import {
    getCartItems,
    confirmdetail,
    viewProgramDetail,
    confirmdetailNonLoginUser,
    getCartItemsNonLoginUser,
    camperListNonLoginUser
} from '../../services/programService';
import { profileInformation } from '../../services/campersServices';
import { ProgramLoader} from '../reuse/SVG';
import { connect } from "react-redux";
import { getHtml } from '../../helper';
import SignaturePad from 'react-signature-canvas';



const ConfirmDetails = (props) =>{
    let location                                            = useLocation();
    const[campersList, setCampers]                          = useState();
    const[cartItems, setCartItems]                          = useState([]);
    const[email, setEmail]                                  = useState('');
    const[emailerror, setEmailError]                        = useState('');
    const[showerror, setShowErrors]                         = useState(false);
    const[showsuccess, setSuccess]                          = useState(false);
    const[showsuccesserror, setSuccessError]                = useState(false);
    const[primeryNumber, setprimeryNumber]                  = useState('');
    const[primeryNumberError, setprimeryNumberError]        = useState('');
    const[secondryname, setsecondryName]                    = useState('');
    const[firstName, setFirstName]                          = useState('');
    const[lastName, setLastName]                            = useState('');
    const[firstNameErr, setFirstNameErr]                    = useState('');
    const[lastNameErr, setLastNameErr]                      = useState('');
    const[secondrynameError, setSecondrynameError]          = useState('');
    const[secondryPhone, setSecondryPhone]                  = useState('');
    const[secondryPhoneError, setSecondryPhonelError]       = useState('');
    const[singatureby, setsingatureby]                      = useState('');
    const[singaturebyError, setsingaturebyError]            = useState('');
    const[signature, setSignature]                          = useState();
    const[campererror, setCampererror]                      = useState(false);
    const[programdeiclmer, setProgramdisclmer]              = useState();
    const[btnloder, setBtnLoader]                           = useState(false);
    const[loader, setLoader]                                = useState(true);
    const[questionsans, setQuestionAns]                     = useState([]);
    const[signPadPopup, setSignPadPopup]                    = useState(false);
    const[signIsLegal, setSignIsLegal]                      = useState(false);
    const[isSignatureClear, setIsSignatureClear]            = useState(true);
    const[signatureImg, setSignatureImg]                    = useState('');
    const[secondaryEmail, setSecondaryEmail]                = useState('');
    const[resErr, setResErr]                                = useState('');

    const signaturePadRef = useRef();
    const submitRef = useRef();


    useEffect(() => {    
        if(props.loggedIn){
            const loginuseerproductlist = localStorage.getItem('cartitemsloginuser') ? localStorage.getItem('cartitemsloginuser') : "[]";     
            const loginuserarrayproduct =  JSON.parse(loginuseerproductlist);
            let   loginuserproduct = loginuserarrayproduct.filter(item=> item.cart_id === location.state.cart_id);

            if(loginuserproduct.length){
                let camperslist = loginuserproduct[0].camperlist;
                let selectedlist =[];
                let  selectedcamperList = loginuserproduct[0].selectedcamperList;

                
                
                camperslist.map(item => {
                    if(selectedcamperList.includes(item._id)){
                        selectedlist.push(item);
                    }
                })

                setCampers(selectedlist) 
            }

            getCartItems().then((res) => {
                if(res.data.statusCode===0){
                    setCartItems([]);            
                }else{
                setCartItems(res.data.responseData.result.filter(item=> item._id ===location.state.cart_id));
                let cartitem  = res.data.responseData.result.filter(item=> item._id ===location.state.cart_id);
                let program_id  = cartitem[0].programId;
                let questions = cartitem[0].questions;
                let params = `?programId=${program_id}`;
                let program_details = viewProgramDetail(params);
                program_details.then(res => {
                    setProgramdisclmer(res.data.responseData.result.programData.disclaimer);
                })
                if(questions !== undefined && questions.length > 0){
                    let questionsarr = [...questionsans];
                    questions.map(ques => {
                        let qu = { "questionId": ques._id, "question": ques.title, "answer":''}
                        questionsarr.push(qu);
                    })
                    setQuestionAns(questionsarr); 
                }
                }
            })


                profileInformation().then((res)=>{
                    if(res?.data?.statusCode){
                        setLoader(false);
                        let profileInfo = res.data.responseData.result;
                        if(profileInfo?.phone!=="undefined"){setprimeryNumber(profileInfo.phone)}
                        if(profileInfo?.secondaryContact!=="undefined"){setsecondryName(profileInfo.secondaryContact)}
                        if(profileInfo?.name!=="undefined"){setFirstName(profileInfo.name)}
                        if(profileInfo?.email!=="undefined"){setEmail(profileInfo.email)}
                        if(profileInfo?.lastName!=="undefined"){setLastName(profileInfo.lastName)}
                        if(profileInfo?.secondaryPhoneNumber!=="undefined"){setSecondryPhone(profileInfo.secondaryPhoneNumber)}
                        if(profileInfo?.secondaryEmail!=="undefined"){setSecondaryEmail(profileInfo.secondaryEmail)}
                    }else{
                        setLoader(false);
                    }

                })

        }
        else
        {
            setLoader(false);

        getCartItemsNonLoginUser().then((res) => {
            if(res.data.statusCode === 0){
                setCartItems([]);        
            }else{
                if(location.state === undefined){
                    return (<Redirect to={{pathname: "/cart"}}/>)
                }
            setCartItems(res.data.responseData.result.filter(item=> item._id ===location.state.cart_id));
            let cartitem  = res.data.responseData.result.filter(item=> item._id ===location.state.cart_id);
            let program_id  = cartitem[0].programId;
            let params = `?programId=${program_id}`;
            let questions = cartitem[0].questions;
            let program_details = viewProgramDetail(params);
            program_details.then(res => {
                setProgramdisclmer(res.data.responseData.result.programData.disclaimer);
            })

            if(questions !== undefined && questions.length > 0){
                let questionsarr = [...questionsans];
                questions.map(ques=>{
                    let qu = { "questionId": ques._id, "question": ques.title, "answer":''}
                    questionsarr.push(qu);
                })
                setQuestionAns(questionsarr); 
            }


            }
        })


        camperListNonLoginUser().then((res)=>{
            let nonloginuseCamperlist = res.data.responseData.result;
            if(nonloginuseCamperlist.length>0)
            {
            nonloginuseCamperlist.map((item,index)=>{
                if(item.additionalExperience!==undefined)
                {
                    nonloginuseCamperlist[index].additionalExperience = JSON.parse(item.additionalExperience);
                }
            })
            setCampers(nonloginuseCamperlist);
        }
        }) 
    }
        

    },[])



    useEffect(() => {
        if(signIsLegal){
            setSignPadPopup(true);
        }
    }, [signIsLegal])





    useEffect(() => {
        if(signatureImg){
            submitRef.current.click();
        }
    }, [signatureImg])



    const removeSelecteAction = (camperId) =>{
        if(props.loggedIn)
        {
            const loginuseerproductlist = localStorage.getItem('cartitemsloginuser') ? localStorage.getItem('cartitemsloginuser') : "[]";     
            const loginuserarrayproduct =  JSON.parse(loginuseerproductlist);
            let   loginuserproduct = loginuserarrayproduct.filter(item=> item.cart_id === location.state.cart_id);
            let adddtionexp  =loginuserproduct[0].additionalexp; 
            let camperindex = loginuserproduct[0].camperlist.findIndex(camper => camper._id ===camperId);
            let selectedcamperindex = loginuserproduct[0].selectedcamperList.findIndex(item => item ===camperId);
            let additionexpCamper = loginuserproduct[0].camperlist[camperindex].additionalExperience;
            if(additionexpCamper !== undefined)
            {
                additionexpCamper.map(item=>{
                    let additionalexpindex = adddtionexp.findIndex(itemadd => itemadd._id ===item._id);
                    adddtionexp[additionalexpindex].quantity = adddtionexp[additionalexpindex].quantity - item.quantity; 
                })
            }
            loginuserproduct[0].additionalexp = adddtionexp;
            loginuserproduct[0].camperlist.splice(camperindex,1);
            loginuserproduct[0].selectedcamperList.splice(selectedcamperindex,1);
            localStorage.setItem('cartitemsloginuser', JSON.stringify(loginuserproduct));
            let camperslist = loginuserproduct[0].camperlist;
            let selectedlist =[];
            let newCamperlist = [];
            let  selectedcamperList = loginuserproduct[0].selectedcamperList;
            camperslist.map((item)=>{
                if(selectedcamperList.includes(item._id))
                {
                    selectedlist.push(item);
                    newCamperlist.push(item)
                }
            })
            setCampers(selectedlist);
            setCampers(newCamperlist); 
        }   

    }





    const handelSubmit = e => {
     e.preventDefault();
        setBtnLoader(true);

        if(campersList !== undefined && campersList.length === location.state.numbderofCampers){
            let errorcount = 0;
            
            if(email === undefined){
                setEmailError(true);
                errorcount++;
            }
            if(firstName === undefined){
                setFirstNameErr('First name is required');
                errorcount++;
            }else if(firstName.length > 50){
                setFirstNameErr('First name is to large max limit 50');
                errorcount++;
            }
            if(lastName === undefined){
                setLastNameErr('Last name is required');
                errorcount++;
            }

            if (!primeryNumber || primeryNumber.length < 9 || primeryNumber.length > 15) {
                setprimeryNumberError('Primary Phone not valid');
                errorcount++;
            }

            if(secondryname === undefined){
                setSecondrynameError(true);
                errorcount++;
            }

            if(secondryPhone){
                if(secondryPhone.length < 9){
                    setSecondryPhonelError('Secondary Phone not valid');
                    errorcount++;
                }else if(secondryPhone.length > 15){
                    setSecondryPhonelError('Secondary Phone not valid');
                    errorcount++;
                }
            }

            if(singatureby === undefined ||  singatureby === ''){
                setsingaturebyError(true);
                errorcount++;
            }


            if(errorcount === 0){
                let cartId = location.state.cart_id;
                let primaryPhone = primeryNumber;
                let secondaryContact = secondryname;
                let secondaryPhone = secondryPhone;
                let signatureLine = singatureby;
                let signatureText = signature;
                let signatureImage = signatureImg;
                let campers =[];

                let params ={
                    cartId,
                    email,
                    primaryContact: `${firstName} ${lastName || ''}`,
                    primaryPhone,
                    secondaryContact,
                    secondaryPhone,
                    signatureLine,
                    signatureText,
                    signatureImage,
                    secondaryEmail,
                    firstName,
                    lastName
                }
                
                if(campersList.length === 1){
                    let camperId =campersList[0]._id;
                    let groupCode ="";
                    let additional_exp_cart =[]; 
                   
                    if(campersList[0].additionalExperience !== undefined){
                       
                        campersList[0].additionalExperience.map(item => {
                            let addition_exp = {
                                "additionalExperienceId": item._id,
                                "numberOfBooking": item.quantity,
                                "price": item.price,
                                "title" : item.title,
                                "answer" : item.question,
                            }
                            additional_exp_cart.push(addition_exp);
                        })
                    }
                    let campername = `${campersList[0].firstName} ${campersList[0].lastName}`;  
                    let parmsarr = {"camperId": camperId, "camperName": campername} 
                    if(campersList[0].groupCode !== undefined){
                        parmsarr.groupCode =  campersList[0].groupCode;
                    }
                    if(additional_exp_cart.length > 0){
                        parmsarr['additionalExperience'] = additional_exp_cart
                    }

                    if(campersList[0].answers !== undefined){
                        parmsarr['answers'] =  campersList[0].answers;
                    }
                    parmsarr['waiver'] =  campersList[0].waiver;
                    parmsarr['waiverLiability'] =  campersList[0].waiverLiability;
                    parmsarr['concussion'] =  campersList[0].concussion;
                    parmsarr['keyDeposit'] =  campersList[0].keyDeposit;
                    parmsarr['isCommuter'] =  campersList[0].isCommuter;
                    parmsarr['camperType'] =  campersList[0].camperType;
                    parmsarr['camplistid'] =  campersList[0].camplistid;

                    campers.push(parmsarr);  

                }
                else
                {                 
                    campersList.map(item=> {
                        let campername = `${item.firstName} ${item.lastName}`;  
                        let parmsarr={"camperId": item._id, "camperName": campername} 
                        parmsarr['waiver'] =  item.waiver;
                        parmsarr['waiverLiability'] =  item.waiverLiability;
                        parmsarr['concussion'] =  item.concussion;
                        parmsarr['keyDeposit'] =  item.keyDeposit;
                        parmsarr['isCommuter'] =  item.isCommuter;
                        parmsarr['camperType'] =  item.camperType;
                        parmsarr['camplistid'] =  item.camplistid;
                        if(item.groupCode !==undefined)
                        {
                            parmsarr.groupCode = item. groupCode  
                        }
                        if(item.additionalExperience!==undefined)
                        { 
                            let additional_exp_cart =[]; 
                            item.additionalExperience.map(addtional=>{
                            let addition_exp = {
                                "additionalExperienceId": addtional._id,
                                "numberOfBooking": addtional.quantity,
                                "price": addtional.price,
                                "title": addtional.title,
                                "answer" : addtional.question,
                            }
                            additional_exp_cart.push(addition_exp);
                            })
                            parmsarr['additionalExperience'] = additional_exp_cart;
                        }
                        if(item.answers !== undefined)
                        {
                            parmsarr['answers'] =  item.answers;
                        }
                        campers.push(parmsarr);   
                    })    
                }
            params['campers'] = JSON.stringify(campers);

            let confrdetails ='';
            if(props.loggedIn){
                confrdetails = confirmdetail(params);
            }else{
                confrdetails = confirmdetailNonLoginUser(params);
            }

            confrdetails.then(res => {
                if(res.data.statusCode === 1){
                    setBtnLoader(false);
                    props.history.push('/cart');
                }else{
                    setResErr(res?.data?.error?.responseMessage);
                    setBtnLoader(false);
                    setSuccessError(false);
                }  
            })

            }
            else
            {
                setBtnLoader(false);      
            }
        }
        else
        {
            setBtnLoader(false);
            setCampererror(true);
        }
        
    }


    const handleChange = e => {
        let filedname =e.target.name;
        let fieldval = e.target.value;

  

        if(filedname === 'email'){
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fieldval)){
                setShowErrors(true); 
                setEmailError(true);
                setEmail();
            }else{
                setEmail(fieldval);
                setEmailError(false);
                setShowErrors(false); 
            }
        }
        
        if(filedname === 'secondryname'){
            if(fieldval === ''){ 
                setSecondrynameError(true);   
                setsecondryName();
            }else{  
                setSecondrynameError(false);   
                setsecondryName(fieldval);
            }
        }

        if(filedname === 'singatureby'){
            if(e.target.checked){
                setSuccess(true);  
            }
            setsingatureby(fieldval);
            setsingaturebyError(false)
        }

    }





    if(location.state===undefined){
        return (<Redirect to={{pathname: "/cart"}} />)
    }


    const handleSignaturePadChange = e => {
        if(e.isTrusted){
            setIsSignatureClear(false)
        }
    }

    const handleSignaturePadSave = e => {
        if(!isSignatureClear){
            setSignatureImg(signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png'))
            setSignPadPopup(false)
        }
    }


    


  

    return(
        <div className="body_container"> 

            <Modal
                dialogClassName="h_auto"
                show={campererror}
                animation={false}
                centered
                >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error!</h3>
                    <p className="text-center">You need to select {location.state.numbderofCampers} number of campers </p>
                    <button onClick={()=>{setCampererror(false)}}className="btn btn-primary btn-block mt-2">Ok</button>
                </div>
            </Modal.Body>
            </Modal>


            <Modal
                dialogClassName="h_auto"
                show={showsuccesserror}
                animation={false}
                centered
                >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error!</h3>
                    <p className="text-center">There is come error on process your resquest please try after some time</p>
                    <button onClick={()=>{setSuccessError(false)}}className="btn btn-primary btn-block mt-2">Ok</button>
                </div>
            </Modal.Body>
            </Modal>



            <Modal
                dialogClassName="h_auto"
                show={showsuccess}
                size="xl"
                animation={false}
                centered>

                <Modal.Body>
                    <span className="modal_close">
                    <span onClick={() => {setSuccess(false); setsingatureby('')} } ><i className="icon-cross" /></span>
                                                </span>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Disclaimer</h3>
                        <p className="max-h overflow-auto">{getHtml(programdeiclmer)}</p>
                        <span className="btn btn-primary btn-block mt-2" onClick={() => (setSuccess(false), setSignPadPopup(true))}> I agree </span>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                dialogClassName="h_auto"
                show={signPadPopup}
                animation={false}
                centered
                >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Signature</h3>
                       <div className="signature_pad">
                
                            <SignaturePad
                                onEnd={handleSignaturePadChange}
                                ref={signaturePadRef}
                                canvasProps={{width: 361, height: 200, className: 'sigCanvas'}} />
                            </div>
                        
                        <div className="mt-2 d-flex justify-content-center">
                            <button disabled={isSignatureClear ? true : false} className="btn btn-primary btn-block" onClick={handleSignaturePadSave}>Done</button>
                            <button className="btn btn-primary btn-block ml-1" onClick={() => (signaturePadRef.current.clear(), setIsSignatureClear(true))}>Clear</button>
                        </div> 
                            
                </div>
            </Modal.Body>
            </Modal>

           

        <div className="container">
         {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="d-flex align-items-center justify-content-between section_header">
                <h2>Confirm Details</h2>

            </div>
            <div className="row">
                <div className="col-lg-2" />
                <div className="col-lg-8">   

                {props.loggedIn ? '' : <p>Sign in to book with your saved details and manage your bookings easily!</p>}              
                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                {props.loggedIn ?
                                
                                <React.Fragment>

                                <label>Choose Participants</label><br/>    

                               <React.Fragment>
                                <Link className="btn btn-outline-dark rounded-0" to={
                                            {     
                                                pathname: '/selectcampers',
                                                state:{"numbderofCampers":location.state.numbderofCampers, "cart_id": location.state.cart_id}
                                            }
                                            } >Select Participants</Link> <br/>
                                </React.Fragment>            
                                

                                {
                                campersList!==undefined && 
                                campersList.map((item,index) =>(
                                    <span className="cm_tag"><i onClick={()=>{removeSelecteAction(item._id, index)}} className="icon-close" /> {item!==undefined ? item.firstName : ''} {item!==undefined ?item.lastName :''} </span>
                                ))   
                                }
                                </React.Fragment>
                                :        
                                 
                                <React.Fragment>
                                {
                                    campersList ? 
                                    campersList.map((item,index) =>(
                                        <span className="cm_tag" >
                                            {/* <i className="icon-pancil" /> */}
                                             {item!==undefined ? item.firstName : ''} {item!==undefined ?item.lastName :''} </span>
                                    )) 
                                    :(
                                        <React.Fragment>
                                        <label>Choose Participants</label><br/>
                                        <Link className="btn btn-outline-dark rounded-0" to={
                                            {     
                                                pathname: '/addcampers',
                                                state:{"numbderofCampers":location.state.numbderofCampers, "cart_id": location.state.cart_id}
                                            }
                                            } >
                                            Add Participants
                                            </Link>
                                            </React.Fragment> 
                                    )
                                }
                                


                                </React.Fragment>                
                                } 
                            </div>
                        </div>

                        <hr />

                        <h4>Guardian Information:</h4>

                        <div className="row align-items-end">

                            <div className="col-md-6 form-group confirmDetail">
                                <label>First Name</label>
                                <input
                                    value={firstName}
                                    type="text"
                                    onChange={e => (setFirstName(e.target.value), setLastNameErr(''))}
                                    className={`form-control ${firstNameErr ? 'is-invalid' : ''}`} />

                                    {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="col-md-6 form-group confirmDetail">
                                <label>Last Name</label>
                                <input
                                    value={lastName}
                                    type="text"
                                    onChange={e => (setLastName(e.target.value), setLastNameErr(''))}
                                    className={`form-control ${lastNameErr ? 'is-invalid' : ''}`} />

                                    {lastNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{lastNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                         


                            <div className="col-md-6 form-group confirmDetail">
                                <label>Email</label>
                                <input
                                    value={email}
                                    onChange={handleChange}
                                    className={`form-control ${emailerror ? 'is-invalid' : ''}`}
                                    type="email"
                                    name="email" />

                                {emailerror ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailerror}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>

                            <div className="col-md-6 form-group confirmDetail">
                                <label>Primary Phone (This number will be used in the case of emergencies)</label>
                                <div className="cm_phone_field">
                                    <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                    <PhoneInput
                                        country="US"
                                        className={`form-control ${primeryNumberError ? 'is-invalid' : ''}`}
                                        onChange={e => (setprimeryNumber(e), setprimeryNumberError(''))}
                                        value={primeryNumber}
                                        maxLength={16}
                                        />

                                    {primeryNumberError ? <OverlayTrigger placement="left" overlay={<Tooltip>{primeryNumberError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>
                            </div>
                            
                        </div>


                        <div className="row">

                            <div className="col-md-6 form-group confirmDetail">
                                <label>Secondary Contact FULL Name</label>
                                <input
                                    value={secondryname}
                                    type="text"
                                    onChange={handleChange}
                                    name="secondryname"
                                    className={`form-control ${secondrynameError ? 'is-invalid' : ''}`} />

                                    {secondrynameError ? <OverlayTrigger placement="left" overlay={<Tooltip>{secondrynameError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>

                            <div className="col-md-6 form-group confirmDetail">
                                <label>Secondary Phone</label>
                                <div className="cm_phone_field">
                                    <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                    <PhoneInput
                                        country="US"
                                        className={`form-control ${secondryPhoneError ? 'is-invalid' : ''}`} 
                                        onChange={e => (setSecondryPhone(e), setSecondryPhonelError(''))}
                                        value={secondryPhone}
                                        maxLength={16} />

                                {secondryPhoneError ? <OverlayTrigger placement="left" overlay={<Tooltip>{secondryPhoneError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>
                            </div>


                            <div className="col-md-12 form-group confirmDetail">
                                <label>Secondary Email</label>
                                <input
                                    value={secondaryEmail}
                                    type="text"
                                    onChange={e => setSecondaryEmail(e.target.value)}
                                    name="secondaryEmail"
                                    className="form-control" />
                            </div>
                            
                        </div>


                        <div className="row pro_wrapper_2">
                            <div className="col-sm-12 mb-3 cm_gendr">
                                <label>Signature Line </label>
                            </div>
                            <div className="col-md-12 mb-3 cm_gendr">
                                
                                <ul className="d-flex">

                                    <li className="mr-2 check_box_wrapper">
                                        <input checked={singatureby === 1 ? true : false} name="singatureby" onChange={handleChange} type="radio" value="1" />
                                        <span className="check_box"><span /> Mother</span> 
                                    </li>

                                    <li className="mr-2 check_box_wrapper">
                                        <input checked={singatureby === 2 ? true : false} name="singatureby" onChange={handleChange} type="radio" value="2" />
                                        <span className="check_box"><span /> Father</span> 
                                    </li>

                                    <li className="mr-2 check_box_wrapper">
                                        <input checked={singatureby === 3 ? true : false} name="singatureby" onChange={handleChange} type="radio" value="3" />
                                        <span className="check_box"><span /> Other</span> 
                                    </li>

                                    <li className="mr-2 check_box_wrapper">
                                        <input checked={singatureby === 4 ? true : false} name="singatureby" onChange={handleChange} type="radio" value="4" />
                                        <span className="check_box"><span /> 18 yrs or older and signing for myself</span> 
                                    </li> 
                                </ul>

                                {singaturebyError && <span className="form-error">Signature is required</span>}
                                
                            </div>

                            {resErr ? <h5 className='m-auto res_error'>{resErr}</h5> : ''}


                            <div className="col-sm-12 text-center mb-5 mt-4">
                                <Link className="btn btn-light rounded-0 mr-2" style={{width: '130px'}} to="/cart">Cancel</Link>
                                <button ref={submitRef} onClick={handelSubmit} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Save</button></div>
                        </div>


                    </form>
                </div>
            </div>

        </div>


        

    </div> 
    )
}


const mapStateToProps = state => {
    let { loggedIn } = state.user;
    let { waiverURL, waiver2URL } = state.ui;
    let camperList = state.program.camperList;
    return {
        loggedIn,
        camperList,
        waiverURL,
        waiver2URL
    };
}
export default connect(mapStateToProps)(ConfirmDetails);