import setting from './settings';

export default (() => {
  return {
    'AUTH': setting.api.AUTH,
    'USER_SIGNUP': setting.api.url + 'user/signup',
    'USER_FAST_SIGNUP': setting.api.url + 'user/fast-signup',
    'VERIFY_EMAIL_OTP': setting.api.url + 'user/verifyemailotp',
    'VERIFY_FAST_EMAIL_OTP': setting.api.url + 'user/verifyfastemailotp',
    'USER_LOGIN': setting.api.url + 'user/login',
    'USER_FAST_LOGIN': setting.api.url + 'user/fast-login',
    'FORGOT_PASSWORD': setting.api.url + 'user/forgetpassword',
    'RESET_PASSWORD': setting.api.url + 'user/resetpassword',
    'RESEND_OTP': setting.api.url + 'user/resendotp',
    'USER_LOGOUT': setting.api.url + 'user/logout',
    'VERIFY_RESET_TOKEN': setting.api.url + 'user/verifyresettoken',
    'TERMS_FEED_LIST': setting.api.url + 'termsFeeds/list',
    'FAQ_LIST': setting.api.url + 'faq/list',
    'FAQ_FEEDBACK': setting.api.url + 'faq/feedback',
    'PRE_LIST': setting.api.url + 'admin/dropDownList',
    'CAMPER_LIST': setting.api.url + 'user/camperList',
    'ALL_PROGRAM_LIST': setting.api.url + 'program/home',
    'ALL_PROGRAMS': setting.api.url + 'program/filterPrograms',
    'VIEW_PROGRAM_DETAIL': setting.api.url + 'program/programDetails',
    'VIEW_PROGRAM': setting.api.url + 'program/viewProgram',
    'VIEW_PROGRAM_BY_NAME': setting.api.url + 'program/programDetailsByName',
    'PROGRAM_LIST': setting.api.url + 'autoschedule/search',
    'WEEKDATA_LIST': setting.api.url + 'autoschedule/findnew',
    'API_LOCK_URL' :  setting.api.url + 'autoschedule/lock',
    'API_UNLOCK_URL':  setting.api.url + 'autoschedule/unlock', 
    'API_REMOVE_URL' : setting.api.url + 'autoschedule/remove',
    'API_FAVOURITE_URL' :  setting.api.url + 'program/favourite', 
    'PROGRAM_FAVOURITE': setting.api.url + 'program/favourite',
    'FAVOURITE_PROGRAMS': setting.api.url + 'program/favouritelist',
    'FOLLOW_CAMPS': setting.api.url + 'camp/follow',
    'ADD_TO_CART': setting.api.url + 'cart/addtocart',
    'GET_CART_ITEMS': setting.api.url + 'cart/cartlist',
    'UPDATE_CART_ITEMS': setting.api.url + '/cart/updateCartItem',
    'REMOVE_FROM_CART': setting.api.url + 'cart/removefromcart',
    'PARTICIPANT_ADD': setting.api.url + 'participant/add',
    'CONFIRM_DETAILS': setting.api.url + 'cart/confirmdetail',
    'CONFIRM_LIST': setting.api.url + 'cart/confirmedlist',
    'GIFT_CARDS_LIST': setting.api.url + 'giftcard/list',
    //'PURCHASE_GIFT_CARD': setting.api.url + 'giftpurchase/buyGiftCard',
    'PURCHASE_GIFT_CARD': setting.api.url + 'giftpurchase/purchaseGiftCard',
    'GIFT_CARD_DETAIL': setting.api.url + 'giftcard/detail/',
    'ADD_ACTIVITY': setting.api.url + 'calender/addactivity',
    'GET_ACTIVITY': setting.api.url + 'calender/getactivity',
    'EDIT_ACTIVITY': setting.api.url + 'calender/editActivity',
    'BOOKING_CREATE': setting.api.url + 'booking/create',
    'BOOKING_LIST': setting.api.url + 'booking/list',
    'BOOKING_DETAIL': setting.api.url + 'booking/bookingdetail',
    'GET_PROGRAM_GALLERY': setting.api.url + 'program/galleryImages',
    'VALIDATE_COUPON': setting.api.url + 'coupon/validatecoupon',
    'VALIDATE_GIFTCODE': setting.api.url + 'giftpurchase/validateGiftCode',
    'UPLOAD_MEDIA': setting.api.url + 'user/uploadMedia',
    'USER_GUEST': setting.api.url + 'user/guest',    
    'PROFILE_INFORMATION': setting.api.url + 'user/profileinformation',  
    'CHANGE_PASSWORD': setting.api.url + 'user/changepassword',   
    'UPDATE_PROFILE': setting.api.url + 'user/updateprofile',   
    'CAMPER_INFORMATION': setting.api.url + 'participant/camperinformation',   
    'DELETE_CAMPER': setting.api.url + 'participant/delete',   
    'EDIT_CAMPER': setting.api.url + 'participant/edit',   
    'SINGLE_CAMPER_EDIT': setting.api.url + 'participant/singleEdit',   
    'CREATE_POST': setting.api.url + 'post/create',    
    'UPLOAD_TO_S3': setting.api.url + 'post/uploadPostToS3',    
    'GET_POSTS': setting.api.url + 'post/getPost',
    'CREATE_COMMENT': setting.api.url + 'comment/postComment',
    'GET_COMMENTS': setting.api.url + 'comment/getComment',
    'POST_LIKE': setting.api.url + 'like/postLike',
    'DELETE_POST': setting.api.url + 'post/deletePost',
    'GET_FRIENDS_AND_CAMPS': setting.api.url + 'user/getFriendsAndCamps',
    'GET_USER_DETAILS_AND_POSTS': setting.api.url + 'post/getUserPostAndDetails',
    'GET_FEED_DETAIL': setting.api.url + 'post/getParticularPost',
    'SEND_REQUEST': setting.api.url + 'user/sendRequest',
    'AUTOSCHEDULER_CART': setting.api.url + 'cart/autoscheduleraddtocart',
    'PICKUP_OTP': setting.api.url + 'user/pickupotp',
    'VERIFY_PICKUP_OTP': setting.api.url + 'user/verifypickupotp',
    'CAMPSEZ_USER_LIST': setting.api.url + 'user/campsezuserlist',
    'CREATE_CUSTOMER_CARD': setting.api.url + 'user/createCustomerProfile',
    'BOOKING_CAMPER_LIST': setting.api.url + 'user/bookingcamperlist',
    'INVITE_COUNSELOR': setting.api.url + 'user/inviteCounselor',
    'RE_INVITE_COUNSELOR': setting.api.url + 'user/reinvitecounselor',
    'PICK_UP': setting.api.url + 'authorizepickup/pickup',
    'PICK_UP_LIST': setting.api.url + 'authorizepickup/list',
    'DELETE_PICK_UP': setting.api.url + 'authorizepickup/delete',
    'GET_CUSTOMER_PROFILE': setting.api.url + 'user/getCustomerProfile',
    'GET_ASSOCIAT_PROGRAMS': setting.api.url + 'program/getProgramsByCamp',
    'COUPON_LIST': setting.api.url + 'coupon/couponlist',
    'DELETE_CUSTOMER_CARD': setting.api.url + 'user/deleteCustomerCard',
    'DELETE_CARD': setting.api.url + 'user/deleteCard',
    'USER_FRIEND_REQUEST_LIST': setting.api.url + 'user/getUserRequestList',
    'ACCEPT_REJECT_FRIEND_REQUEST': setting.api.url + 'user/respondRequest',
    'GET_ALL_TYPE_USER': setting.api.url + 'user/allTypeUserlist',
    'GET_USER_FRIEND_LIST': setting.api.url + 'user/getUserFriendList',
    'GROUP_CREATE': setting.api.url + 'group/create',
    'CREATE_FOLDER': setting.api.url + 'folder/create',
    'DONATE': setting.api.url + 'donation/donate',
    'DIRECTOR_PROGRAMS': setting.api.url + 'program/directorprograms',
    'DIRECTOR_PROGRAMS_STATUS': setting.api.url + 'program/directorprogramstatus',
    'DIRECTOR_CREATE_ACTIVITY': setting.api.url + 'directoractivity/create',
    'DIRECTOR_ACTIVITY_LIST': setting.api.url + 'directoractivity/list',
    'GET_DIRECTOR_ACTIVITY': setting.api.url + 'directoractivity/getactivity',
    'EDIT_DIRECTOR_ACTIVITY': setting.api.url + 'directoractivity/edit',
    'CREATE_ACTIVITY_FOLDER': setting.api.url + 'folder/createactivityfolder',
    'CAMP_PROGRAMS': setting.api.url + 'program/campprograms',
    'ADD_COUNSELOR': setting.api.url + 'user/addcounselor',
    'COUNSELOR_LIST': setting.api.url + 'user/counselorlist', 
    'DISTRICT_LIST': setting.api.url + 'district/list',
    'COUNSELOR_ALL_LIST': setting.api.url + 'user/allCounselorList', 
    'CREATE_COUNSELOR_FOLDER': setting.api.url + 'folder/createcounselorfolder', 
    'EDIT_COUNSELOR': setting.api.url + 'user/editcounselor', 
    'USER_STATUS_CHANGE': setting.api.url + 'user/statuschange', 
    'DELETE_COUNSELOR_FOLDER': setting.api.url + 'folder/deletecounselor', 
    'COUNSELOR_DETAILS': setting.api.url + 'user/counselordetail', 
    'COUNSELOR_FOLDERS': setting.api.url + 'folder/counselor', 
    'COUNSELOR_FOLDERS_DETAILS': setting.api.url + 'user/foldercounselor', 
    'GET_INBOX': setting.api.url + 'group/inbox', 
    'DIRECTOR_BOOKING_LIST': setting.api.url + 'booking/directorbookinglist', 
    'DIRECTOR_CAMPER_LIST': setting.api.url + 'booking/bookingcamper', 
    'CREATE_BOOKING_FOLDER': setting.api.url + 'folder/createbookingfolder',
    'DELETE_BOOKING_FOLDER': setting.api.url + 'folder/deletebooking',   
    'GET_PROGRAM_BOOKING_DETAIL': setting.api.url + 'booking/programbookingdetail',  
    'UPLOAD_GARDIAN_PDF': setting.api.url + 'booking/uploadGardianPdf',
    'D_PROGRAM_DETAIL': setting.api.url + 'program/viewDirectorProgram',
    'D_PROGRAM_EDIT': setting.api.url + 'program/edit',
    'D_PROGRAM_CREATE': setting.api.url + 'program/directorcreateprogram',
    'UPDATE_PROGRAM_IMG': setting.api.url + 'program/updateImage',
    'EDIT_PROGRAM': setting.api.url + 'program/edit',
    'UPDATE_ADDITIONAL_EXP': setting.api.url + 'program/updateAdditionalExperience',
    'PROGRAM_DELETE': setting.api.url + 'program/delete',
    'BOOKING_CAMPER_DETAIL': setting.api.url + 'booking/bookingcamperdetail',
    'GET_ADDITIONAL_EXP': setting.api.url + 'booking/additionalExpByCamper',
    'DELETE_ACTIVITY_FOLDER': setting.api.url + 'folder/deleteactivity',
    'ACTIVITY_FOLDERS_DETAILS': setting.api.url + 'directoractivity/folderactivity', 
    'BOOKING_FOLDERS_DETAILS': setting.api.url + 'booking/folderbooking',
    'CREATE_BOOKING_CAMPER_FOLDER': setting.api.url + 'folder/createbookingcamperfolder', 
    'Booking_FOLDERS_DETAILS': setting.api.url + 'booking/folderbooking', 
    'GET_INBOX': setting.api.url + 'group/inbox',
    'GET_ALL_USER_LIST': setting.api.url + 'user/getUserFriendList',
    'GROUP_CREATE': setting.api.url + 'group/create',
    'CHAT_HISTORY': setting.api.url + 'chat/chathistory',
    'GET_CAMPERS_FOR_DIRECTOR': setting.api.url + 'user/getCampersForDirector',
    'DELETE_BOOKING_CAMPER_FOLDER': setting.api.url + 'folder/deletebookingcamper',
    'BOOKING_CAMPER_FOLDER_DETAILS': setting.api.url + 'booking/folderbookingcamper',
    'CAMPERS_SIGN_IN': setting.api.url + 'attendee/campersignin',
    'CAMPERS_SIGN_OUT': setting.api.url + 'attendee/campersignout',
    'ADD_REMOVE_BOOKING_FROM_FOLDER': setting.api.url + 'booking/updatefolderbooking',
    'DELETE_COUNSELOR': setting.api.url + 'user/deletecounselor',
    'EDIT_PICKUP': setting.api.url + 'authorizepickup/editpickup',
    'CREATE_BECOME_PARTNER': setting.api.url + 'partner/create',
    'BOOKING_REVIEW': setting.api.url + 'review/addReview',
    'ADD_REMOVE_COUNSELOR_FROM_FOLDER': setting.api.url + 'user/updatefoldercounselor',
    'GET_ADVERTISEMENT': setting.api.url + 'advertisement/list',
    'DELETE_ATIVITY': setting.api.url + 'directoractivity/delete', 
    'UPDATE_ACTIVITY_FOLDERS': setting.api.url + 'directoractivity/updatefolderactivity',
    'ALLOW_DISALLOW_PERSONNEL_FEEDS':  setting.api.url + 'user/allowAllCounselorFeeds',
    'ADD_REMOVE_CAMPERS_FROM_FOLDER': setting.api.url + 'booking/updatefolderbookingcamper',
    'UPDATE_GALLERY_IMAGE': setting.api.url + 'program/updateGalleryImages',
    'ASK_QUESTION': setting.api.url + 'askquestion/askquestion',
    'LIST_YOUR_CAMP_REQUEST': setting.api.url + 'listyourcamp/create',
    'CAREER_LIST': setting.api.url + 'career/list',
    'ADDMORE_ACTIVITY_TO_FOLDER': setting.api.url + 'directoractivity/getactivityfolderlist',
    'GET_PERSONNEL_FOLDER_LIST': setting.api.url + 'user/getpersonnelfolderlist',
    'GET_STATIC_PAGES': setting.api.url + 'staticpages/getcontent',
    'CONTACT_US': setting.api.url + 'contactus/contact',
    'GET_CAMPER_LIST_CAMPER_FOLDER': setting.api.url + 'booking/getcamperfolderlist',
    'GET_BOOKING_LIST_CAMPER_FOLDER': setting.api.url + 'booking/getbookingfolderlist',
    'INVOIC_LIST': setting.api.url + 'campinvoice/invoicelist',
    'CREATE_CABIN': setting.api.url + 'cabin/create',
    'GET_CABIN': setting.api.url + 'cabin/getCabins',
    'REFUND_POLIICY': setting.api.url + 'camp/refundpolicy',
    'NEWS_LIST': setting.api.url + 'news/list',
    'NEWS_DETAIL': setting.api.url + 'news/detail',
    //'BILLING_DETAILS': setting.api.url + 'booking/billingdetail',
    'BILLING_DETAILS': setting.api.url + 'user/billingdetail',
    'GET_REVIEW': setting.api.url + 'review/getReviewByCamp',
    'DELETE_COMMENT': setting.api.url + 'comment/delete',
    'CLONE_PROGRAM': setting.api.url + 'program/cloneProgram',
    'COPY_PROGRAM_ITEMS': setting.api.url + 'program/copyProgramItems',
    'GET_ALL_PRGRAM_WITH_ID': setting.api.url + 'program/getAllProgramsByCampCode',
    'DELETE_GALLERY_IMG': setting.api.url + 'program/deleteGalleryImages',
    'UNREAD_COUNT': setting.api.url + 'group/unreadCount',
    'USER_DASHBOARD': setting.api.url + 'user/dashboard',
    'UPLOAD_EXCEL': setting.api.url + 'program/uploadExcel',
    'GET_REFUND_POLICY': setting.api.url + 'camp/getrefundpolicy',
    'GET_PERSONNEL_ACTIVITY_LIST': setting.api.url + 'directoractivity/personnelactivitylist',
    'GET_CAMP_DETAIL': setting.api.url + 'camp/campdetail',
    'EDIT_CAMP_DETAIL': setting.api.url + 'camp/directorcampedit',
    'US_LACROSSE': setting.api.url + 'booking/getCustomers',
    'UPDATE_SERIAL_NUMBER': setting.api.url + 'booking/update-camper-serial-number',
    'MESSAGE_PROGRAM': setting.api.url + 'messageprogram/create',
    'MESSAGE_PROGRAM_SINGLE': setting.api.url + 'messageprogram/createSingle',
    'IS_MESSAGE_PROGRAM_EXIST': setting.api.url + 'messageprogram/isMessageProgramExist',
    'USER_PDF_CREATE': setting.api.url + 'userpdf/create',
    'ADD_SCHEDULE': setting.api.url + 'schedule/scheduleDate',
    'GET_SCHEDULE': setting.api.url + 'schedule/scheduledDateList',
    'SINGLE_DELETE_SCHEDULE': setting.api.url + 'schedule/delete',
    'DELETE_SCHEDULE': setting.api.url + 'schedule/deleteMany',
    'SCHEDULE_COMMIT': setting.api.url + 'schedule/scheduleCommit',
    'SCHEDULE_EDIT': setting.api.url + 'schedule/edit',
    'REMOVE_STAFF': setting.api.url + 'schedule/removeStaff',
    'DISTRICT_LIST': setting.api.url + 'district/list',
    'CANCEL_BOOKING': setting.api.url + 'booking/cancelBookingByJustify',
    'GET_COUPONS_LIST': setting.api.url + 'coupon/list',
    'CREATE_COUPON': setting.api.url + 'coupon/create',
    'COUPON_STATUS_CHANGE': setting.api.url + 'coupon/statusChange',
    'COUPON_DELETE': setting.api.url + 'coupon/delete',
    'USER_LIST': setting.api.url + 'user/userlist',
    'USER_DETAIL': setting.api.url + 'user/getUserDetailById?userId=',
    'CREATE_BOOKING_BY_ADMINT': setting.api.url + 'booking/createByAdmin',
    'GET_PROGRAMS_LIST': setting.api.url + 'program/listForProgram', 
    'SIGNUP_FOR_STAFF': setting.api.url + 'user/addStaff', 
    'GET_TAXS': setting.api.url + 'tax/getTax',
    'ON_BOARDING_JUSTIFY': setting.api.url + 'user/onboardingJustify',
    'VALIDATE_SLOTS': setting.api.url + 'booking/validateSlot',
    'VALIDATE_BOOKING': setting.api.url + 'cart/validateBooking',
    'CREATE_PRODUCT': setting.api.url + 'store/create',
    'LIST_PRODUCT': setting.api.url + 'store/list',
    'EDIT_PRODUCT': setting.api.url + 'store/edit',
    'PRODUCT_DETAIL': setting.api.url + 'store/detail',
    'ADD_PRODUCT_IN_TO_CART': setting.api.url + 'cart/addItemToCart',
    'PRODUCT_PURCHASE': setting.api.url + 'item-purchase/create',
    'INSURANCE_PURCHASE': setting.api.url + 'booking/insurance-purchase/create',
    'GET_PRODUCT_CART_COUNT': setting.api.url + 'cart/cartCount',
    'GET_ORDER_PRODUCTS': setting.api.url + 'item-purchase/list',
    'CANCEL_PROGRAM_BOOKING_BY_CAMPER': setting.api.url + 'booking/cancelBookingByCampers',
    'UPDATE_BOOKING_KEYS': setting.api.url + 'booking/updatekeyDepositConcussion',
    'REFUND_BOOKING_BY_CAMPER': setting.api.url + 'booking/refundBookingByCampers',
    'PRODUCT_DELETE': setting.api.url + 'store/delete',
    'ADD_PRODUCT_WITH_PROGRAM': setting.api.url + 'cart/addItemToProgramCart',
    'CREATE_TEMPLATE': setting.api.url + 'template/create',
    'GET_TEMPLATES': setting.api.url + 'template/getTemplates',
    'CREATE_SCHEDULE': setting.api.url + 'messageprogram/createSchedule',
    'GET_SCHEDULE_LIST': setting.api.url + 'messageprogram/getSchedules',
    'GET_SCHEDULE_CONVERSATIONS_LIST': setting.api.url + 'messageprogram/getScheduledConversations',
    'DELETE_TEMPLATE': setting.api.url + 'template/delete',
    'EDIT_TEMPLATE': setting.api.url + 'template/edit',
    'CREATE_NOTE': setting.api.url + 'notes/create',
    'DELETE_NOTE': setting.api.url + 'notes/delete',
    'EDIT_NOTE': setting.api.url + 'notes/edit',
    'GET_NOTES': setting.api.url + 'notes/getNotes',
    'GET_USER_ACTIVITY': setting.api.url + 'activity/getActivities',
    'EDIT_SCHEDULE': setting.api.url + 'messageprogram/edit',
    'DELETE_SCHEDULE': setting.api.url + 'messageprogram/delete',
    'PRODUCT_BOOKING_LIST': setting.api.url + 'item-purchase/adminlist',
    'CALC_ALL_PRICE': setting.api.url + 'cart/calculatePrice',
    'PENDING_AMOUNT_LIST': setting.api.url + 'booking/pending-amount-list',
    'PAID_AMOUNT_RECEIPTS': setting.api.url + 'booking/paid-amount-receipts',
    'COMBINED_PENDING_AMOUNT_LIST': setting.api.url + 'booking/combined-pending-amount-list',
    'DEDUCT_PAY_LATER_AMOUNT': setting.api.url + 'booking/deductPayLaterAmount',
    'UPDATE_REMAINING_AMOUNT': setting.api.url + 'booking/editBookingAmount',
    'UPDATE_BOOKING_DATE': setting.api.url + 'booking/editBookingDate',
    'RESEND_BOOKING_EMAIL': setting.api.url + 'booking/sendConfirmationEmail',
    'ITEM_CATEGORY_ADD': setting.api.url + 'itemcategory/add',
    'GET_ITEM_CATEGORY': setting.api.url + 'itemcategory/list',
    'GET_USER_BOOKED_CAMPS': setting.api.url + '/itemcategory/campList',
    'DELETE_PRODUCT_CAT': setting.api.url + '/itemcategory/delete',
    'ITEM_PURCHASE_UPDATE_PRODUCT_BOOKING': setting.api.url + 'item-purchase/updateProductBooking',
    'USER_BOOKING_LIST_FOR_PRODUCT': setting.api.url + '/booking/userwiseBookinglist',
    'ADMIN_BOOKING_LIST_FOR_PRODUCTS': setting.api.url + '/booking/userwiseBookinglistAdmin', 
    'EDIT_ANS_BY_DIR': setting.api.url + 'booking/editBookingQuestion',
    'EDIT_All_QUES_BY_DIR': setting.api.url + '/booking/editBookingAllQuestions',
    'JUSTIFI_AUTH_TOKEN': setting.api.url + 'user/justifiAuthToken',
    'LIST_PRODUCT_BY_BOOKING': setting.api.url + 'item-purchase/listByBooking',
    'CANCEL_PRODUCT_BY_BOOKING': setting.api.url + 'item-purchase/cancelProductPurchase',
    'REFUND_PRODUCT_BY_BOOKING': setting.api.url + 'item-purchase/refundProductPurchase',
    'MEDICAL_DOCUMENT_STATUS': setting.api.url + 'participant/medicalDocument/statusChange',
    'SEND_EMAIL_SMS': setting.api.url + 'chat/sendEmailSMS',
    'SEND_EMAIL_TEST': setting.api.url + '/template/sendTest/email',
    'SEND_PHONE_TEXT_TEST': setting.api.url + '/template/sendTest/phoneText',
    'SEND_APP_MESSAGE_TEST': setting.api.url + '/template/sendTest/appMessage',
    'UPDATE_STARRED_CHAT': setting.api.url + '/group/setStarredMsg',
    'CREATE_NEW_SCHEDULE': setting.api.url + 'messageprogram/createNewSchedule',
    'GET_PERSONNEL_BY_CAMP_CODE': setting.api.url + 'user/personnelByCampCode?campCode=',
    // 'MARK_PRIMARY_CARD': setting.api.url +'user/markPrimaryCard', //TODO: Revert this change in case it don't work.
    'MARK_PRIMARY_CARD': setting.api.url +'user/updatePrimaryCard',
    'UPDATE_PRIMARY_CARD': setting.api.url +'user/updatePrimaryCard',
    'REMOVE_ITEM_FROM_PROGRAM_CART': setting.api.url +'cart/removeItemFromProgramCart',
    'CANCEL_REFUND_ACTION': setting.api.url +'/item-purchase/action',
    'CAMPERS_BOOKING_ACTION': setting.api.url + '/booking/campersBookingAction',
    'CAMPER_ADDITIONAL_CHARGES': setting.api.url + '/booking/additional-charge/create',
    'PARTICIPANTS_CAMPER_BOOKING':setting.api.url + 'participant/camper-booking',
    'PAYMENT_METHOD_BY_TRANSACTON':setting.api.url + '/booking/paymentMethodByTransaction',
    // 
  }
})()