import React,{useState, useEffect, useRef} from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { DatePicker, Select, notification} from 'antd';
import CustomSelect from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';


import {campprograms} from '../../services/directorServices'
import {ageArr, shortsAndTshirtSizes, servingService, getHtml, getBr, medical, allergy } from '../../helper';
import {addcounselor, editcounselor, signupStaffService } from '../../services/directorServices';
import { uploadMedia } from '../../services/campersServices';
import { PDFIcon } from '../reuse/SVG';
import { getCampDetailService } from '../../services/userServices';
import { upadteUserProfileAction } from '../../store/user';
import CustomMultiSelectDropdown from '../reuse/CustomMultiSelectDropdown';
import { medicalList } from '../../helper';
import { allergyList } from '../../helper';


const { Option } = Select;
const dateFormatNew = 'MM/DD/YYYY';


const  disabledDate = (current) => {
    return current && (current > moment().startOf('day') || current < moment(`${new Date().getFullYear() - 100}-10-23`).startOf('day'));
}

const AddCounselor = (props) => {
    const[title, setTitle]                          = useState();
    const[phone, setPhone]                          = useState();
    const[email, setEmail]                          = useState();
    const[emailError, setEmailError]                = useState('');
    const[dob, setDob]                              = useState();
    const[dobError, setDobError]                    = useState(false);
    const[counselorProgram, setCounselorProgram]    = useState();
    const[ageGroup, setAgeGroup]                    = useState();
    const[counselorfolderId, setCounselorfolderId]  = useState([]);
    const[userDescription, setUserDescription]      = useState();
    const[descriptionError, setdescriptionError]    = useState(false);
    const[ProgramsList, setProgramsList]            = useState([]);
    const[profileImage, setProfileImage]            = useState();
    const[profileimageurl, setProfileImageurl]      = useState();
    const[btnloder, setBtnLoader]                   = useState(false);
    const[folders, setFolders]                      = useState([]);
    const[signPadPopup, setSignPadPopup]            = useState(false);
    const[signIsLegal, setSignIsLegal]              = useState(2);
    const[isSignatureClear, setIsSignatureClear]    = useState(true);
    const[campDetail, setCampDetail]                = useState();

    const[signatureImg, setSignatureImg]                        = useState('');
    const[serving, setServing]                                  = useState('');
    const[firstName, setFirstName]                              = useState('');
    const[lastName, setLastName]                                = useState('');
    const[streetAddress, setStreetAddress]                      = useState();
    const[appartment, setAppartment]                            = useState('');
    const[city, setCity]                                        = useState('');
    const[zipCode, setZipCode]                                  = useState('');
    const[state, setState]                                      = useState('');
    const[tshirtSize, setTshirtSize]                            = useState();
    const[shortsSize, setShortSize]                             = useState();
    const[medicalvalues, setMedical]                            = useState([]);
    const[medicalvaluesErr, setMedicalErr]                      = useState('');
    const[allergyvalues, setAllergy]                            = useState([]);
    const[allergyvaluesErr, setAllergyErr]                      = useState('');
    const[allergyDeadly, setAllergyDeadly]                      = useState();
    const[medicalInsuranceCompany, setMedicalInsuranceCompany]  = useState('');
    const[policyNumber, setPolicyNumber]                        = useState('');
    const[group, setGroup]                                      = useState('');
    const[primaryPhysician, setPrimaryPhysician]                = useState('');
    const[physicianAddress, setPhysicianAddress]                = useState('');
    const[physicianPhone, setPhysicianPhone]                    = useState('');
    const[medicationsTaken, setMedicationsTaken]                = useState('');
    const[allowDocumentCamper, setAllowDocumentCamper]          = useState(1);
    const[medicalDocument, setMedicalForm]                      = useState();
    const[readTrainingGuide, setReadTrainingGuide]              = useState(2);
    const[campCodeOther, setCampCodeOther]                      = useState('');
    const[trainingGuideModal, setTrainingGuideModal]            = useState(false);
    const[agreement, setAgreement]                              = useState('');
    const[trainingGuidePdf, setTrainingGuidePdf]                = useState('');
    const [participantBringingOwnMedication, setParticipantBringingOwnMedication]           = useState(''); 
    const signaturePadRef = useRef();
    let history = useHistory();


    useEffect(()=>{
        let params = `/?campCode=${props?.userProfile?.campCode}`;
        campprograms(params).then((res) => {
            if(res?.data?.statusCode){
                setProgramsList(res.data.responseData.result);
            }
        })
        // counselorfoders().then((res)=>{
        //     if(res?.data?.statusCode){
        //         setFolders(res.data.responseData.result)
        //     }
        // })
    },[])



    const getCampDetail = () => {
        getCampDetailService(`?campCode=${props?.userProfile?.campCode || props?.match?.params.id}`).then(res => {
            if(res?.data?.statusCode){
                let tempAgreement = res.data.responseData.result?.agreement;
                let tempTrainingGuidePdf = res.data.responseData.result?.trainingGuidePdf;
                if(tempAgreement){
                    setAgreement(getBr(res.data.responseData.result.agreement))
                }
                if(tempTrainingGuidePdf){
                    setTrainingGuidePdf(res.data.responseData.result.trainingGuidePdf)
                }
                
                
            }
        })
    }


    useEffect(() => {
        getCampDetail();
    }, [props])


    useEffect(() => {
        if(props?.match?.params?.id){
            setCampCodeOther(props.match.params.id);
        }
    }, [props])




    useEffect(() => {
        if(props.Details !== undefined){
            let cd = props.Details;
            setPhone(cd.phone);
            setEmail(cd.email);
            setDob(cd.dob);
            setUserDescription(cd.userDescription);

            if(cd.counselorProgram){
                setCounselorProgram(cd.counselorProgram);
            }
            if(cd.counselorfolderId){
                setCounselorfolderId(cd.counselorfolderId);
            }
            if(cd.ageGroup){
                setAgeGroup(cd.ageGroup);
            }
            if(cd.userTitle){
                setTitle(cd.userTitle);
            }
            if(cd.counselorDetails){
                setServing(cd.counselorDetails.serving);
                setFirstName(cd.counselorDetails.firstName);
                setLastName(cd.counselorDetails.lastName);
                setStreetAddress(cd.counselorDetails.streetAddress);
                setAppartment(cd.counselorDetails.appartment);
                setCity(cd.counselorDetails.city);
                setZipCode(cd.counselorDetails.zipCode);
                setState(cd.counselorDetails.state);
                setTshirtSize(cd.counselorDetails.tshirtSize);
                setShortSize(cd.counselorDetails.shortsSize);
                setMedical(cd.counselorDetails.medicalvalues);
                setAllergy(cd.counselorDetails.allergyvalues);
                setAllergyDeadly(cd.counselorDetails.allergyDeadly);
                setMedicalInsuranceCompany(cd.counselorDetails.medicalInsuranceCompany);
                setPolicyNumber(cd.counselorDetails.policyNumber);
                setGroup(cd.counselorDetails.group);
                setPrimaryPhysician(cd.counselorDetails.primaryPhysician);
                setPhysicianAddress(cd.counselorDetails.physicianAddress);
                setPhysicianPhone(cd.counselorDetails.physicianPhone);
                setMedicationsTaken(cd.counselorDetails.medicationsTaken);
                setParticipantBringingOwnMedication(cd.counselorDetails.participantBringingOwnMedication);
                setAllowDocumentCamper(cd.counselorDetails.allowDocumentCamper);
                setMedicalForm(cd.counselorDetails.medicalDocument);
                setUserDescription(cd.counselorDetails.userDescription);
                setSignIsLegal(cd.counselorDetails.signIsLegal);
                setSignatureImg(cd.counselorDetails.signatureImg);
                setReadTrainingGuide(cd.counselorDetails.readTrainingGuide);
                setCampDetail(cd.campDetail);
                setProfileImageurl(cd.profileImage);
            }  
        }
    },[props.Details])

    let prevalus =[]
    folders.map(item => {
        if(counselorfolderId.includes(item._id)){
            let obj = {
                "value": item._id,
                "label":item.folderName
            }  
            prevalus.push(obj);
        }
    })


    const upload_image = (e) =>{
        if (e.target.files[0]) {
            setProfileImage(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setProfileImageurl(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }


    const handleEmail =(e) =>{
        let filedname =e.target.name;
        let fieldval = e.target.value;
        if(filedname==='email')
        {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fieldval)) {
            setEmailError('Please enter valid email');
            setEmail();
        }
        else
        {
            setEmail(fieldval);
            setEmailError('');
        }
        }
    }


    const handleValidate = () => {
        let validate = true;

        if(email === '' || email === undefined){
            setEmailError('Email is required');
            validate = false
        }

        return validate;
    }

    const handelSubmit =(e) => {
        e.preventDefault();
        setBtnLoader(true);

        if(handleValidate()){

            let form_data = new FormData();

            form_data.append('name', `${firstName} ${lastName}`);
            form_data.append('email',email);
            if(campCodeOther){
                form_data.append('campCode', campCodeOther);
                form_data.append('userId', props.match.params.id2);
            }else{
                form_data.append('campCode', props.userProfile.campCode);
            }
            form_data.append('phone',phone);
            form_data.append('address', streetAddress);
            form_data.append('dob',dob ? dob : '');
        
            if(ageGroup !== undefined && ageGroup.trim() !==''){
                form_data.append('ageGroup',ageGroup);
            }  

            if(userDescription !== undefined && userDescription.trim() !==''){
                form_data.append('userDescription',userDescription);
            }  
            
            if(profileImage !== undefined){
                form_data.append('profileImage',profileImage);
            }

            if(counselorfolderId.length > 0){
                form_data.append('counselorfolderId',JSON.stringify(counselorfolderId));
            }else{
                form_data.append('counselorfolderId',JSON.stringify(counselorfolderId));
            }

            if(counselorProgram !== undefined && counselorProgram.trim() !==''){
                form_data.append('counselorProgram',counselorProgram);
            }

            if(title !== undefined){
                form_data.append('userTitle',title);
            }

            let tempMedicalvalues = [];
            if(medicalvalues !== null){
                let isMedicalNone = medicalvalues.filter(item => item.value === "None");
                if(isMedicalNone.length){
                    tempMedicalvalues = [{value: "None", label: "None"}];
                }else{
                    tempMedicalvalues = medicalvalues
                }
            }


            let tempAllergyvalues = [];
            if(allergyvalues !== null){
                let isAllergyNone = allergyvalues.filter(item => item.value === "None");
                if(isAllergyNone.length){
                    tempAllergyvalues = [{value: "None", label: "None"}];
                }else{
                    tempAllergyvalues = allergyvalues
                }
            }
            

            let counselorDetails = {
                serving,
                firstName,
                lastName,
                streetAddress,
                appartment,
                city,
                zipCode,
                state,
                tshirtSize,
                shortsSize,
                medicalvalues: tempMedicalvalues,
                allergyvalues: tempAllergyvalues,
                allergyDeadly,
                medicalInsuranceCompany,
                policyNumber,
                group,
                primaryPhysician,
                physicianAddress,
                physicianPhone,
                medicationsTaken,
                participantBringingOwnMedication,
                allowDocumentCamper,
                medicalDocument,
                userDescription,
                signIsLegal,
                readTrainingGuide,
                signatureImg
            }




            form_data.append('counselorDetails', JSON.stringify(counselorDetails))
                
            if(props.Details!==undefined){
                form_data.append('counselorId',props.Details._id);


                editcounselor(form_data).then( res => {
                    if(res?.data?.statusCode){
                        let _userProfile = {...props.userProfile};
                        _userProfile.name = `${firstName || ''} ${lastName || ''}`;
                        props.upadteUserProfileAction(_userProfile);
                    }
                    setBtnLoader(false);
                    notification.open({
                        description:
                        'Staff Updated Successfully',
                    });
                })
            }else{
                let _service = campCodeOther ? signupStaffService : addcounselor;

                _service(form_data).then(res => {
                    if(res?.data?.statusCode) {
                        setBtnLoader(false);

                        if(campCodeOther){
                            history.push("/");
                            notification.open({
                                description:
                                'Staff application sent to the director successfully',
                            });
                        }else{
                            history.push("/personnel");
                            notification.open({
                                description:
                                'Staff Added Successfully',
                            });
                        }
                        
                    }else{
                        setBtnLoader(false);
                        notification.open({
                            description: res.data.error.responseMessage
                        }); 
                    }  
                })
            }
        }else{
            setBtnLoader(false);
        }
    }

    const handelFolderAction = e => {
        let tempVal = e ? e.map(i => i.value) : '';
        if(tempVal.length === 0){
            setCounselorfolderId([])
        }else{
        setCounselorfolderId(tempVal)
        }
    }



    const uploadHippaCompliance = (e, type) => {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        uploadMedia(data).then(res => {
            if(res.data.responseData.result.fileUrl !== undefined){
                if(type === 'medical'){
                    setMedicalForm(res.data.responseData.result.fileUrl)
                }
            }
        })
    } 



    // useEffect(() => {
    //     if(signIsLegal){
    //         setSignPadPopup(true);
    //     }
    // }, [signIsLegal])


    const handleLegalSignChange = e => {
        setSignIsLegal(e.target.value === 1 ? 2 : 1);
        if(e.target.value === 2){
            setSignPadPopup(true)
        }
    }


    const handleSignaturePadChange = e => {
        if(e.isTrusted){
            setIsSignatureClear(false)
        }
    }

    const handleSignaturePadSave = e => {
        if(!isSignatureClear){
            setSignatureImg(signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png'))
            setSignPadPopup(false)
        }
   
    }


    const handleReadChange = e => {
        if(readTrainingGuide === 1){
            setReadTrainingGuide(2);
        }else{
            setTrainingGuideModal(true)
        }
        // setReadTrainingGuide(e.target.value === 1 ? 2 : 1);
        // if(e.target.value === 2){
        //     setTrainingGuideModal(true)
        // }
    }


    const handleAllergyChange = e => {
        if(e !== null){
            let temp = e.filter(item => item.value === 'None');
            if(temp.length === 1){
                setAllergy(temp);
                setAllergyDeadly(2)
            }else{
                setAllergy(e); 
            }
        }else{
            setAllergy([]);
        }
    }


    // useEffect(() => {
    //     if(allergyvalues !== null){
    //         let temp = allergyvalues.filter(item => item.value === 'None');
    //         if(temp.length){
    //             setAllergy(temp);
    //             setAllergyDeadly(2)
    //         }
    //     }
        
    // }, [allergyvalues])





    return(
        <div className="body_container pb-4 director_wrapper pro_wrapper">
        <div className="container">

            <div className="row">
                {
                   props.Details ? '' : <div className="col-lg-2" /> 
                }
                
               <div className= {`col-lg-${props.Details ? '12': '8'}`} > 
                {
                    props.EditfromPersonellProfile ? '' :  
                    <div className="page_card_header cm_border_0">
                      { props.Details ? <h4 className="color_red">Edit Personnel Information</h4> : <h4 className="color_red">New Personnel</h4>}  
                    </div>
                }    

                <form onSubmit={handelSubmit} className="donate_wrap dark_labal" encType="multipart/form-data">

                    <div className="row">

                        <div className="col-sm-12 text-center mb-5">
                            <div className="profile_upload">
                                <img src={profileimageurl ? profileimageurl : require('../../assets/img/camps-user.png')} />
                                <input onChange={upload_image} type="file" accept="image/x-png,image/gif,image/jpeg"/>
                                <i className="icon-camera-plus" />
                            </div>
                        </div>

                    </div>    




                       
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label>Title [Optional]</label>
                            <input
                                type="text"
                                name="activityName"
                                placeholder="Enter Title"
                                value={title}
                                onChange={(e)=>{setTitle(e.target.value)}}
                                className="form-control" />
                        </div>

                        <div className="col-md-6 form-group presnol-age-group">
                            <label>How are you serving?</label>
                            <Select
                                className="form-control"
                                placeholder="Select"
                                value={serving ? serving : 'Select Serving'}
                                onChange={e => setServing(e)} 
                            >   
                                <Option key="ageselect" value="">Select Serving</Option> 
                                {servingService.map(item => <Option key={item} value={item}>{item}</Option>)}
                            </Select>
                        </div>

                        <div className="col-md-6 form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                placeholder="Enter First Name"
                                className="form-control"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                />
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Last Name</label>
                            <input
                                type="text"
                                placeholder="Enter Last Name"
                                className="form-control"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                />
                        </div>

                      

                    </div>





                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label>Phone Number </label>
                            <div className="cm_phone_field">
                                <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                    <PhoneInput
                                    country="US"
                                    className="form-control"
                                    maxLength={16}
                                    onChange={e => setPhone(e)}
                                    value={phone} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Email</label>
                            <input
                                type="text"
                                name="email"
                                placeholder="Enter Email Address"
                                className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                value={email}
                                disabled={props.Details ? 'true': ''}
                                onChange={e => (handleEmail(e), setEmailError(''))}
                                />
                            {emailError ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>

                    </div>





                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label>Street Address</label>
                            <input
                                type="text"
                                onChange={e => setStreetAddress(e.target.value)}
                                className="form-control"
                                value={streetAddress}
                                placeholder="Street Address" />
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Apt./Suite/Unit [optional]</label>
                            <input
                                type="text"
                                onChange={e => setAppartment(e.target.value)}
                                className="form-control"
                                value={appartment}
                                placeholder="Apt./Suite/Unit [optional]" />
                        </div>


                        <div className="col-md-6 form-group">
                            <label>City</label>
                            <input
                                type="text"
                                onChange={e => setCity(e.target.value)}
                                className="form-control"
                                value={city}
                                placeholder="City" />
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Zip Code</label>
                            <input
                                type="text"
                                onChange={e => setZipCode(e.target.value)}
                                className="form-control"
                                value={zipCode}
                                placeholder="Zip Code" />
                        </div>


                        <div className="col-md-6 form-group">
                            <label>State/Province</label>
                            <input
                                type="text"
                                onChange={e => setState(e.target.value)}
                                className="form-control"
                                value={state}
                                placeholder="State/Province" />
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Date of Birth</label>

                            <DatePicker
                                disabledDate={disabledDate}
                                value={ dob ? moment(parseInt(dob)): ''} 
                                className={`form-control ${dobError ? 'is-invalid' : ''}`}
                                placeholder="MM/DD/YYYY"
                                format={dateFormatNew}
                                onChange={e => { setDob(new Date(e._d).getTime()); setDobError(false)}} />
                        </div>

                    </div>





                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label>Program Name [Optional]</label>
                            <select
                                className="form-control"
                                placeholder="Select Program Name"
                                value={counselorProgram}
                                onChange={e => setCounselorProgram(e.target.value)}>  
                                {ProgramsList.map((program)=> <option key={program._id} value={program._id}>{program.programName}</option>)}
                            </select>
                        </div>

                        <div className="col-md-6 form-group presnol-age-group">
                            <label>Age Group [Optional] <i className="input_info">(i)</i></label>
                            <Select
                                className="form-control"
                                placeholder="Select"
                                value={ageGroup ? ageGroup : 'Select Age'}
                                onChange={(e) => { setAgeGroup(e) }} 
                            >   
                                <Option key="ageselect" value="">Select Age Group</Option> 
                                {ageArr.map(item => <Option key={item} value={item}>{item}</Option>)}
                            </Select>
                            <OverlayTrigger placement="left" overlay={<Tooltip>This is the age group the personnel will be instructing, simply choose the best/most representative one</Tooltip>}><i className="input_info" /></OverlayTrigger>
                        </div>

                    </div>


                    <div className="row">
                {
                    folders.length > 0  ?
                        <div className="col-md-12 form-group">
                            <label>Assign to Folder [Optional]</label>
                            <CustomSelect
                                isMulti
                                placeholder="Assign to Folder"
                                onChange={e => handelFolderAction(e)}
                                value={prevalus}
                                options={folders.map(item => ({value: item._id, label: item.folderName}))} />
                        </div> :''
                }  
                    </div>





                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label>T-shirt Size</label>
                            <select
                                value={tshirtSize}
                                onChange={e => setTshirtSize(e.target.value)}
                                defaultValue={tshirtSize}
                                className="form-control">
                                <option  value="">Select Size</option>
                                {shortsAndTshirtSizes.map(value => <option key={value} value={value}>{value}</option>)}
                            </select> 
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Shorts Size</label>
                            <select
                                onChange={e => setShortSize(e.target.value)}
                                value={shortsSize}
                                className="form-control">
                                <option  value="">Select Size</option>
                                {shortsAndTshirtSizes.map(value => <option key={value} value={value}>{value}</option>)}
                            </select> 
                        </div>


                        <div className="col-md-12 form-group">
                            <label>Medical</label>
                            <CustomMultiSelectDropdown
                                placeHolder="Select Medical"
                                value={medicalvalues}
                                err={medicalvaluesErr}
                                calback={val => (setMedical(val), setMedicalErr(''))}
                                list={medicalList} />
                        </div>


                        <div className="col-md-12 form-group">
                            <label>Allergy</label>
                            <CustomMultiSelectDropdown
                                placeHolder="Select Allergy"
                                value={allergyvalues}
                                err={allergyvaluesErr}
                                calback={val => (setAllergy(val), setAllergyErr(''))}
                                list={allergyList} />
                        </div>


                        <div className="col-md-12 mb-3 d-flex cm_gendr">
                            <label>Allergy is deadly</label>
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        value={1}
                                        onChange={e => setAllergyDeadly(e.target.value)}
                                        checked={allergyDeadly === 1 ? 'checked': ''} />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        value={2}
                                        onChange={e => setAllergyDeadly(e.target.value)}
                                        checked={allergyDeadly === 2 ? 'checked': ''} />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div>

                        <div className="col-sm-12 mb-3">
                            <h6>Insurance Information:</h6>
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Medical Insurance Company</label>
                            <input
                                type="text"
                                onChange={e => setMedicalInsuranceCompany(e.target.value)}
                                className="form-control"
                                value={medicalInsuranceCompany}
                                placeholder="Medical Insurance Company" />
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Policy Number</label>
                            <input
                                type="text"
                                onChange={e => setPolicyNumber(e.target.value)}
                                className="form-control"
                                value={policyNumber}
                                placeholder="Policy Number" />
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Group #</label>
                            <input
                                type="text"
                                onChange={e => setGroup(e.target.value)}
                                className="form-control"
                                value={group}
                                placeholder="Group #" />
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Primary Physician</label>
                            <input
                                type="text"
                                onChange={e => setPrimaryPhysician(e.target.value)}
                                className="form-control"
                                value={primaryPhysician}
                                placeholder="Primary Physician" />
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Physician Address</label>
                            <input
                                type="text"
                                onChange={e => setPhysicianAddress(e.target.value)}
                                className="form-control"
                                value={physicianAddress}
                                placeholder="Physician Address" />
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Physician Phone #</label>
                            <div className="cm_phone_field">
                                <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                <PhoneInput
                                    country="US"
                                    className="form-control" 
                                    onChange={e => setPhysicianPhone(e)}
                                    value={physicianPhone}
                                    maxLength={16}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label>Please list any medications taken (please include dosage & timing) or any other special assistance that may be required. (Leave blank if none)</label>
                            <textarea
                                className="form-control"
                                value={medicationsTaken}
                                onChange={e => setMedicationsTaken(e.target.value)}
                                placeholder="Write something here…" />
                        </div>
                      

                        <div className="col-md-12 mb-3 cm_gendr">
                            <label>Will you be bringing your own medication to a program?</label>
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        value={1}
                                        onChange={() => setParticipantBringingOwnMedication(1)}
                                        checked={participantBringingOwnMedication === 1 ? 'checked' : ''} />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        value={2}
                                        onChange={() => setParticipantBringingOwnMedication(2)}
                                        checked={participantBringingOwnMedication === 2 ? 'checked' : ''} />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div>

                
                        <div className="col-md-12 mb-3 cm_gendr">
                            <label>I may be included in digital and film photography and/or video while on site.</label>
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={e => setAllowDocumentCamper(1)}
                                        checked={allowDocumentCamper === 1 ? 'checked' : ''}
                                        value={1} />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={e => setAllowDocumentCamper(2)}
                                        checked={allowDocumentCamper === 2 ? 'checked' : ''}
                                        value={2} />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div>

                        

                        <div className="col-md-12 form-group">
                            <label>Please attach any accompanying documents that you feel are necessary for you to serve (Additional medical documents, etc.).</label>
                            <div className="attach_file_btn">Attach PDF <input type="file" value="" accept="application/pdf" onChange={e => uploadHippaCompliance(e,'medical')} /></div>
                            {medicalDocument ? <div className="cm_pdf_icon"><PDFIcon /><i className="icon-cross" onClick={() => setMedicalForm('')} /> <p>{medicalDocument ? medicalDocument.slice(medicalDocument.lastIndexOf('_') + 1) : ''}</p></div> : ''}                              
                        </div>


                       


                    </div>


                    
                    <div className="row">

                        <div className="col-md-12 form-group">
                            <label>Personal Bio [Optional]</label>
                            <textarea
                                name="description"
                                placeholder="Write Description/short bio here of the New Personnel, who they will be serving, passions etc."
                                className={`form-control ${descriptionError ? 'is-invalid' : ''}`}
                                value={userDescription}
                                onChange={(e)=> { setUserDescription(e.target.value); setdescriptionError(false)}}
                                />
                        </div>

                        <div className="col-md-12 mb-3 cm_gendr">
                            <label>Download Training Guide PDF</label>
                            <ul className="d-flex">
                                {campDetail?.trainingGuidePdf ? <div className="trainingGuidePdf"><a target="_blank" href={campDetail.trainingGuidePdf}><PDFIcon /> {campDetail.trainingGuidePdf ? campDetail.trainingGuidePdf.slice(campDetail.trainingGuidePdf.lastIndexOf('_') + 1) : ''}</a></div> : ''}
                            </ul>
                        </div>

                        <div className="col-md-12 mb-3 cm_gendr">
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        value={readTrainingGuide}
                                        onChange={handleReadChange}
                                        checked={readTrainingGuide === 1 ? true: false} />
                                    <span className="check_box"><span /><b>Agreement</b></span>   
                                </li>
                            </ul>
                        </div>

                        <div className="col-sm-12 mb-3 cm_gendr">
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        type="checkbox"
                                        onChange={handleLegalSignChange}
                                        value={signIsLegal}
                                        checked={signIsLegal === 1 ? true: false} className="form-control"/>
                                    <span className="check_box"><span /> Agreement Signature</span>
                                    
                                </li>
                            </ul>
                            {signIsLegal === 1 ? <img src={signatureImg} /> : ''}
                           
                        </div>
                        
                    </div>

                    <div className="text-center mb-5">
                    {
                        props.Details ? 
                         <Link
                            onClick={() => props.HideEditmode()}
                            className="btn btn-light rounded-0 mr-2"
                            to={`/personnel/${props.Details._id}`}
                            style={{width: '130px'}}>Cancel</Link>
                         :
                         <Link className="btn btn-light rounded-0 mr-2" to="/personnel" style={{width: '130px'}}>Cancel</Link> 
                    }
                        
                        <button onClick={() => setBtnLoader(true)} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Save</button>
                    </div>



                    <Modal
                        dialogClassName="h_auto"
                        show={signPadPopup}
                        animation={false}
                        centered>
                        <Modal.Body>
                            <div className="p-5">
                                <h3 className="text-center mb-4">Signature</h3>
                                <div className="signature_pad">
                                <SignaturePad
                                    onEnd={handleSignaturePadChange}
                                    ref={signaturePadRef}
                                    canvasProps={{width: 361, height: 200, className: 'sigCanvas'}} />
                                </div>
                            
                            <div className="mt-2 d-flex justify-content-center">
                                <button disabled={isSignatureClear ? true : false} className="btn btn-primary btn-block" onClick={handleSignaturePadSave}>Done</button>
                                <button className="btn btn-primary btn-block ml-1" onClick={() => (signaturePadRef.current.clear(), setIsSignatureClear(true))}>Clear</button>
                            </div>    
                            </div>
                        </Modal.Body>
                    </Modal>



                    <Modal
                        dialogClassName="h_auto"
                        show={trainingGuideModal}
                        size="lg"
                        animation={false}
                        centered>

                        <Modal.Body>
                            <span className="modal_close">
                            <span onClick={() => setTrainingGuideModal(false)} ><i className="icon-cross" /></span></span>
                            <div className="p-5">
                                <h3 className="text-center mb-4">Agreement</h3>
                                <p>{agreement ? getHtml(agreement) : ''}</p>
                                <span className="btn btn-primary btn-block mt-2" onClick={()=> (setTrainingGuideModal(false), setReadTrainingGuide(1))}>I agree</span>
                            </div>
                        </Modal.Body>
                    </Modal>




                    

                    </form>




                </div>
            </div>



        
        </div>
    </div>

    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { preList } = state.program;
    return {
        userProfile,
        preList
    };
}

const mapDispatchToProps = dispatch => ({
    upadteUserProfileAction: params => dispatch(upadteUserProfileAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(AddCounselor);
