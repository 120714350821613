import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {CardLoader} from '../reuse/SVG';
import { connect } from 'react-redux';
import { getProgramType, getGender, getgendersdisplay, campDates } from '../../helper';
import { programFavourite } from '../../services/programService';
import { LogoWhite } from '../reuse/SVG';
import { notification } from 'antd';

const getProgramPriceByDates = (allPrices) => {
    if(allPrices.length){
        if(allPrices[0].standardPrice > 0){
            return `Price: $${allPrices[0].standardPrice.toFixed(2)}`
        }else {
            return 'Free'
        } 
    }
}

const CampCard = props => {
    let { program } = props;
    const [isFavouritecamp, setIsFavourite] = useState(program.isFavourite);
    const [loading, setLoadningAction] = useState(false);
    const [isloggin, setIsuserLogin] = useState(false);
    
    const handleFavourite = (programId, favourite) => {
        setLoadningAction(true);
        let params = {
            programId: program._id,
            favourite: favourite
        }

        programFavourite(params).then(res => {
            notification.open({
                description: `You started ${favourite ? 'follow' : 'unfollow'} this camp`,
            })
            setLoadningAction(false);
            setIsFavourite(favourite);
        })
    }
    return (
        <div className="camp_card">
            {loading ? <CardLoader/>: ''}
            <Link to={`/camps/${program._id}`} className="card_link" />
            <div className="camp_card_head">
            {program.isSpecialDeal || props.isSD ? <span className="SD_tag">Special Offers</span> : <h4>{program.camps}</h4>}
            {program.isOnline ? <span className="SD_tag cm_online_tag">Online</span>: ''}
                {
                props.loggedIn ?
                isFavouritecamp ? ( 
                <span className= "btn_like active"  onClick={()=>handleFavourite(program._id, 0)}><i className="icon-heart" /></span>
              )
              :
              <span className= "btn_like" onClick={()=>handleFavourite(program._id, 1)}><i className="icon-heart" /></span> 
              :''
              }
                
            </div>
    
            {program?.programImages?.length ? <img className='for_desk_p_img' src={program?.programImages.filter(i => !i.viewType)[0]?.url || program?.programImage} /> : <div className="no_img"><LogoWhite /></div>}
            {program?.programImages?.length ? <img className='for_mob_p_img' src={program?.programImages.filter(i => i.viewType)[0]?.url || program?.programImage} /> : <div className="no_img"><LogoWhite /></div>}
    
            <div className="camp_card_content">
                <h3>{program.programName}</h3>
                <div className="row no-gutters">
                    <div className="col-md-8">
                        <p>Program Date : {program._id === "621d3620c9cc80756fe0a171" ? "TBA" : campDates(program.startDate, program.endDate)}</p>
                        <p>Type of Program : {getProgramType(program.programType)} <span className="cm_horizental_space" /></p>
                        <p>Time : {program.checkInTime} - {program.checkOutTime}</p>
                        <p>Gender : {getgendersdisplay(program.gender)} <span className="cm_horizental_space" /> Age Group : {program.ageGroup}</p>
                    </div>
                    <div className={`col-md-4 text-right ${program.isSpecialDeal || props.isSD ? 'cm_spacial_price' : ''}`}>
                        {program.byDates === 1 ? (
                            <h3>{getProgramPriceByDates(program.additionalDates)}</h3>
                        ) : (
                            <h3><span className="under_red"> {program.registrationFee ? `Price : $${(program.registrationFee + (program.registerNonRefundFee || 0)).toFixed(2)}` : 'Free'}   </span></h3>
                        )}
                        
                        {
                           program.isSpecialDeal || props.isSD ? <h2>${program.specialDealsPrice.toFixed(2)}</h2>  : ''} 
                        
                        {/* {program.price ? <h2>${program.dealType === '1' ? parseInt(program.price).toFixed(2) : (program.registrationFee - (program.price/program.registrationFee) * 100).toFixed(2)}</h2> : ''} */}
                        
                        <p>EZPro <span className="EZPro_rating">5 <i className="icon-star"></i></span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    let { loggedIn } = state.user;
    return{
        loggedIn
    }
}

export default connect(mapStateToProps, null)(CampCard)