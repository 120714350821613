import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { getProgramType, dateFormat, getgendersdisplay, dateFormatfordays } from '../../helper';
import { LogoWhite } from '../reuse/SVG';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};


const Card = props => (
    <div className="cm_card">
        <div className="card_img_wrap">
            {
                props.data.programImage ? <img src={props.data.programImage} /> : <div className="no_img"><LogoWhite /></div>
            }
            
            
            <h4>{props.data.camps}</h4>
            {props.isFeatured ? <span className="cm_Ad">Ad</span> : ''}
            

            <div className="d-flex justify-content-between align-items-center camp_card_name spacial_price_Card">
                <h3>{props.data.programName}</h3>
                <div className={`text-right ${props?.data?.isSpecialDeal  ? 'cm_spacial_price' : ''}`}>
                        
                    <h2><span className="under_red"> {props?.data?.registrationFee ? `Price : $${(props?.data?.registrationFee + (props?.data?.registerNonRefundFee || 0)).toFixed(2)}` : 'Free'}   </span></h2>
                    {
                    props?.data?.isSpecialDeal ? <h2>${props?.data?.specialDealsPrice.toFixed(2)}</h2>  : ''} 
                    
                    {/* {program.price ? <h2>${program.dealType === '1' ? parseInt(program.price).toFixed(2) : (program.registrationFee - (program.price/program.registrationFee) * 100).toFixed(2)}</h2> : ''} */}
                    
                </div>
            </div>
        </div>
        
        <div className="card_body">
            <div className="d-flex justify-content-between">
                <div>
                    <time className="color-primary">{dateFormat(props.data.startDate)} - {dateFormat(props.data.endDate)}</time>
                    <p className="color-primary">({ dateFormatfordays(props.data.startDate, props.data.endDate)})</p>
                </div>
                <div><Link to={`/camps/${props.data._id}`} className="btn btn-primary rounded-0 pl-4 pr-4">Details</Link></div>
            </div>

            <div className="d-flex justify-content-between">
                <div>
                    <p className="text-nowrap">{getProgramType(props.data.programType)}</p>
                    <p className="text-nowrap">Time : {props.data.checkInTime} - {props.data.checkOutTime}</p>
                    <p>Age : {props.data.ageGroup}</p>
                </div>
                <div>
                    <p className="text-nowrap">Gender : {getgendersdisplay(props.data.gender)}</p>
                    <p className="text-right"> EZPro <span className="EZPro_rating">0 <i className="icon-star" /></span></p>
                    {/* <div className="text-right close_red"><i className="icon-close" /></div> */}
                </div>
            </div>


        </div>
    </div>
)



const CampsCarousel = props => {
    return(
        <div className="container">
            <h2 onClick={() => props.handleViewAll(props.title)} className={`cm_cursor section_title_center ${props.isFeatured ? 'cm_color_white' : ''}`}>{props.title}</h2>
            {props.isFeatured ? <p className="text-center cm_color_white">Get inspiration for your next activity</p> : ''}
            {
               props.list && (props.list.data.length >= 3) ? <Slider {...settings}>
                                                                {props.list && props.list.data.map(item => <Card key={item} {...props} data={item} />)}
                                                            </Slider> : <div className="row d-flex justify-content-center">{ props.list && props.list.data.map(item => <div className="col-md-4"><Card key={item} {...props} data={item} /></div>)}</div>
            }

            
        </div>

    )
}

export default CampsCarousel;