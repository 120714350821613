import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
 
import { toggleAuthModal } from '../../store/ui';
import { logoutAction } from '../../store/user';
import { fLatLongAction } from '../../store/pFilter';
import { getPreListAction, getCamperListAction } from '../../store/program';
import {getitemsincart, productCartCountAction} from '../../store/cart'
import { getCartItems } from '../../services/programService';
import { unreadCountAction } from '../../store/chat';
import { counselordetail } from '../../services/directorServices';
import { getProductsCartCountService } from '../../services/onlineStoreService';
import { StoreIcon } from '../reuse/SVG';


const Header = props => {

const handleAddMenuClass = () => document.body.classList.add('menu_open');
const handleremoveMenuClass = () => document.body.classList.remove('menu_open');

const [permissions, setPermissions] = useState({});
const [incicator, setIndicator] = useState(false)

useEffect(() => {
    if(props?.userProfile?.UserType === 4 && (!props?.isMaintenance)){
        counselordetail(`/?counselorId=${props.userProfile._id}`).then((res) => {
            if(res.data.statusCode === 1){
                setPermissions(res.data.responseData.result.permissions || {}) 
            }
        })
    }
}, [props.userProfile])


    useEffect(() => {
        if((!props?.isMaintenance)){
            props.getPreListAction();
            if(props.loggedIn){
                props.getCamperListAction()
            }
        }
        const isAdmin = localStorage.getItem('isAdminLogin')
        if(isAdmin === "1"){
            setIndicator(true);
        }

        
    }, [props.loggedIn]);


    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            props.fLatLongAction([position.coords.latitude, position.coords.longitude]);
        })
    }

    useEffect(() => {
        if(props.loggedIn && (!props?.isMaintenance)){
            getCartItems().then(res => {
                if(res?.data?.statusCode){
                    props.getitemsincart(res.data.responseData.result.length)
                }
            })
            props.unreadCountAction();

            getProductsCartCountService().then(res => {
                if(res?.data?.statusCode){
                    props.productCartCountAction(res.data.responseData.result.itemCount)
                }
               
            })

        }
    },[])





    function formatPhoneNumber(phoneNumber) {
        phoneNumber = phoneNumber.replace(/\D/g, '');
    
        var countryCode = '';
        if (phoneNumber.length === 11) {
            countryCode = '+' + phoneNumber.substring(0, 1) + ' ';
            phoneNumber = phoneNumber.substring(1);
        }

        return countryCode + '(' + phoneNumber.substring(0, 3) + ') ' +
               phoneNumber.substring(3, 6) + '-' +
               phoneNumber.substring(6);
    }

    return(
        <header>
            <div className="top_bar">

                <div className="container">

                    <div className="d-flex justify-content-between align-content-center cm_d_for_mobile">

                        <div className="d-flex top_bar_left align-items-center">
                           <div className='for_font_size_sm'>
                            {
                                (props.campEmail || props.campPhone) ? <div className='camp_contact'>
                                <span className='camp_contact_text'>Question about camp?</span>
                                {props.campPhone ? <a href={`tel:${props.campPhone}`}><i className="icon-phone" />{formatPhoneNumber(props.campPhone)}</a> : ''}
                                {props.campEmail ? <a href={`mailto: ${props.campEmail}`} target="_blank"><i className="icon-email" /> {props.campEmail}</a> : ''}
                            </div> : ''
                            }

                                

                                <div>
                                    <span className='camp_contact_text'>Question about computer software?</span>
                                    <a href="tel:+19712453061"><i className="icon-phone" /> +1 (971)-245-3061</a>
                                    {props.isTeamsEZ ? <a href="mailto: support@teamsez.com" target="_blank"><i className="icon-email" /> support@teamsez.com</a> : <a href="mailto: support@campsez.com" target="_blank"><i className="icon-email" /> support@campsez.com</a>}
                                </div>

                           </div>
                            
                            <div className="d-flex ml-5 social_links">
                                <a target="_blank" href="https://www.facebook.com/CampsEZ"><i className="icon-facebook" /></a>
                                <a target="_blank" href="https://www.instagram.com/camps_ez/"><i className="icon-instagram" /></a>
                                <a target="_blank" href="https://www.youtube.com/channel/UCpe_I1IlZ5zyOSp7jnH7_3A"><i className="icon-youtube" /></a>
                                <a target="_blank" href="https://twitter.com/Camps_EZ" ><i className="icon-twiter" /></a>
                                <a target="_blank" href="https://www.linkedin.com/company/campsez/about/?viewAsMember=truehttps://www.linkedin.com/company/campsez/about/?viewAsMember=true"><i className="icon-linkedin" /></a>
                            </div>
                        </div>
                        {(!props?.isMaintenance) && 
                        <div className="top_bar_right d-flex align-items-center">
                            <div className="gift_card_link">
                                {props.loggedIn ? <Link to="/online-store" className="icon__cart">
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <StoreIcon/>
                                                                <span className='ml-1'>Online Store</span>
                                                            </div>
                                                    </Link> : ''}  
                                <Link to="/gift-cards">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <i className="icon-offers"/> <span className='ml-1'> Gift Cards</span>
                                         </div>
                                </Link>
                                {props.loggedIn ? <Link to="/inbox">
                                    <div className='d-flex justify-content-between align-items-center gap-2'>
                                        <i className="icon-send" />
                                    </div>
                                </Link> : ''}
                                
                            </div>

                           

                            {props.loggedIn  ? <Dropdown className="header_drop">
                                                <Dropdown.Toggle id="dropdown-basic">Hello, <span className='user_name'>{props.userProfile.name}</span></Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="javascript:void(0);"><Link to="/profile/information">Account</Link></Dropdown.Item>
                                                    {props?.userProfile?.UserType === 4 || props?.userProfile?.UserType === 2 ? props?.userProfile?.UserType === 4 && !permissions.addUser ? '' : <Dropdown.Item href="javascript:void(0);"><Link to="/users">Users</Link></Dropdown.Item> : ''}
                                                    {props.userProfile.UserType === 2 ? <Dropdown.Item href="javascript:void(0);"><Link to="/payments">Payments</Link></Dropdown.Item> : ''}
                                                    {props.userProfile.UserType === 2 ? <Dropdown.Item href="javascript:void(0);"><Link to="/products-booking">Products Bookings</Link></Dropdown.Item> : ''}
                                                     {props.userProfile.UserType !== 2  &&  props.userProfile.UserType !== 4 ?  
                                                     <>       
                                                    <Dropdown.Item><Link to="/profile/bookings">My Bookings</Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to={{pathname: '/calendar', state: {"type": 0 }}}>My Calendar</Link></Dropdown.Item>
                                                    </> :''}
                                                    <span className="dropdown-item" onClick={props.logoutAction}>Log Out</span>
                                                </Dropdown.Menu>
                                            </Dropdown> : <span className="pointer" onClick={() => props.toggleAuthModal(1)}>Sign In</span> 
                        
                            }
                        </div>
                        }

                    </div>
                    
                </div>
            </div>

        {(!props?.isMaintenance) &&
            <div className="cm_header">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="menu_trigger" onClick={handleAddMenuClass}><span /></div>
                        <Link to='/' className="cm_logo"><img src={require(`../../assets/img/${props.isTeamsEZ ? 'teamsez.png' : 'logo.svg'}`)} alt=""/></Link>
                        {incicator && <div class="circle-indicator"></div>}
                        {props?.userProfile?.UserType === 1 ? <Link className="show_on_mob" onClick={handleremoveMenuClass} to='/cart'><i className="icon-cart" /> Cart</Link> : ''}
                        
                        <div className="menu_wrapper">
                            
                            {
                                props?.userProfile ?
                                    props.userProfile.UserType === 2 ?
                                        (
                                            <ul>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/'>Dashboard</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/bookings'>Bookings</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/personnel'>Personnel</NavLink></li>
                                                <li><NavLink onClick={handleremoveMenuClass} to='/activity'>Activity List</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/inbox'>Inbox {props.unreadCount ? <span className="unreadCount">{props.unreadCount}</span> : ''}</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/feeds'>Feeds</NavLink></li>
                                                <li className="normal_menu"><NavLink exact onClick={handleremoveMenuClass} to='/director-camps'>Program Info</NavLink></li>
                                            </ul>  
                                        ) 
                                        :

                                         props.userProfile.UserType === 4 ?
                                         (
                                            <ul>
                                            <li><NavLink exact onClick={handleremoveMenuClass} to='/bookings'>Bookings</NavLink></li>
                                            <li><NavLink exact onClick={handleremoveMenuClass} to='/my-calendar'>My Calendar</NavLink></li>
                                            <li><NavLink onClick={handleremoveMenuClass} to='/activity'>Activity List</NavLink></li>
                                            <li><NavLink exact onClick={handleremoveMenuClass} to='/inbox'>Inbox</NavLink></li>
                                            <li><NavLink exact onClick={handleremoveMenuClass} to='/feeds'>Feeds</NavLink></li>
                                            <li className="normal_menu"><NavLink exact onClick={handleremoveMenuClass} to='/director-camps'>Program Info</NavLink></li>
                                        </ul>   
                                         )
                                         :
                                         (
                                            <ul>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/'>Home</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/camps'>Programs</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/special-offers'>Special Deals</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/about-us'>About Us</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/feeds'>Feeds</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/pickup'>Authorized Pickup</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/inbox'>Inbox {props.unreadCount ? <span className="unreadCount">{props.unreadCount}</span> : ''}</NavLink></li>
                                                {
                                                props.loggedIn && (<li><NavLink exact onClick={handleremoveMenuClass} to='/favorite'>Favorites</NavLink></li>)
                                                }
                                                <li className="hide_in_mob"><NavLink exact onClick={handleremoveMenuClass} to='/cart'><i className="icon-cart" /> Cart ({props.numberofcartitems !== '' && (props.numberofcartitems + props.productCartCount)})</NavLink></li>
                                            </ul> 
                                        ) : (
                                            <ul>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/'>Home</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/camps'>Programs</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/special-offers'>Special Deals</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/about-us'>About Us</NavLink></li>
                                                <li><NavLink exact onClick={handleremoveMenuClass} to='/feeds'>Feeds</NavLink></li>
                                                {
                                                props.loggedIn && (<React.Fragment><li><NavLink exact onClick={handleremoveMenuClass} to='/pickup'>Authorized Pickup</NavLink></li><li><NavLink exact onClick={handleremoveMenuClass} to='/favorite'>Favorites</NavLink></li></React.Fragment>)
                                                }
                                                <li className="hide_in_mob"><NavLink exact onClick={handleremoveMenuClass} to='/cart'><i className="icon-cart" /> Cart ({props.numberofcartitems!=='' && props.numberofcartitems})</NavLink></li>
                                            </ul>
                                        )
                            }


                            
                        </div>

                    </div>
                </div>
            </div>
        }
            <div onClick={handleremoveMenuClass} className="menu_overlay" />
        </header>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { unreadCount } = state.chat;
    let { isTeamsEZ, campPhone, campEmail } = state.ui;
    let { userProfile, loggedIn } = state.user;
    let numberofcartitems = state.cartReducer.cartitems;
    let productCartCount = state.cartReducer.productCartCount;
    return {
        userProfile,
        loggedIn,
        numberofcartitems,
        unreadCount,
        isTeamsEZ,
        productCartCount,
        campEmail,
        campPhone
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleAuthModal: params => dispatch(toggleAuthModal(params)),
    getPreListAction: () => dispatch(getPreListAction()),
    logoutAction: () => dispatch(logoutAction()),
    fLatLongAction: params => dispatch(fLatLongAction(params)),
    getCamperListAction: () => dispatch(getCamperListAction()),
    getitemsincart: (cartitems) => dispatch(getitemsincart(cartitems)),
    unreadCountAction: () => dispatch(unreadCountAction()),
    productCartCountAction: (params) => dispatch(productCartCountAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);