import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, } from 'react-bootstrap';
import { getUserDetailService } from '../../services/otherService';
import { resHandle } from '../../helper';
import ProfileInformation from './ProfileInformation';
import CamperInfo from './CamperInfo';
import UserHistory from './UserHistory';
import UserNotes from './UserNotes';
import { getNotesService } from '../../services/campersServices';
import PaymentHistory from '../booking/PaymentHistory';




const UserDetail = props => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('profileInfo');
    const [userData, setUserData] = useState({});
    const [totalNotsCount, setTotalNotsCount] = useState('');



    const getUserDetail = () => {
        getUserDetailService(props.match.params.id).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setUserData(data.result)
            }
        })
        let query = `?userId=${props.match.params.id}&campCode=${props.userProfile.campCode}`;
        getNotesService(query).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setTotalNotsCount(data.count);
            }
        })



    }


    useEffect(() => {
        if(props.match.params.id){
            getUserDetail();
        }

    }, [props.match.params.id])


    useEffect(() => {
        if(props.userProfile.UserType){
            if(props.userProfile.UserType === 2 || props.userProfile.UserType === 4){
                
            }else{
                history.push('/')
            }
        }

    }, [props.userProfile])


    return (
        <div className="body_container p-3">
            <div className='container'>
                <Tabs onSelect={e => setActiveTab(e)} defaultActiveKey="profileInfo">
                    <Tab eventKey="profileInfo" title="Profile Information">{activeTab === 'profileInfo' ? <ProfileInformation callback={getUserDetail} data={userData} {...props} /> : ''}</Tab>
                    <Tab eventKey="camperInfo" title="Participant(s)">{activeTab === 'camperInfo' ? <CamperInfo callback={getUserDetail} data={userData} {...props} /> : ''}</Tab>
                    <Tab eventKey="userHistory" title="History">{activeTab === 'userHistory' ? <UserHistory callback={getUserDetail} data={userData} {...props} /> : ''}</Tab>
                    <Tab eventKey="userNotes" title={<span className='notes_count'>Notes {totalNotsCount ? <b>{totalNotsCount > 99 ? '99+' : totalNotsCount}</b> : ''}</span>}>{activeTab === 'userNotes' ? <UserNotes callback={getUserDetail} data={userData} {...props} /> : ''}</Tab>
                    <Tab eventKey="paymentHistory" title={<span className='notes_count'>Payment History </span>}>{activeTab === 'paymentHistory' ? <PaymentHistory data={userData} /> : ''}</Tab>
                </Tabs>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    return {
      userProfile
    }
  }
  
  const mapDispatchToProps = dispatch => ({

  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);