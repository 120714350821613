import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { changeAuthModal, toggleForgotModal } from '../../store/ui';
import { forgotPasswordAction } from '../../store/user';
import { isValidEmail } from '../../helper';


const ForgotPassword = props => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');


    const handleChange = e => {
        setEmail(e.target.value);
        setEmailError('');
    }



    const handleValidate = () => {
        let validate = true;
        if(email === ''){
            setEmailError('Email is required');
            validate = false
        }else if(!isValidEmail(email)){
            setEmailError('Email is not valid');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let params = {
                email
            }

            props.forgotPasswordAction(params);
        }
    }

    return(
        <Modal
            dialogClassName="auth_modal h_auto"
            show={props.forgotModal}
            animation={false}
            centered
        >
            <Modal.Body>
            <form onSubmit={handleSubmit} className="p-5">
                <input type="email" name="email" style={{display: 'none'}} />
                <h3 className="text-center mb-4">Reset your password</h3>

                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className={`form-control border_form_control ${emailError ? 'is-invalid' : ''}`}
                        name="email"
                        value={email}
                        onChange={handleChange} />
                        {emailError ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                </div>


                <div className="text-right">
                    <button type="submit" className="btn btn-primary rounded-0 btn-block mt-4">Reset Password</button>
                </div>

                {props.error && <p className="error_msg">{props.error}</p>}

                <p className="text-center mt-4">Back to <span className="cm_link_red" onClick={() => props.changeAuthModal(1)}>Sign In</span></p>

            </form>
            </Modal.Body>
        </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { forgotModal } = state.ui;
    let { error } = state.user;
    return {
        forgotModal,
        error
    };
}
  
const mapDispatchToProps = dispatch => ({
    changeAuthModal: params => dispatch(changeAuthModal(params)),
    toggleForgotModal: params => dispatch(toggleForgotModal(params)),
    forgotPasswordAction: params => dispatch(forgotPasswordAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);