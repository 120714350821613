import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Empty, notification } from 'antd';

import { directorActivityListService, createActivityFolderService } from '../../services/activityService';


const SelectActivity = (props) => {
    const [activities, setActivities] = useState([]);
    const [search, setSearch] = useState('');
    const [activitesIds, setActivityIds] = useState([]);
    const [selectdeselectType, setSelectDeSelectType] = useState(1);
    const [btnloder, setBtnLoader] = useState(false);
    const [activityWarning, setActivityWarning] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [folderalreadyExit, setFolderAlreadyExit] = useState(false);

    const getActivities = () => {
        let params = `?search=${search}`;
        directorActivityListService(params).then(res => {
            if (res.data.statusCode) {
                setActivities(res.data.responseData.result.activity);
            }
        })
    }


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getActivities();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])



    const selectDeselectAction = (type) => {
        let listactivites = [...activitesIds];
        if (type === 1) {
            listactivites.length = 0
            activities.map((item) => {
                listactivites.push(item._id);
            })
            setSelectDeSelectType(2)
        }
        else {
            listactivites.length = 0
            setSelectDeSelectType(1)
        }
        setActivityIds(listactivites);
    }


    const handelActivityAction = (e) => {
        setActivityWarning(false)
        let listactivites = [...activitesIds];
        if (listactivites.includes(e.target.value)) {
            selectDeselectAction(2)
            let newlist = listactivites.filter(item => item !== e.target.value)
            setActivityIds(newlist);
        }
        else {
            listactivites.push(e.target.value)
            if (listactivites.length === activities.length) { selectDeselectAction(1) }
            setActivityIds(listactivites);
        }
    }

    const createFolderAction = () => {
        let listactivites = [...activitesIds];
        if (listactivites.length === 0) {
            setActivityWarning(true);
            setBtnLoader(false)
        }
        else {
            let params = { "folderName": props.match.params.folderName, "activityIds": JSON.stringify(listactivites) }
            createActivityFolderService(params).then((res) => {
                if (res.data.statusCode === 1) {
                    setBtnLoader(false)

                    notification.open({
                        description:
                            'Folder Created Successfully',
                    });
                    setRedirect(true);
                    setBtnLoader(false);
                    setFolderAlreadyExit(false)
                }
                else if (res.data.statusCode === 0) {
                    setBtnLoader(false);
                    setFolderAlreadyExit(true)
                }
                else {
                    setBtnLoader(false)
                }
            })
        }
    }


    return (

        <div className="body_container pb-4 director_wrapper">
            {redirect ? <Redirect push to="/activity" /> : ''}

            <div className="container">


                <div className="d-flex page_card_header">
                    <h4>Activity List</h4>
                </div>
                <div className="row">
                    <div className="col-lg-2" />

                    <div className="col-lg-8">

                        <div className="d-flex align-items-center justify-content-between page_card_header cm_border_0">
                            <h4>Select Activity</h4>
                            <div className="btn_group">
                                {
                                    selectdeselectType === 1 ? <button onClick={() => { selectDeselectAction(1) }} className="btn btn-primary">Select all</button> : <button onClick={() => { selectDeselectAction(2) }} className="btn btn-primary">DeSelect all</button>
                                }

                            </div>
                        </div>


                       

                        <div className="partner-form search_wrap" style={{ maxWidth: '100%' }}>
                            <i className="icon-search" />
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Search"
                                value={search}
                                onChange={e => setSearch(e.target.value)} />
                        </div>
                        {
                            activities.length ? activities.map(item => (
                                <div className="d-flex align-items-center counselor_card sel_card" key={item._id}>
                                    <div>
                                        <h4>Activity name : {item.activityName}</h4>
                                        <h4>Equipment : {item.equipment}</h4>
                                        <h4>Cost : ${item.cost ? item.cost.toFixed(2) : '0'}</h4>
                                        <h4>Time needed : {item.timeNeeded} minutes</h4>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input type="checkbox" value={item._id} onChange={(e) => { handelActivityAction(e) }} checked={activitesIds.includes(item._id) ? 'checked' : ''} />
                                            <span className="check_box"><span></span></span>
                                        </div>
                                    </div>
                                </div>
                            )) : <div className="cm_empty m-auto"><Empty description="Data Not Found" /> </div>
                        }

                    <div className="col-sm-12 text-center mb-5">
                            {
                                activityWarning ?
                                    <div className="waring_text" role="alert">
                                        Please Select the Activity First
                                    </div>
                                    : ''
                            }
                            {
                                folderalreadyExit ? <p className="waring_text">Folder with same name already exit</p> : ''
                            }
                        </div>

                        <div className="col-sm-12 text-center mb-5">
                            <Link to="/activity" className="btn btn-light rounded-0 mr-2" style={{ width: '130px' }}>Cancel</Link>
                            <button onClick={() => { setBtnLoader(true); createFolderAction(); }} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{ width: '130px' }}>Submit</button>
                        </div>


                    </div>
                </div>




            </div>
        </div>
    )
}

export default SelectActivity;