import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { notification } from 'antd';

import { activityfoderdetails, updatefolderactivity, addmoreActivity } from '../../services/activityService';
import { ProgramLoader } from '../reuse/SVG';


const FolderDetailsActivity = (props) => {
    const [loading, setLoading] = useState(true);
    const [activities, setActivities] = useState([]);
    const [removeactivityfromfolder, setRemoveActivityfromFolder] = useState(false);
    const [removeactivityid, setremoveActivityId] = useState();
    const [addmore, setAddmore] = useState(false);
    const [newListActivity, setNewListActivity] = useState([]);
    const [activitesIds, setActivityIds] = useState([]);
    const [selectdeselectType, setSelectDeSelectType] = useState(1);
    const [activityWarning, setActivityWarning] = useState(false);
    const [btnloder, setBtnLoader] = useState(false);
    const [folderName, setFolderName] = useState('');

    useEffect(() => {
        let params = `/?folderId=${props.match.params.id}`;
        activityfoderdetails(params).then((res) => {
            if (res.data.statusCode === 1) {

                setActivities(res.data.responseData.result)
                setFolderName(res.data.responseData.folderName);
                setLoading(false)
            }
            else {
                setLoading(true)
            }
        })
    }, [])


    const removeActivityfromfolderAction = () => {

        let params = { "folderId": props.match.params.id, "activityIds": JSON.stringify([removeactivityid]), "type": 2 }
        updatefolderactivity(params).then(res => {
            if (res.data.statusCode === 1) {
                let newactivites = activities.filter(item => item._id !== removeactivityid);
                setActivities(newactivites);
                setremoveActivityId();
                setLoading(false);
                setRemoveActivityfromFolder(false)
                notification.open({
                    description:
                        'Activity successfully removed from folder',
                });
            }
            else {
                setLoading(false);
                notification.open({
                    description:
                        'Please try after some time',
                });
            }
        })
    }


    const addMoreAction = () => {
        setAddmore(true);
        setLoading(true);
        let params = `/?folderId=${props.match.params.id}`;
        addmoreActivity(params).then(res => {
            setLoading(false);
            if (res.data.statusCode === 1) {
                setNewListActivity(res.data.responseData.result);
                //setFolderName(res.data.responseData.folderName);
            }
            else {
                setAddmore(false);
                notification.open({
                    description:
                        'Please try after some time',
                });
            }
        })
    }

    const handelActivityAction = (e) => {
        setActivityWarning(false)
        let listactivites = [...activitesIds];
        if (listactivites.includes(e.target.value)) {
            selectDeselectAction(2)
            let newlist = listactivites.filter(item => item !== e.target.value)
            setActivityIds(newlist);
        }
        else {
            listactivites.push(e.target.value)
            if (listactivites.length === newListActivity.length) { selectDeselectAction(1) }
            setActivityIds(listactivites);
        }
    }

    const selectDeselectAction = (type) => {
        let listactivites = [...activitesIds];
        if (type === 1) {
            listactivites.length = 0
            newListActivity.map((item) => {
                listactivites.push(item._id);
            })
            setSelectDeSelectType(2)
        }
        else {
            listactivites.length = 0
            setSelectDeSelectType(1)
        }
        setActivityIds(listactivites);
    }

    const updateFolderAction = () => {

        let listactivites = [...activitesIds];
        if (listactivites.length === 0) {
            setActivityWarning(true);
            setBtnLoader(false)
        }
        else {
            setLoading(true);
            let params = { "folderId": props.match.params.id, "activityIds": JSON.stringify(activitesIds), "type": 1 }
            updatefolderactivity(params).then(res => {
                setBtnLoader(false);
                if (res.data.statusCode === 1) {

                    let params = `/?folderId=${props.match.params.id}`;
                    activityfoderdetails(params).then((res) => {
                        if (res.data.statusCode === 1) {
                            setActivities(res.data.responseData.result)
                            setLoading(false)
                        }
                        else {
                            setLoading(true)
                        }
                    })
                    setActivityIds([]);
                    setSelectDeSelectType(1);
                    setAddmore(false);
                    notification.open({
                        description:
                            'Activity successfully added to folder',
                    });
                }
                else {
                    setLoading(false);
                    notification.open({
                        description:
                            'Please try after some time',
                    });
                }
            })

        }

    }

    return (
        <div className="body_container pb-4 director_wrapper">


            <Modal
                show={removeactivityfromfolder}
                animation={false}
                dialogClassName="folder_model"
                size="lg"
                centered
            >
                <Modal.Body>
                    <span className="modal_close" onClick={() => { setRemoveActivityfromFolder(false); setremoveActivityId() }}><i className="icon-cross" /></span>
                    <div className="text-center p-5 pickup_modal_body">
                        <p>Are you sure you want to remove this activity From Folder</p>
                    </div>
                    <div className="text-center p-5">
                        <button onClick={() => { setRemoveActivityfromFolder(false); setremoveActivityId() }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                        <button onClick={() => { removeActivityfromfolderAction() }} style={{ width: '120px' }} className="btn btn-primary ml-3">Yes</button>
                    </div>
                </Modal.Body>
            </Modal>



            <div className="container">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Folder : {folderName}</h4>

                    {
                        !addmore ?

                            <div className="btn_group">
                                {
                                    !addmore ? <span onClick={() => { addMoreAction(); setAddmore(true) }} className="btn btn-primary mr-3">Add More</span> : ''
                                }


                                <Link to="/activity" className="btn btn-primary">Back</Link>

                            </div>
                            :
                            <div className="btn_group">
                                {
                                    selectdeselectType === 1 ? <button onClick={() => { selectDeselectAction(1) }} className="btn btn-primary">Select all</button> : <button onClick={() => { selectDeselectAction(2) }} className="btn btn-primary">Deselect all</button>
                                }

                            </div>
                    }
                </div>



                {
                    !addmore ?

                        <div className="row">
                            <div className="col-lg-2" />


                            {
                                loading ? <div className="program_loader"><ProgramLoader /></div> :
                                    <div className="col-lg-8">
                                        {
                                            activities.length > 0 ?
                                                activities.map(item => (
                                                    <div className="row align-items-center counselor_card" key={item._id}>
                                                        <div className="col-md-7">
                                                            <h4>Activity name : {item.activityName}</h4>
                                                            <h4>Equipment : {item.equipment}</h4>
                                                    
                                                            <h4>Cost : ${item.cost ? item.cost.toFixed(2) : '0'}</h4>
                                                            <h4>Time needed : {item.timeNeeded} minutes</h4>

                                                        </div>
                                                        <div className="col-md-5 text-right">
                                                            <button onClick={() => { setRemoveActivityfromFolder(true); setremoveActivityId(item._id) }} className="btn btn-primary mr-1">Remove From Folder</button>
                                                            <Link to={`/activity/edit/${item._id}`} className="btn btn-primary">View</Link>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <div className="row align-items-center counselor_card">
                                                    <p>No Activity Found</p>
                                                </div>
                                        }
                                    </div>
                            }
                        </div>
                        :
                        <div className="row">
                            <div className="col-lg-2" />
                            {
                                loading ? <div className="program_loader"><ProgramLoader /></div> :
                                    <div className="col-lg-8">


                                        

                                        {
                                            newListActivity.length > 0
                                                ?
                                                <React.Fragment>
                                                    {
                                                        newListActivity.map(item => (
                                                            <div className="d-flex align-items-center counselor_card sel_card" key={item._id}>
                                                                <div>
                                                                    <h4>Activity name : {item.activityName}</h4>
                                                                    <h4>Equipment : {item.equipment}</h4>
                                                                    <h4>Cost : ${item.cost ? item.cost.toFixed(2) : '0'}</h4>
                                                                    <h4>Time needed : {item.timeNeeded} minutes</h4>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <div className="check_box_wrapper cm_dark_check">
                                                                        <input type="checkbox" value={item._id} onChange={(e) => { handelActivityAction(e) }} checked={activitesIds.includes(item._id) ? 'checked' : ''} />
                                                                        <span className="check_box"><span></span></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }


                                                {activityWarning ? <div className="waring_text">Please Select the Activity First </div> : ''}


                                                    <div className="col-sm-12 text-center mb-5">
                                                        <button to="/activity" className="btn btn-light rounded-0 mr-2" onClick={() => { setActivityIds([]); setSelectDeSelectType(1); setAddmore(false); setActivityWarning(false) }} style={{ width: '130px' }}>Cancel</button>
                                                        <button onClick={() => { setBtnLoader(true); updateFolderAction() }} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{ width: '130px' }}>Submit</button>
                                                    </div>
                                                </React.Fragment>

                                                :
                                                <>
                                                    <div className="d-flex align-items-center counselor_card sel_card">
                                                        No More Activity found
                                                    </div>
                                                    <div className="col-sm-12 text-center mb-5">
                                                        <button to="/activity" className="btn btn-primary rounded-0" onClick={() => { setActivityIds([]); setSelectDeSelectType(1); setAddmore(false); setActivityWarning(false) }} style={{ width: '130px' }}>back</button>
                                                    </div>
                                                </>
                                        }


                                        

                                    </div>
                            }
                        </div>

                }

            </div>
        </div>
    )
}

export default FolderDetailsActivity;