import React, {useState, useEffect }from 'react';
import { DatePicker } from 'antd';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input/input';
import { Controller } from "react-hook-form";
import {shortsAndTshirtSizes, medical, dietGuideLines, allergy, gradeArr} from '../../../helper';
import MultiselectDropdownCommon from '../../profile/campers/MultiselectDropdownCommon';
import AddtionalExpForNonLogin from './addtionalexprencesnonlogin';
import {uploadMedia} from '../../../services/campersServices';
import { PDFIcon } from '../../reuse/SVG';


const CamperFormFileds = props => {
    const fieldNamePrev                             = `participants[${props.FormIndex}]`;
    const refprop                                   = props.refprops;
    const [fieldName, setFiedTestname]              = useState(fieldNamePrev);
    const [catlisting, setCatlisting]               = useState([]);
    const [subcatlisting, setSubCatlisting]         = useState([]);
    const [hippaform, setHippaForm]                 = useState();
    const [medicalform, setMedicalForm]             = useState();
    //const [activityInterest, setActivityInterest]   = useState([]);
    const [physicianPhone, setPhysicianPhone]       = useState();
    const [programGender, setProgramGender]         = useState();
    const [programGenderPre, setProgramGenderPre]   = useState();
    const [allergyisDeadly, setAllergyisDeadly]     = useState(''); 
    const [genderWarning, setGenderWarning]         = useState(false);
    const [ageGroupWarning, setAgeGroupWarning]     = useState(false);
    const [photography, setPhotography]             = useState(1);
    const [dietisreligious, setDietisreligious]     = useState(2); 
    const dateFormatNew                             = 'MM/DD/YYYY';
    


    useEffect(() => {
        let filedNameprev = `participants[${props.FormIndex}]`;
        setFiedTestname(filedNameprev);
    },[props.FormIndex])


    useEffect(() => {
        if(props.ProgramGender !==3){
            setProgramGender(props.ProgramGender);
            setProgramGenderPre(props.ProgramGender);
        }

    },[props.ProgramGender])


// const handleCategory = e => {
//     let activityintrest =[...activityInterest];
//     if(catlisting.includes(e._id)){
//         const subCatArr = subcatlisting.filter(el => el.categoryname  !== e.name);
//         const  CatArr =   catlisting.filter(el => el  !== e._id);
//         setSubCatlisting(subCatArr);
//         setCatlisting(CatArr)
//         activityintrest = activityintrest.filter(item => item.category!==e._id)
//     }else{
//         let activint = { "category": e._id, "subcategory":[]}
//         if(e.subCategory.length >0 )
//         {
//         let subcatlistwithname = { "categoryname": e.name, "cat_id":e._id, "catlist": e.subCategory }
//         let subCatArr = [...subcatlisting, subcatlistwithname];
//         setSubCatlisting(subCatArr);
//         setCatlisting([...catlisting, e._id]);
//         }
//         activityintrest.push(activint);
//     }
    
//     setActivityInterest(activityintrest);
// }    

// const handelSubcategory =(cat_id, subcat_id) =>{
//     let activityintrest =[...activityInterest];
//     let category_index = activityintrest.findIndex(cat => cat.category === cat_id);
//     if(activityintrest[category_index].subcategory.includes(subcat_id))
//     {
//         let subcat_index = activityintrest[category_index].subcategory.findIndex(subcat => subcat === subcat_id);
//         activityintrest[category_index].subcategory.splice(subcat_index,1)
//     }
//     else
//     {
//         activityintrest[category_index].subcategory.push(subcat_id);

//     }
//     setActivityInterest(activityintrest);
// }




const  disabledDate = (current) => {
    return current && current > moment().startOf('day');
  }

  const uploadHippaCompliance = (e, type) => {
    const data = new FormData();
    data.append('file', e.target.files[0]);
    uploadMedia(data).then((res)=>{
        if(res.data.responseData.result.fileUrl!==undefined)
        if(type==='hippa')
        {
            setHippaForm(res.data.responseData.result.fileUrl)
        }
        if(type==='medical')
        {
            setMedicalForm(res.data.responseData.result.fileUrl)
        }
    })
}

const handelGender = (e) => {
  if(programGenderPre===1)
  {
      if(e.target.value ===2 || e.target.value ===3)
      {
        setGenderWarning(true);
        setProgramGender(1)
      }
  }
  else if(programGenderPre===2)
  {
    if(e.target.value ===1 || e.target.value ===3)
    {
      setGenderWarning(true);
      setProgramGender(2)
    }   
  }
  else
  {
    setProgramGender(e.target.value)  
  }
}



const handelDietReligious=() =>{
    setDietisreligious(0);
}

const handelAllergydeadly = () =>{
    setAllergyisDeadly(2);
}





    return(
                <div className="row pro_wrapper">


                <Modal
                    dialogClassName="h_auto"
                    show={genderWarning}
                    animation={false}
                    centered
                    >
                    <Modal.Body>
                        <div className="p-5">
                            <h3 className="text-center mb-4">Error</h3>
                            {`The Program is only for the ${programGender ===1 && 'Male' || programGender ===2 && 'Female'}`}
                            <button  onClick={() => {setGenderWarning(false)}} className="btn btn-primary btn-block mt-2">Ok</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    dialogClassName="h_auto"
                    show={ageGroupWarning}
                    animation={false}
                    centered
                    >
                    <Modal.Body>
                        <div className="p-5">
                            <h3 className="text-center mb-4">Error</h3>
                            {`The Program is only for the age group ${props.MinAge} -${props.MaxAge}`}
                            <button  onClick={() => {setAgeGroupWarning(false)}} className="btn btn-primary btn-block mt-2">Ok</button>
                        </div>
                    </Modal.Body>
                </Modal>                



                    <div className="col-lg-11">
                        <div className="d-flex justify-content-between">
                            <div><p className="color_primary">Camper {props.FormIndex+1} : <input type="text" name={`${fieldName}.groupcode`} className= {props.Errors.includes(`camper${props.FormIndex}groupcode`) ? 'is-invalid group_code' :'group_code'} value={props.GroupCode} ref={refprop} placeholder="Group Code" readOnly /></p></div>
                            <div>
                                {
                                  props.RemoveCamper ?
                                  (
                                    <span className="btn btn-primary btn-sm rounded-0" test={props.FormIndex} onClick={props.RemoveCamper(props.FormIndex)}><i className="icon-delete" /> Delete</span>
                                  )
                                  :''
                                }

                            </div>
                        </div>
                    </div>                    
                    <div className="col-lg-11">
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label>First Name</label>
                                <input type="text" className= {props.Errors.includes(`camper${props.FormIndex}firstName`) ? 'is-invalid form-control' :'form-control'}  name={`${fieldName}.firstName`} placeholder="First Name" ref={refprop} />
                                
                                
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Last Name</label>
                                <input type="text"  className={props.Errors.includes(`camper${props.FormIndex}lastName`) ? 'is-invalid form-control' :'form-control'} name={`${fieldName}.lastName`} placeholder="Last Name" ref={refprop} />
                               
                            </div>


                            <div className="col-md-6 form-group">
                            <label>Date of Birth </label>
                                

                                <Controller
                                    as={DatePicker}
                                    control={props.Control}
                                    disabledDate={disabledDate}
                                    valueName="selected" // DateSelect value's name is selected
                                    name={`${fieldName}.dob`}
                                    className= {props.Errors.includes(`camper${props.FormIndex}dob`) ? 'is-invalid form-control' :'form-control'}
                                    placeholderText="Select date"
                                    ref={refprop}
                                    format={dateFormatNew}
                                    />
                            
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Select Current Grade As of Today</label>
                            <select name={`${fieldName}.currentGrade`}  ref={refprop}  className= {props.Errors.includes(`camper${props.FormIndex}currentGrade`) ? 'is-invalid form-control' :'form-control'}>
                                <option value="">Select Grade</option>
                                {gradeArr.map(value => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                ))}
                                </select>
                            </div>


                             
                            <div className="col-md-12 mb-3 d-flex cm_gendr">
                            
                                <label>Sex</label>
                                <ul className="d-flex">
                                    <li className="mr-2 check_box_wrapper">
                                        <input ref={refprop} type="radio" onChange={handelGender} value="1"  name={`${fieldName}.gender`} checked={programGender===1 ? 'checked': ''} />
                                        <span className="check_box"><span /> Male</span> 
                                    </li>
                                    <li className="mr-2 check_box_wrapper">
                                        <input ref={refprop} type="radio" onChange={handelGender} value="2"  name={`${fieldName}.gender`} checked={programGender===2 ? 'checked': ''}  />
                                        <span className="check_box"><span /> Female</span> 
                                    </li>
                                    {/* <li className="mr-2 check_box_wrapper">
                                        <input ref={refprop} type="radio" onChange={handelGender} value="3"  name={`${fieldName}.gender`} checked={programGender===3 ? 'checked': ''} />
                                        <span className="check_box"><span /> Non-binary</span> 
                                    </li> */}
                                </ul>

                                {props.Errors.includes(`camper${props.FormIndex}gender`) ? <span className="cm_err_msg">Gender is Required</span> :''}
                                
                            </div>



                            <div className="col-md-6 form-group">
                                <label>Street Address</label>
                                <input ref={refprop} type="text" className= {props.Errors.includes(`camper${props.FormIndex}address`) ? 'is-invalid form-control' :'form-control'} name={`${fieldName}.address`} placeholder="Street Address" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Apt./Suite/Unit [optional]</label>
                                <input ref={refprop} type="text" className= {props.Errors.includes(`camper${props.FormIndex}apartment`) ? 'is-invalid form-control' :'form-control'} name={`${fieldName}.apartment`} placeholder="Apt./Suite/Unit [optional]" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>City</label>
                                <input ref={refprop} type="text" className= {props.Errors.includes(`camper${props.FormIndex}city`) ? 'is-invalid form-control' :'form-control'} name={`${fieldName}.city`} placeholder="City" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Zip Code</label>
                                <input ref={refprop} type="text" className= {props.Errors.includes(`camper${props.FormIndex}zipCode`) ? 'is-invalid form-control' :'form-control'}  name={`${fieldName}.zipCode`} placeholder="Zip Code" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>State/Province</label>
                                <input ref={refprop} type="text" className= {props.Errors.includes(`camper${props.FormIndex}state`) ? 'is-invalid form-control' :'form-control'}  name={`${fieldName}.state`} placeholder="State/Province" />
                            </div>
                            

                            <div className="col-sm-12" />
                            

                            <div className="col-md-6 form-group">
                                <label>T-shirt Size</label>
                                <select name={`${fieldName}.tshirtSize`}  ref={refprop}  className= {props.Errors.includes(`camper${props.FormIndex}tshirtSize`) ? 'is-invalid form-control' :'form-control'}>
                                 <option value="">Select T-shirt Size</option>   
                                {shortsAndTshirtSizes.map(value => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                ))}
                                </select>
                               
                                
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Shorts Size</label>
                                <select name={`${fieldName}.shortsSize`}  ref={refprop}  className= {props.Errors.includes(`camper${props.FormIndex}shortsSize`) ? 'is-invalid form-control' :'form-control'}>
                                <option value="">Select Shorts Size</option>   
                                {shortsAndTshirtSizes.map(value => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                ))}
                                </select> 
                            </div>

                            <div className="col-md-6 form-group">
                                <label>Medical</label>
                                <MultiselectDropdownCommon
                                    className={props.Errors.includes(`camper${props.FormIndex}medical`) ? 'is-invalid form-control' :'form-control'}
                                    Multiselectvalues={medical}
                                    Ref={refprop}
                                    Fieldname={`${fieldName}.medical`}
                                    PlaceHolder="Select Medical"
                                    RequiredError={props.Errors.includes(`camper${props.FormIndex}medical`) ? true : false}/>
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Dietary Guidelines</label>
                                <MultiselectDropdownCommon
                                    Multiselectvalues={dietGuideLines}
                                    Ref={refprop}
                                    handelDietReligious={handelDietReligious} 
                                    Fieldname={`${fieldName}.dietaryGuidelines`}
                                    PlaceHolder="Select Dietary Guidelines"
                                    RequiredError={props.Errors.includes(`camper${props.FormIndex}dietaryGuidelines`) ? true : false}/>
                            </div>


                            <div className="col-md-12 mb-3 d-flex cm_gendr">
                                <label>Diet is Religious</label>
                                
                                <ul className="d-flex">
                                    <li className="mr-2 check_box_wrapper">
                                        <input type="radio"  ref={refprop} value={1} name={`${fieldName}.dietReligious`} onChange={()=>{setDietisreligious(1)}} checked={dietisreligious===1 ? 'checked':'' } />
                                        <span className="check_box"><span /> Yes</span> 
                                    </li>
                                    <li className="mr-2 check_box_wrapper">
                                        <input type="radio" ref={refprop} value={2}  name={`${fieldName}.dietReligious`} onChange={()=>{setDietisreligious(0)}} checked={dietisreligious===0 ? 'checked':'' }/>
                                        <span className="check_box"><span /> No</span> 
                                    </li>
                                </ul>
                                {props.Errors.includes(`camper${props.FormIndex}dietReligious`) ? <span className="cm_err_msg">Field is required</span> :''}
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Allergy</label>
                                <MultiselectDropdownCommon handelAllergydeadly={handelAllergydeadly} Multiselectvalues={allergy} Ref={refprop} Fieldname={`${fieldName}.allergy`} PlaceHolder="Select Allergy" RequiredError={props.Errors.includes(`camper${props.FormIndex}allergy`) ? true : false}/> 
                            </div>


                            <div className="col-md-12 mb-3 d-flex cm_gendr">
                            {props.Errors.includes(`camper${props.FormIndex}allergyDeadly`) ? <span className="cm_err_msg">Field is required</span> :''}
                                <label>Allergy is deadly</label>
                                <ul className="d-flex">
                                    <li className="mr-2 check_box_wrapper">
                                        <input type="radio" ref={refprop} value={1}  name={`${fieldName}.allergyDeadly`}  onChange={()=>{setAllergyisDeadly(1)}} checked={allergyisDeadly===1 ? 'checked':'' }/>
                                        <span className="check_box"><span /> Yes</span> 
                                    </li>
                                    <li className="mr-2 check_box_wrapper">
                                        <input type="radio" ref={refprop} value={2}  name={`${fieldName}.allergyDeadly`} onChange={()=>{setAllergyisDeadly(2)}} checked={allergyisDeadly===2 ? 'checked':'' }/>
                                        <span className="check_box"><span /> No</span> 
                                    </li>
                                </ul>

                            </div>
                            
                            
                            <div className="col-sm-12 mb-3">
                                <h6>Insurance Information:</h6>
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Medical Insurance Company</label>
                                <input type="text" ref={refprop} className= {props.Errors.includes(`camper${props.FormIndex}medicalInsuranceCompany`) ? 'is-invalid form-control' :'form-control'} name={`${fieldName}.medicalInsuranceCompany`} placeholder="Medical Insurance Company" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Policy Number</label>
                                <input type="text" ref={refprop} className= {props.Errors.includes(`camper${props.FormIndex}policyNumber`) ? 'is-invalid form-control' :'form-control'}   name={`${fieldName}.policyNumber`}  placeholder="Policy Number" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Group #</label>
                                <input type="text" ref={refprop} className= {props.Errors.includes(`camper${props.FormIndex}group`) ? 'is-invalid form-control' :'form-control'} name={`${fieldName}.group`} placeholder="Group #" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Primary Physician</label>
                                <input type="text" ref={refprop} className= {props.Errors.includes(`camper${props.FormIndex}primaryPhysician`) ? 'is-invalid form-control' :'form-control'} name={`${fieldName}.primaryPhysician`} placeholder="Primary Physician" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Physician Address</label>
                                <input type="text" ref={refprop} className= {props.Errors.includes(`camper${props.FormIndex}physicianAddress`) ? 'is-invalid form-control' :'form-control'}  name={`${fieldName}.physicianAddress`}  placeholder="Physician Address" />
                            </div>


                            <div className="col-md-6 form-group">
                                <label>Physician Phone #</label>
                                <div className="cm_phone_field">
                                    <img className="us_flag" src={require('../../../assets/img/united-states-flag-icon.svg')} />
                                    <PhoneInput
                                        country="US"
                                        className= {props.Errors.includes(`camper${props.FormIndex}physicianPhone`) ? 'is-invalid form-control' :'form-control'}
                                        value={physicianPhone}
                                        onChange={(e)=>{setPhysicianPhone(e)}}
                                        name={`${fieldName}.phone`}
                                        maxLength={16}
                                        />
                                </div>
                                <input type="hidden" ref={refprop}   name={`${fieldName}.physicianPhone`} value={physicianPhone}/>
                                {/* <input type="text" className= {props.Errors.includes(`camper${props.FormIndex}physicianPhone`) ? 'is-invalid form-control' :'form-control'}  ref={refprop}   name={`${fieldName}.physicianPhone`} placeholder="Physician Phone #" /> */}
                            </div>

                            <div className="col-md-12 form-group">
                                <label>Please list any medications taken(please include dosage & timing) or any other special assistance necessary to attend a program. (Leave blank if none)</label>
                                <textarea className= {props.Errors.includes(`camper${props.FormIndex}medicationsTaken`) ? 'is-invalid form-control' :'form-control'} ref={refprop} name={`${fieldName}.medicationsTaken`}  placeholder="Write something here…" />
                            </div>


                            <div className="col-md-12 mb-3 cm_gendr">
                             
                                <label> Service Provider Staff may document participant with digital and film photography and/or video.</label>
                                <ul className="d-flex">
                                    <li className="mr-2 check_box_wrapper">
                                        <input type="radio" ref={refprop}   value={1} onChange={()=>{setPhotography(1)}} checked={photography===1 ? 'checked':'' } name={`${fieldName}.allowDocumentCamper`} />
                                        <span className="check_box"><span /> Yes</span> 
                                    </li>
                                    <li className="mr-2 check_box_wrapper">
                                        <input type="radio"  ref={refprop} value={2} onChange={()=>{setPhotography(2)}} checked={photography===2 ? 'checked':'' }  name={`${fieldName}.allowDocumentCamper`} />
                                        <span className="check_box"><span /> No</span> 
                                    </li>
                                </ul>
                                <div className="position-relative pt-2">
                                {props.Errors.includes(`camper${props.FormIndex}allowDocumentCamper`) ? <span className="cm_err_msg">Field is Required</span> :''} 
                                </div>

                                
                            </div>

                            <div className="col-md-12 form-group">
                                <label>Please attach any accompanying documents that you feel are necessary for attendance (Additional Medical document etc).</label>
                                <div  className="attach_file_btn">Attach PDF <input value="" type="file" accept="application/pdf" onChange={(e)=>{uploadHippaCompliance(e,'medical')}} /></div>

                                {
                                    medicalform ? <div className="cm_pdf_icon"><PDFIcon /><i className="icon-cross" onClick={() => setMedicalForm()} /> <p>{medicalform ? medicalform.slice(medicalform.lastIndexOf('_') + 1) : ''}</p></div> : ''  
                                }
                                {
                                    medicalform && <input ref={refprop} value={medicalform} type="hidden" name={`${fieldName}.medicalDocument`} />
                                }                                
                            </div>

                            <div className="col-md-12 form-group">
                                <label>HIPPA Compliances Consent Form. <a className="cm_d_btn" href={require('../../../assets/pdf/HIPAAComplianceConsentForm.pdf')} download><i className="icon-download-page" /> Download Form</a> </label>
                                <div className="attach_file_btn">Attach PDF <input value="" type="file" onChange={(e)=>{uploadHippaCompliance(e,'hippa')}} accept="application/pdf" /></div>
                    
                                {
                                    hippaform ? <div className="cm_pdf_icon"><PDFIcon /><i className="icon-cross" onClick={() => setHippaForm()} /> <p>{hippaform ? hippaform.slice(hippaform.lastIndexOf('_') + 1) : ''}</p></div> : ''  
                                }
                                {hippaform && <input ref={refprop} type="hidden" value={hippaform} name={`${fieldName}.hippaDocument`} />}
                            </div>  



                        </div>
                    </div>

               {/* <div className="col-md-12 form-group">
              
                 
                    {
                         props.preList.category.map(item => (
                            <OverlayTrigger
                            key={item._id}
                            delay={{ show: 10, hide: 200 }}
                            overlay={<Tooltip className="dark_tooltip">{item.name}</Tooltip>}>
                            <div key={item._id} className="cat_wrap">
                                        <input
                                            type="checkbox"
                                            value={item._id}
                                            ref={refprop}
                                            onChange={() => handleCategory(item)}
                                        />
                                            <div className="cat_bg">
                                                <img src={item.icon} />
                                            </div>
                                    </div>
                                </OverlayTrigger>
                         ))
                    }

                    <div className="position-relative pt-2">
                    {props.Errors.includes(`camper${props.FormIndex}activityInterest`) ? <span className="cm_err_msg">Category is Required</span> :''} 
                    </div>

                    {
                        subcatlisting.map(item => (
                            <div className="sub_cat_wrap">
                                <h5 className="cat_title"> { item.categoryname } </h5>
                                <ul>{item.catlist.map(catlistitem => (
                                    <li key={catlistitem._id}>
                                        <div className="check_box_wrapper">
                                            <input
                                                name={`${fieldName}.subcategory`}
                                                ref={refprop}
                                                type="checkbox"
                                                value={catlistitem._id}
                                                onChange={()=>{handelSubcategory(item.cat_id, catlistitem._id)}}
                                            />
                                            <span className="check_box">
                                                <span />  {catlistitem.name}
                                            </span>
                                        </div>
                                    </li>
                                    ))} 
                                </ul>       
                            </div>    
                        ))
                    } 

                </div>
                <input type="hidden"  name={`${fieldName}.activityInterest`} value={JSON.stringify(activityInterest)} ref={refprop}  /> */}
                <input type="hidden" name={`${fieldName}.camperId`}  ref={refprop} value={localStorage.getItem('NonLoginUserID')}/>

        {
        props.Additionalexp&& props.Additionalexp.length > 0 ?                                     
        <div className="col-md-12 form-group">
        <ul className="campers_add_exp">
            {
                props.Additionalexp.map(item => (
                   <AddtionalExpForNonLogin Addtonalexptocamper={props.Addtonalexptocamper} SelectCamper={props.FormIndex} Details={item} Additionalexp={props.Additionalexp} CamperAddtionalexp={props.CamperAddtionalexp} addAdditionalExp={props.addAdditionalExp}   Ref={refprop} Fieldname={`${fieldName}.AddtionalExprences`}/>
                ))
            }
        </ul>
        </div>
        :''
    }



{
    props.Questionsans && props.Questionsans.length >0 ?
    <div className="col-md-12 form-group">
        {
            props.Questionsans.map((ques,i)=>(
                <div className="col-sm-12 mb-3 form-group">
                <label>Q.{i+1} {ques.question}</label>
                <textarea className="form-control" name="" />
                </div>
            ))     
        }
    </div>  
    :''
}




                </div>



    )
}
const mapStateToProps = state => {
    let { preList, fSubcategory} = state.program;
    let userID =false;

    if(state.user.userProfile!== undefined)
    {
        userID = state.user.userProfile._id;
    }
    
    return{
        preList,
        fSubcategory,
        userID
    }
}

export default connect(mapStateToProps, null)(CamperFormFileds);