import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
    fSubCategoriesAction,
    fEzProEatingAction,
    fMaxDistanceAction,
    fLatLongAction,
    fFilterTypeAction,
    clearSingleFitlerAction
} from '../../store/pFilter';
import { filterProgramsAction } from '../../store/program';


const Filters = props => {

    const [expend, setExpend] = useState(false);

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            props.fLatLongAction([position.coords.latitude, position.coords.longitude]);
        })
    }


    const handleMaxDistance = e => {

        if(e.target.checked){
            props.fFilterTypeAction(1);
            props.fMaxDistanceAction(parseInt(e.target.value))
        }else{
            props.fFilterTypeAction('');
            props.fMaxDistanceAction('')
        }
    }


    const handleSubCategory = e => {
        if(props.subCategories.includes(e)){
            let subCatArr = props.subCategories.filter(item => item !== e);
            props.fSubCategoriesAction(subCatArr)
        }else{
            let subCatArr = [...props.subCategories, e];
            props.fSubCategoriesAction(subCatArr);
        }

        
    }


    const handleRatingChange = e => {
        if(props.ezProRating.includes(parseInt(e.target.value))){
            let temp = props.ezProRating.filter(item => item !== parseInt(e.target.value));
            props.fEzProEatingAction(temp);
        }else{
            let temp = [...props.ezProRating];
            temp.push(parseInt(e.target.value));
            props.fEzProEatingAction(temp)
        } 
        
    }


    



    return(
        <div className="side_silters mb-5">
            <h3>Filter</h3>

            {
                props.fSubcategory.length ? <div className="filter_box">
                <h4>Sub Category</h4>
                {props.subCategories ? props.subCategories.length ? <i className="icon-cross clear_checkboxs" title="Clear sub categories" onClick={() => props.clearSingleFitlerAction('subCategories')} /> : '' : ''}
                
                    {
                        props.fSubcategory.slice(0, expend ?  props.fSubcategory.length : 5).map(item => (
                            <React.Fragment>
                            <h5 className="cat_title"> { item.categoryname } </h5>
                            <ul>
                            {
                            item.catlist.map(catlistitem => (
                                <li key={catlistitem._id}>
                                <div className="check_box_wrapper">
                                    <input
                                        type="checkbox"
                                        value={catlistitem._id}
                                        checked={props.subCategories.includes(catlistitem._id)}
                                        onChange={() => handleSubCategory(catlistitem._id)}
                                    />
                                    <span className="check_box">
                                        <span />  {catlistitem.name}
                                    </span>
                                </div>
                            </li>
                            ))} 


                            </ul>       
                        </React.Fragment>    
                        ))
                    } 
                
                    {props.fSubcategory.length > 5 ? <span className="cm_seemore" onClick={() =>setExpend(!expend)}>See {expend ? 'less' : 'more'}</span> : ''}
                
            </div> : ''
            }
            

            



        {
        /* 
            <div className="filter_box">
                <h4>Distance from your location</h4>
                { props.maxDistance ? <i className="icon-cross clear_checkboxs" title="Clear distance" onClick={() => props.clearSingleFitlerAction('maxDistance')} /> : ''}
                <ul>
                    {
                        ratings.map(item => (
                            <li key={item}>
                                <div className="check_box_wrapper">
                                    <input
                                        checked={props.maxDistance === item}
                                        type="checkbox"
                                        onChange={handleMaxDistance}
                                        value={item}
                                        name={item}
                                    />
                                    <span className="check_box">
                                        <span />  Less than {item} mi
                                    </span>
                                </div>
                                <span className="items_val">20</span>
                            </li>
                        ))
                    } 
                </ul>
            </div>
        */
        } 



            <div className="filter_box">
                <h4>Star Rating</h4>
                <ul>
                    {
                        ratings.map(item => (
                            <li key={item}>

                                <div className="check_box_wrapper">
                                    <input
                                        type="checkbox"
                                        value={item}
                                        //checked={props?.ezProRating?.includes(item) ? true : false}
                                        onChange={handleRatingChange}
                                    />
                                    <span className="check_box">
                                        <span /> {item} Star
                                    </span>
                                </div>
                                <span className="items_val">20</span>
                            </li>
                        ))
                    }
                </ul>
            </div>




            



        </div>
    )
}




/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { preList, fSubcategory } = state.program;
    let {
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        city,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,
        lat,
        long,
        pageNo,
        limit,
        weekDays,
        subCategories,
        maxDistance,
        isOnline
    } = state.pFilter;
    let { subcategory } = preList;

    return {
        subcategory,
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        city,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,
        lat,
        long,
        pageNo,
        limit,
        weekDays,
        subCategories,
        fSubcategory,
        maxDistance,
        isOnline 
    };
}
  
const mapDispatchToProps = dispatch => ({
    fSubCategoriesAction:           params => dispatch(fSubCategoriesAction(params)),
    fEzProEatingAction:             params => dispatch(fEzProEatingAction(params)),
    filterProgramsAction:           params => dispatch(filterProgramsAction(params)),
    fMaxDistanceAction:             params => dispatch(fMaxDistanceAction(params)),
    fLatLongAction:                 params => dispatch(fLatLongAction(params)),
    clearSingleFitlerAction:        params => dispatch(clearSingleFitlerAction(params)),
    fFilterTypeAction:              params => dispatch(fFilterTypeAction(params)),
    
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Filters);


const ratings = [1,2,3,4,5]