import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { toggleResetPasswordModal } from '../../store/ui';
import { getAllProgramListAction } from '../../store/home';
import { fFilterTypeAction } from '../../store/pFilter';

import MainSlider from '../reuse/MainSlider';
import CampTypeCarousel from '../carousels/CampTypeCarousel';
import CampsCarousel from '../carousels/CampsCarousel';
import StartingSoonCarousel from '../carousels/StartingSoonCarousel';
import MainFilter from './MainFilter';
import { ProgramLoader } from '../reuse/SVG';
import { getAdvertisementService } from '../../services/otherService';
import NearByCarousel from '../carousels/NearByCarousel';
import ChessForSuccessColor from '../../assets/img/district/chess-for-success-color.png';
import RewardPoints from '../../assets/img/rewardPoints.png';
import AddsIcon from '../../assets/img/adds-icon.png';
import sportInternationalImage from '../../assets/img/sport-international.png';


const Home = props => {
    const history = useHistory();
    const[loader, setLoader] = useState(true);
    const[advList, setAdvList] = useState([]);

    useEffect(() => {
        let params = `?lat=${props.lat}&long=${props.long}`;
        props.getAllProgramListAction(params).then(res => {
            setLoader(false)
        });
    }, [props.lat])

    useEffect(() => {
        let query = `?pageName=home`
        getAdvertisementService(query).then(res => {
            if(res?.data?.statusCode){
                setAdvList(res.data.responseData.result) 
            }
        })

        if(props.isResetPassword){
            props.toggleResetPasswordModal();
        }
        
    }, [])

    const handleViewAll = text => {

        if(text === 'Featured Programs'){
            props.fFilterTypeAction(3);
        }

        if(text === 'Popular Programs'){
            props.fFilterTypeAction(0);
        }
        history.push('/camps');
    }

    return(
        <div className="body_container">
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="main_banner">
                <MainSlider />
                <div className="main_filter_wrapper">
                    <div className="container h-100">
                        <div className="row no-gutters align-content-center d-flex h-100">
                            <div className="col-xl-5 col-md-6">
                                <MainFilter isHome={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        

            <div className="container premier_partners">
                <h3 className='mt-5 mb-4'>Premier Partners:</h3>
                <Link to="/district-camps">
                    <img className='img-fluid' src={ChessForSuccessColor} />
                </Link>

                <Link to="/sports-international-camps">
                    <img className='img-fluid' src={sportInternationalImage} />
                </Link>
            </div>


            <div className="container off_bg">
                <div className="row d-flex align-items-center">
                    <div className="order-lg-2 col-lg-6">
                        <img src={RewardPoints} />
                    </div>
                    <div className="order-lg-1 col-lg-6 p-5 mt-4">
                        {/* <img className="off_img" src={require('../../assets/img/off-img.png')} /> */}
                        <h3>Introducing rewards points! </h3>
                        <p>Collect points with the purchase of any program and redeem them on future purchases.</p>
                        {/* <h4>Collect 5 Camps and Get 10% off on next camp</h4> */}
                    </div>
                    
                </div>
            </div>

    
            <CampTypeCarousel />

            
            <div className="camps_carousel_wrap cristal_bg pt-3">
                <CampsCarousel
                    isFeatured={true}
                    list={props.featured}
                    handleViewAll={handleViewAll}
                    title="Featured Programs" />
            </div>
    
            <div className="camps_carousel_wrap mt-5">
                <CampsCarousel
                    list={props.popularCamps}
                    handleViewAll={handleViewAll}
                    title="Popular Programs" />
            </div>

            {props.startingSoon ? <StartingSoonCarousel list={props.startingSoon} /> : ''}
            {props.nearbyCamps ? <NearByCarousel list={props.nearbyCamps} /> : ''}
            

            



            <div className="cm_adds_wrapper mb-5">
                <div className="container text-center">
                   <div className="row">
                       {
                           advList.slice(0, 3).map(item => (
                            <div className="col-lg-4">
                                <a href={item.link} target="_blank">
                                <div className="adds_card">
                                    <img src={AddsIcon} className="adds_icon" />
                                    <img src={item.image} className="img-fluid advert_1" />
                                    <div className="adds_title">
                                        <h4>{item.tagline}</h4>
                                    </div>
                                </div>
                                </a>
                            </div>
                           ))
                       }
                    

                   </div>
                </div>
            </div>

            
        </div>   
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { allProgram } = state.home;
    let { lat, long } = state.pFilter;
    let { popularCamps, startingSoon, featured } = allProgram;
    return {
        popularCamps,
        startingSoon,
        featured,
        lat,
        long
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleResetPasswordModal: () => dispatch(toggleResetPasswordModal()),
    getAllProgramListAction: params => dispatch(getAllProgramListAction(params)),
    fFilterTypeAction: params => dispatch(fFilterTypeAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Home);