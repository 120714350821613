import React, { Fragment } from 'react'

const GroupList = ({ heading, listItems }) => {
    return (
        <Fragment>
            <div className="text-center">
                <h6>{heading}</h6>
            </div>
            <ul>{
                    listItems.map((item) =>  <li>{item}</li>)
                }
            </ul>
        </Fragment>
    )
}

export default GroupList
