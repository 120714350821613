import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';
import { notification } from 'antd';
import isEmail from 'validator/lib/isEmail';

import { getPageDataService, contactUsService } from '../../services/otherService';
import { ProgramLoader } from '../reuse/SVG';
import { checkStringContainsNumbers,  } from '../../helper';
import ContactBg from '../../assets/img/contact-bg.png';


const AboutUs = () => {
    const [loader, setLoader] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [nameErr, setNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [phoneErr, setPhoneErr] = useState('');
    const [messageErr, setMessageErr] = useState('');
    const [resMsg, setResMsg] = useState('');

    const [list, setList] = useState([]);


    const getPageData = () => {
        let query = `?page=about`;

        getPageDataService(query).then(res => {
            setLoader(false);
            if (res.data.statusCode);
            setList(res.data.responseData.result)
        })
    }

    useEffect(() => {
        getPageData();
    }, []);



    const handleValidate = () => {
        let validate = true;

        if (name === '') {
            setNameErr('Name is required');
            validate = false
        }else if(name.trim() === ''){
            setNameErr('Name is required');
            validate = false 
        }else if(checkStringContainsNumbers(name)){
            setNameErr('Invalid name');
            validate = false 
        }

        if (email === '') {
            setEmailErr('Email is required');
            validate = false;
        }else if (!isEmail(email)) {
            setEmailErr('Email is not valid');
            validate = false
        }else if(email.trim() === ''){
            setEmailErr('Email is required');
            validate = false;
        }

        if (phone === '' || phone === null) {
            setPhoneErr('Phone is required');
            validate = false
        }else if(phone.trim() === ''){
            setPhoneErr('Phone is required');
            validate = false 
        }

        if (message === '') {
            setMessageErr('Message is required');
            validate = false
        }else if(message.trim() === ''){
            setMessageErr('Message is required');
            validate = false
        }

        return validate;
    }




    const handleSubmit = e => {
        e.preventDefault();

        if (handleValidate()) {
            setLoader(true);
            let params = {
                name,
                phone,
                email,
                message
            }

            contactUsService(params).then(res => {
                setLoader(false);
                if (res.data.statusCode) {
                    setName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                    setResMsg(res?.data?.responseData?.message)
                    notification.open({
                        description: res?.data?.responseData?.message,
                    });
                }
            })
        }

    }



    return (
        <div className="body_container">
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}

            {
                list.length ? list.filter(item => item.type === 'aboutSection1').map(i => (
                    <div className="cm_banner_wrapper" key={i._id}>
                        <img src={i.image} />
                        <div className="cm_banner_text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2>{i.title}</h2>
                                        <p className="line_h_2">{i.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ''
            }




            {
                list.length ? list.filter(item => item.type === 'aboutSection2').map(i => (
                    <div className="cm_section" key={i._id}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <h2 className="section_title_left">{i.title}</h2>
                                    <p className="line_h_2">{i.content}</p>

                                    <ul className="cm_counts">

                                        {
                                            list.length ? list.filter(item => item.type === 'aboutSection3').map(i => (
                                                <li key={i._id}>
                                                    <img src={require('../../assets/img/campers.svg')} />
                                                    <h3>{i.title}</h3>
                                                    <h5>{i.content}</h5>
                                                </li>
                                            )) : ''
                                        }

                                        {
                                            list.length ? list.filter(item => item.type === 'aboutSection4').map(i => (
                                                <li key={i._id}>
                                                    <img src={require('../../assets/img/unique-activities.svg')} />
                                                    <h3>{i.title}</h3>
                                                    <h5>{i.content}</h5>
                                                </li>
                                            )) : ''
                                        }

                                        {
                                            list.length ? list.filter(item => item.type === 'aboutSection5').map(i => (
                                                <li key={i._id}>
                                                    <img src={require('../../assets/img/nature.svg')} />
                                                    <h3>{i.title}</h3>
                                                    <h5>{i.content}</h5>
                                                </li>
                                            )) : ''
                                        }

                                        {
                                            list.length ? list.filter(item => item.type === 'aboutSection6').map(i => (
                                                <li key={i._id}>
                                                    <img src={require('../../assets/img/baseball.svg')} />
                                                    <h3>{i.title}</h3>
                                                    <h5>{i.content}</h5>
                                                </li>
                                            )) : ''
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <img className="about_img" src={i.image} />
                    </div>
                )) : ''
            }










            <div className="cm_section ball_right" style={{ backgroundImage: `url(${require('../../assets/img/dash-line.svg')})` }}>
                <div className="container">
                    <h2 className="section_title_center ">Why Choose Us</h2>

                    <div className="row">

                        {
                            list.length ? list.filter(item => item.type === 'aboutSection7').map(i => (
                                <div className="col-md-4" key={i._id}>
                                    <div className="shadow_card mt-5">
                                        <span className="card_num">01</span>
                                        <h5>{i.title}</h5>
                                        <p>{i.content}</p>
                                    </div>
                                </div>
                            )) : ''
                        }

                        {
                            list.length ? list.filter(item => item.type === 'aboutSection8').map(i => (
                                <div className="col-md-4" key={i._id}>
                                    <div className="shadow_card mt-5">
                                        <span className="card_num">02</span>
                                        <h5>{i.title}</h5>
                                        <p>{i.content}</p>
                                    </div>
                                </div>
                            )) : ''
                        }

                        {
                            list.length ? list.filter(item => item.type === 'aboutSection9').map(i => (
                                <div className="col-md-4" key={i._id}>
                                    <div className="shadow_card mt-5">
                                        <span className="card_num">03</span>
                                        <h5>{i.title}</h5>
                                        <p>{i.content}</p>
                                    </div>
                                </div>
                            )) : ''
                        }

                    </div>
                </div>
            </div>







            <div className="contact_us_wrapper">
                <div className="header">
                    <div className="container">
                        <h2><i className="icon-phone-big" /> Hotline for Parents: <span> +1 (971)-245-3061</span></h2>
                    </div>
                </div>

                <div className="row no-gutters">
                    <div className="col-lg-6 contact_bg" style={{ backgroundImage: `url(${ContactBg})` }}>

                    </div>
                    <div className="col-lg-6 partner-form">
                        <form onSubmit={handleSubmit}>
                            <h3>Contact us</h3>

                            <div className="form-group">
                                <input
                                    type="text"
                                    value={name}
                                    onChange={e => (setName(e.target.value), setNameErr(''), setResMsg(''))}
                                    className={`form-control form-control-lg ${nameErr ? 'is-invalid' : ''}`}
                                    placeholder="Name" />

                                {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="form-group">
                                <input
                                    type="email"
                                    className={`form-control form-control-lg ${emailErr ? 'is-invalid' : ''}`}
                                    value={email}
                                    onChange={e => (setEmail(e.target.value), setEmailErr(''), setResMsg(''))}
                                    placeholder="Email" />

                                {emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>

                            <div className="form-group">
                                <PhoneInput
                                    country="US"
                                    placeholder="Phone Number"
                                    className={`form-control form-control-lg ${phoneErr ? 'is-invalid' : ''}`}
                                    onChange={e => (setPhone(e), setPhoneErr(''), setResMsg(''))}
                                    value={phone}
                                    maxLength={16}
                                />

                                {phoneErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{phoneErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="form-group">
                                <textarea
                                    rows="3"
                                    value={message}
                                    onChange={e => (setMessage(e.target.value), setMessageErr(''), setResMsg(''))}
                                    className={`form-control form-control-lg ${messageErr ? 'is-invalid' : ''}`}
                                    placeholder="Comment" />
                                {messageErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{messageErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>



                            <div className="text-center">
                                <button className="btn btn-primary">Submit</button>
                            </div>

                            {resMsg ? <h6 className='res_h6'>{resMsg}</h6> : ''}
                        </form>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AboutUs;

