import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { convertTimeToCurrentTimeZone, removeHtmlTags } from '../../helper'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

const UserCard = ({ item, findIsStarred, toggleStar, userId, timezone }) => {
    return (
        <Fragment>
            <img className="chat_user_img" src={item.userId[0].profileImage ? item.userId[0].profileImage : require('../../assets/img/user.png')} alt="" />
            <Container>
                <Row className='d-flex flex-row justify-content-between'>
                    <Col style={{ width: '20px' }}>
                        <span
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '75px', whiteSpace: 'nowrap', overflow: 'hidden' }}
                        >
                            {item.userId[0].name}
                        </span>
                    </Col>
                    {(!item?.scheduleDateTime) &&
                        <Col>
                            <span
                                onClick={(e) => toggleStar(e, item)} style={{ cursor: 'pointer' }}>
                                {findIsStarred(item, userId) ? <AiFillStar color="#841f15" size={24} /> : <AiOutlineStar size={24} />}
                            </span>
                            {item.count ? <span className="msg_count mr-3">{item.count}</span> : <span></span>}
                        </Col>
                    }
                </Row>
                {(item?.scheduleDateTime) &&
                    <Row className='ml-1'>
                        <small>
                            Schedule date : 
                            <time>
                                <i> {convertTimeToCurrentTimeZone(item.scheduleDateTime, timezone, 'MMM Do, hh:mm a')}</i>
                            </time>
                        </small>

                    </Row>
                }
                <Row className='ml-1'>
                    <small>{removeHtmlTags(item.lastMessage)}</small>
                </Row>
            </Container>
        </Fragment>
    )
}

export default UserCard
