import React, { useEffect, useState, Fragment } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import {camperListSelectCamperScreen, getCartItems } from '../../../services/programService';
import { ProgramLoader } from '../../reuse/SVG';
import { getgendersdisplay, getHtml, _calculateAccureateAge } from '../../../helper';
import CurrentParticipentComponent from './CurrentParticipentComponent';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';


const checkCamperIsCheck = (id, list) => {
    let isChecked = list.filter(i => i._id === id);
    if(isChecked.length){
        return true;
    }else{
        return false;
    }
}
// Existing problem : CamperList api is being called multiple times.
//Task 1:  for this component : Need to optimize the CamperList api call

const SelectCampers = props => {
    let location = useLocation();
    let history = useHistory();
    let numberofcampers = location.state.numbderofCampers;

    const [loader, setloader] = useState(true);
    const [camperList, setCamperList] = useState([]);
    const [selectedCampers, setSelectedCampers] = useState([]);
    const [currentCamper, setCurrentCamper] = useState('');
    const [cartProgramData, setCartProgramData] = useState({});
    const [errModal, setErrModal] = useState(false);
    const [ageErrModal, setAgeErrModal] = useState(false);

    
    const handleCamperCheck = item => {
        const age = _calculateAccureateAge(item.dob) // checking re-calculated age from D.O.B. 
        if(cartProgramData.minAge <= age && cartProgramData.maxAge >= age){
            setCurrentCamper(item);
        }else{
            setAgeErrModal(true)
        }
    }

    const getCartItemFun = () => {
        getCartItems().then(res => {
            if (res.data.statusCode) {
                let _cartProgramData = res.data.responseData.result.filter(item => item._id === location.state.cart_id)[0];
                let _isApplicable = _cartProgramData.isApplicable
                setCartProgramData(_cartProgramData);


                camperListSelectCamperScreen().then(res => {
                    setloader(false);
                    let usecamperList = res.data.responseData.result;

                    if(_isApplicable === 1){
                        if(props?.userProfile?.dob && props?.userProfile?.gender && (props?.userProfile?.phone || props?.userProfile?.phoneNumber) && props?.userProfile?.address){
                            usecamperList.push({
                                city: "",
                                dob: props?.userProfile?.dob,
                                age: props?.userProfile?.dob ? _calculateAccureateAge(props?.userProfile?.dob) : '',
                                firstName: props?.userProfile?.name,
                                gender: props?.userProfile?.gender,
                                grade: '',
                                lastName: '',
                                profileImage: props?.userProfile?.profileImage,
                                waiver: '',
                                waiverLiability: '',
                                address: props?.userProfile?.address,
                                phoneNumber: props?.userProfile?.phone || props?.userProfile?.phoneNumber,
                                _id: props?.userProfile?._id,
                                camperType: 2
                            }); 
                        }else{
                            localStorage.setItem('cart_store_data', JSON.stringify({...location.state, isFirstTimeCart: true}));
                            history.push('/profile/information');
                        }
                    }
                    setCamperList(usecamperList);
                })
            } 
        })
    }


    useEffect(() => {
        getCartItemFun();
    }, [])



    const removeInCamperList = id => {
        let _selectedCampers = [...selectedCampers];
        let index = _selectedCampers.indexOf(id);
        _selectedCampers.splice(index, 1);
        setSelectedCampers(_selectedCampers);
    }


    const checkCampersAction = () => {
        if(selectedCampers.length !== numberofcampers){
            setErrModal(true)
        }
    }




    const camperAddCallback = data => {

        let _selectedCampers = [...selectedCampers];
        _selectedCampers.push(data);
        setSelectedCampers(_selectedCampers);

        if (numberofcampers === 1) {

            const oldproductpre = localStorage.getItem('cartitemsloginuser') || "[]";
            const arrayproduct = JSON.parse(oldproductpre);
            let cartdata = {
                "cart_id": location.state.cart_id,
                "camperlist": _selectedCampers,
                "selectedcamperList": _selectedCampers[0]._id,
                "camplistid": _selectedCampers[0].camplistid,
                //"selectedcamperList": _selectedCampers,
                "additionalexp": cartProgramData.additionalExperience,
            }
            arrayproduct[0] = cartdata;
            localStorage.setItem('cartitemsloginuser', JSON.stringify(arrayproduct));


            history.push({
                pathname: '/confirmdetails',
                state: {
                    "numbderofCampers": numberofcampers,
                    "cart_id": location.state.cart_id,
                    "additionalexpadded": _selectedCampers[0].additionalExperience,
                    //"selectedcamperList": _selectedCampers,
                    "selectedcamperList": _selectedCampers[0]._id,
                    "camplistid": _selectedCampers[0].camplistid,
                }
            });

        }
        else {
            if (_selectedCampers.length === numberofcampers) {

                const oldproduct = localStorage.getItem('cartitemsloginuser') || "[]";
                const arrayproduct = JSON.parse(oldproduct);
                const oldcartlist = arrayproduct.filter(item => item.cart_id === location.state.cart_id);

                let _selectedCampersIds = _selectedCampers.map(i => i._id)
                if (oldcartlist.length) {
                    let index = arrayproduct.findIndex(cartitems => cartitems.cart_id === location.state.cart_id);

                
                    let cartdata = {
                        "cart_id": location.state.cart_id,
                        "camperlist": _selectedCampers,
                        "selectedcamperList": _selectedCampersIds,
                        "additionalexp": cartProgramData.additionalExperience
                    }

                    arrayproduct[index] = cartdata;
                    localStorage.setItem('cartitemsloginuser', JSON.stringify(arrayproduct));
                }
                else {
                    let cartdata = {
                        "cart_id": location.state.cart_id,
                        "camperlist": _selectedCampers,
                        "selectedcamperList": _selectedCampersIds,
                        "additionalexp": cartProgramData.additionalExperience
                    }

                    arrayproduct.push(cartdata);
                }
                localStorage.setItem('cartitemsloginuser', JSON.stringify(arrayproduct));
                history.push({
                    pathname: '/confirmdetails', state: {
                        "numbderofCampers": numberofcampers,
                        "cart_id": location.state.cart_id,
                        "additionalexpadded": cartProgramData.additionalExperience,
                        "selectedcamperList": _selectedCampersIds
                    }
                });
        
                }else{
                    setCurrentCamper('')
                }
        }
    }


    return (
        <div className="body_container">
            {
                currentCamper._id ? (
                    <Fragment>
                        <CurrentParticipentComponent
                            closeSelectingView={() => setCurrentCamper('')}
                            camperAddCallback={camperAddCallback}
                            cartProgramData={cartProgramData}
                            currentCamper={currentCamper} />
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="container">

                            <div className="d-flex align-items-center justify-content-between section_header">
                                <h2>Participants</h2>
                                <div>
                                    <Link
                                        className="btn btn-primary rounded-0"
                                        to={{
                                                pathname: '/profile/participant',
                                                state: {
                                                    "addcampersfromcart": "Yes",
                                                    "numbderofCampers": location.state.numbderofCampers,
                                                    "cart_id": location.state.cart_id,
                                                    "redirectToForm": true,
                                                }
                                        }} >Add Participant</Link>
                                </div>
                            </div>





                            <div className="row">
                                <div className="col-lg-1" />
                                <div className="col-lg-10">
                                    {
                                        loader ? (<div className="program_loader"><ProgramLoader /></div>) : (
                                            <ul className="camper_list">
                                                {
                                                    camperList.length > 0 ? camperList.map((item, index) => (
                                                        <li key={item._id} className="d-flex align-items-center">
                                                            <img onClick={() => handleCamperCheck(item)} src={item.profileImage || require('../../../assets/img/camps-user.png')} />
                                                            <div>
                                                                <h3>{item.firstName}</h3>
                                                                <p><small>Age: {_calculateAccureateAge(item.dob)} &nbsp; &nbsp; &nbsp; Sex: {getgendersdisplay(item.gender)}</small></p>
                                                                {
                                                                    item.additionalExperience !== undefined ?
                                                                        <p>
                                                                            <small>Additional Experiences: </small>
                                                                            {item.additionalExperience.map(addtionexp => (<div><small>{getHtml(addtionexp.title)}</small></div>))}
                                                                        </p> : ''
                                                                }

                                                            </div>


                                                            {
                                                                checkCamperIsCheck(item._id, selectedCampers) ?
                                                                    <div className="check_box_wrapper ml-auto">
                                                                        <input
                                                                            type="checkbox"
                                                                            onClick={() => removeInCamperList(item._id)}
                                                                            value={item._id}
                                                                            checked={checkCamperIsCheck(item._id, selectedCampers) ? 'checked' : ''} />
                                                                        
                                                                        <span className="check_box"><span /></span>
                                                                    </div>
                                                                    :
                                                                    <div className="check_box_wrapper ml-auto">
                                                                        <input
                                                                        type="checkbox"
                                                                        onClick={() => handleCamperCheck(item)}
                                                                        checked={checkCamperIsCheck(item._id, selectedCampers) ? 'checked' : ''}
                                                                        value={item._id} />

                                                                        <span className="check_box"><span /></span>
                                                                    </div>
                                                            }
                                                        </li>
                                                    )) :
                                                        <li>You need to add participants first</li>
                                                }
                                            </ul>
                                        )
                                            
                                    }


                                    <div className="col-sm-12 text-center mb-5">
                                        <Link to="/cart" style={{ width: '130px' }} className="btn btn-light rounded-0 mr-2">Cancel</Link>

                                        {
                                            camperList.length > 0 ? <button onClick={checkCampersAction} className="btn btn-primary rounded-0" style={{ width: '130px' }}>Done</button>
                                                : ''
                                        }


                                    </div>

                                </div>
                            </div>
                            </div>



                            <Modal
                                dialogClassName="h_auto"
                                show={errModal}
                                animation={false}
                                centered
                            >
                                <Modal.Body>
                                    <div className="p-5">
                                        <h3 className="text-center mb-4">Error</h3>
                                        You need to Select {numberofcampers - selectedCampers.length} participant(s)
                                        <button onClick={() => setErrModal(false)} className="btn btn-primary btn-block mt-2">Ok</button>
                                    </div>
                                </Modal.Body>
                            </Modal>


                            <Modal
                                dialogClassName="h_auto"
                                show={ageErrModal}
                                animation={false}
                                centered
                            >
                                <Modal.Body>
                                    <div className="p-5">
                                        <h3 className="text-center mb-4">Error</h3>
                                        This program is not intended for participants of this age.
                                        <button onClick={() => setAgeErrModal(false)} className="btn btn-primary btn-block mt-2">Cancel</button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                    </Fragment>
                )
            }

                
            

        </div>
    )
}




const mapStateToProps = state => {
    let userProfile = state?.user?.userProfile;
    return {
        userProfile,
    };
}


export default connect(mapStateToProps)(SelectCampers);
