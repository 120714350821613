import ApiInstance from './intercepter';
import API from '../config/api';
import { resHandle } from '../helper';

ApiInstance.defaults.headers.common['Authorization'] = `${API.AUTH}`;


export const getTremsFeedList = params => ApiInstance.get(API.TERMS_FEED_LIST + params).then(res => {
    let { data } = resHandle(res);
    return data;
});


export const getfaqListService = params => ApiInstance.get(API.FAQ_LIST + params).then(res => {
    let { status, data } = resHandle(res);
    return data;
});

export const faqFeedbackService = params => ApiInstance.post(API.FAQ_FEEDBACK, params);


export const createFolderService = params => ApiInstance.post(API.CREATE_FOLDER, params);

export const becomePartnerService = params => ApiInstance.post(API.CREATE_BECOME_PARTNER, params);

export const getAdvertisementService = params => ApiInstance.get(API.GET_ADVERTISEMENT + params);

export const askQuestionService = params => ApiInstance.post(API.ASK_QUESTION, params);

export const postYourCampService = params => ApiInstance.post(API.LIST_YOUR_CAMP_REQUEST, params);

export const careerListService = params => ApiInstance.get(API.CAREER_LIST + params);

export const getPageDataService = params => ApiInstance.get(API.GET_STATIC_PAGES + params);

export const contactUsService = params => ApiInstance.post(API.CONTACT_US, params);

export const invoiceListService = params => ApiInstance.get(API.INVOIC_LIST + params);

export const crateCabinService = params => ApiInstance.post(API.CREATE_CABIN, params);

export const getCabinService = params => ApiInstance.get(API.GET_CABIN + params);

export const refundPolicyService = params => ApiInstance.post(API.REFUND_POLIICY, params);

export const newsListService = params => ApiInstance.get(API.NEWS_LIST + params);

export const newsDetailService = params => ApiInstance.get(API.NEWS_DETAIL + params);

export const billingDetailService = () => ApiInstance.get(API.BILLING_DETAILS);

export const getRefundPolicyService = params => ApiInstance.get(API.GET_REFUND_POLICY + params);

export const getCouponsListService = params => ApiInstance.post(API.GET_COUPONS_LIST + params);

export const addCouponService = params => ApiInstance.post(API.CREATE_COUPON, params);

export const couponStatusChange = params => ApiInstance.put(API.COUPON_STATUS_CHANGE, params);

export const couponDeleteService = params => ApiInstance.delete(API.COUPON_DELETE, {data: params});

export const userListService = params => ApiInstance.post(API.USER_LIST, params);

export const getUserDetailService = params => ApiInstance.get(API.USER_DETAIL + params);

export const createBookingByAdminService = params => ApiInstance.post(API.CREATE_BOOKING_BY_ADMINT, params);

export const calcAllPriceService = params => ApiInstance.post(API.CALC_ALL_PRICE, params);

export const getProgramsService = params => ApiInstance.get(API.GET_PROGRAMS_LIST + params);

export const productBookingsService = params => ApiInstance.get(API.PRODUCT_BOOKING_LIST + params);


export const getCityByGeoLocation = params => fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${params.lat},${params.long}&sensor=true&key=AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0`)
  .then(response => response.json())
  .then(res => {
    let { results } = res;
    let city;
    for (let i = 0; i < results.length; i++) {
        for (let j = 0; j < results[i].address_components.length; j++) {
            for (let k = 0; k < results[i].address_components[j].types.length; k++) {
                if (results[i].address_components[j].types[k] === "locality") {
                    city = results[i].address_components[j].long_name; 
                }
            }
        }
    }
    return city;
  });


