import React, { useEffect, useState, useReducer } from 'react';

import { connect } from 'react-redux';
import { getCampDetailService } from '../../services/userServices';
import { productBookingsService } from '../../services/otherService';
import { Empty, notification } from 'antd';
import { Link } from 'react-router-dom';
import PaginatedComponent from '../common/Pagination/PaginationComponent';
import { ProgramLoader } from '../reuse/SVG';
import { Modal } from 'react-bootstrap';
import { formatTimestamp } from '../../helper';
import Select from 'react-select';
import { adminBookingListForProductsService, itemPurchaseUpdateProductBooking } from '../../services/programService';
import { getProductDeailsService } from '../../services/onlineStoreService';
import { getColors2, getSizes } from '../online-store/OnlineStore';



const initialItemDetails = {
    itemId:'',
    purchaseId:''
  };
  const setItemDetails = (state, action) => {
    return { ...state, ...action.payload }
};
const ProductBookings = props => {
    const [loader, setLoader] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [campId, setCampId] = useState('');
    const [count, setCount] = useState(0);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [isEditmodal, setIsEditModal] = useState(false);
    const [bookingList, setBookingList] = useState([]); 
    const [selectedProgram, setSelectedProgram] = useState({});
    const [itemDetails, dispatch] = useReducer(setItemDetails, initialItemDetails);
    const [showProductEditDropDown, setProductEditDropDown] = useState(false);
    const [activeItem, setActiveItem]= useState({})
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [isSizeAvailable,setIsSizeAvailable] = useState(false)
    const [modalLoader, setModalLaoder] = useState(false)
    const [missingCamperError, setMissingCamperError] = useState('')

    const limit = 10;

      const handleToggleSubItem= (objId, ) => {
        if (selectedBookingId === objId) {
          setSelectedBookingId(null);
        } else {
          setSelectedBookingId(objId);
        }
      };

    const getBookingList = () => {
        setLoader(true)
        let query = `?search=${search}&page=${page - 1}&limit=${limit}&campCode=${props.userProfile.campCode}`;
        setLoader(true)
        setBookings([]);
        productBookingsService(query).then(res => {
            setLoader(false)
            if(res.data.statusCode){
                setBookings(res.data.responseData.result)
                setCount(res.data.responseData.totalCount);
            }
        })  
    }

    const getSelectedItem = (ItemId) => {
        setModalLaoder(true)
        const params = `/${ItemId}`
        getProductDeailsService(params).then(res => {
            if(res.data.statusCode){
                setActiveItem(res.data.responseData.result)
                const sizeArray = getSizes(res.data.responseData.result.sizes)
                setIsSizeAvailable(sizeArray?.length > 0 ? true : false)
                setModalLaoder(false)
            }
        }).catch(() => setModalLaoder(false)).finally(() => setModalLaoder(false))
    }
    const getCampDetails = () => {
        const query = `?campCode=${props.userProfile.campCode}`;
                getCampDetailService(query).then((res) => {
                    if(res.data.statusCode){
                        setProductEditDropDown(res.data.responseData.result?.showProductEditButton) 
                    }
                    setLoader(false)
                }).catch(e => console.log(e.message)).finally(() => setLoader(false))
    }


    useEffect(() => {
        getBookingList();
    }, [search, page, campId])

    useEffect(() => {
        getCampDetails();
    }, [])

  

    const editExistingDetailsOfProduct = (userId, bookingId, programId, itemId, purchaseId, camperId ) => {
        setIsEditModal(true)
        const params = `?userId=${userId}&campCode=${props.userProfile.campCode}`
        adminBookingListForProductsService(params).then((res) => {
            if(res.data.statusCode){
                const requiredBookingList = res.data.responseData.result.filter( (item) => {
                    if(bookingId === item?.bookingId && programId === item?.programId){
                        return true;
                    }else if(bookingId){
                        return bookingId === item?.bookingId
                    }else{
                        return false; 
                    }
                })
                dispatch({payload:{itemId, purchaseId}});
                setBookingList((requiredBookingList?.length > 0) ? requiredBookingList : res.data.responseData.result);
                setSelectedProgram((requiredBookingList?.length )? requiredBookingList.filter(item => item.camperId === camperId)[0]: {})
                setIsEditModal(true)
            }
        })
        getSelectedItem(itemId);

    }
    const handleColorSelect = (e) => {
        setSelectedSize(e)
        setSelectedColor('')
    }
    const SaveEditDetails = () => {
        if(!selectedProgram?.camperId){
            setMissingCamperError('Camper is not associated with this product. Please contact CampsEZ admin for support.')
            return 
        }
        let data = {
            purchaseId: itemDetails.purchaseId,
            itemId: itemDetails.itemId,
            bookingId: selectedProgram.bookingId,
            camperId: selectedProgram.camperId,
            programId: selectedProgram.programId,
            sizes:selectedSize.value,
            color:selectedColor.value
          }
        itemPurchaseUpdateProductBooking(data).then( res => {
            if(res.data.statusCode) {
                getBookingList();
                setIsEditModal(false)
                notification.open({
                    description: res?.data?.responseData?.message,
                });
            }else{
                setIsEditModal(false)
                setSelectedProgram({})
                notification.open({
                    description: res?.data?.responseData?.message,
                });
                
            }
        })
        
    }
    const onCloseUpdateProductModal = () => { 
        setIsEditModal(false)
        setSelectedProgram({})
        setActiveItem({})
        setSelectedColor('')
        setSelectedSize('')
        setIsSizeAvailable(false)
    }
    return(
        <>

        <Modal
        size='lg'
            show={isEditmodal}
            animation={false}
            centered>
            <Modal.Header>
                <span> Update Product Booking Details</span>
                <span className="modal_close" onClick={onCloseUpdateProductModal}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>
               {modalLoader ? <div className="page_sm_loader"><ProgramLoader /></div>:
                (!showProductEditDropDown && missingCamperError) ? <h4 className='color_red'>{missingCamperError}</h4> :

                <div className='p-4'>
                    {showProductEditDropDown  && 
                        <>
                            <label>Update Product Details (Program Name - Booking Number - Camper Name )</label>
                            <Select
                                placeholder="Select Program- Booking Number - Camper Name"
                                onChange={e => setSelectedProgram(e)}
                                options={bookingList.filter(i => i.camperId !== selectedProgram?.camperId).map(i => ({...i, label: `${i.programName} - ${i.bookingNumber} - ${i.camperName}`, value: i._id}))}
                                value={(selectedProgram && Object.keys(selectedProgram)?.length> 0) ? {label:`${selectedProgram.programName} - ${selectedProgram.bookingNumber} - ${selectedProgram.camperName}`, value: selectedProgram._id} : '"Select Program- Booking Number - Camper Name"'} 
                                />
                            <hr/>
                        </>

                    }
                 {isSizeAvailable ?
                        <>
                        <label>Update Size </label>
                            <Select
                                placeholder="Select Size"
                                onChange={e => handleColorSelect(e)}
                                options={getSizes(activeItem.sizes)}
                                />
                            <hr/>
                        <label>Update Color</label>
                            <Select
                                placeholder="Select Color"
                                onChange={e => setSelectedColor(e)}
                                options={getColors2(activeItem.sizes, selectedSize.value)}
                                value={selectedColor}
                                />
                    
                    </>
                   : <div className='text-center'>
                       <h3> No Size/Color available</h3>
                     </div>
                        
                       }
                   {(showProductEditDropDown || isSizeAvailable) ? 
                    <div className='text-center'>    
                        <span type="button" className="btn btn-primary m-4" onClick = {SaveEditDetails}>Save</span> 
                    </div>
                    : ""
                    }
                </div>
                }
            </Modal.Body>
        </Modal>
        <div className="page_wrapper">

            <div className="page_card">


                <div className='container'>
                <div className="d-flex align-items-center justify-content-between page_card_header">
                        <h4>Product Bookings</h4>
                        <div className="search_wrap">
                            <i className="icon-search" />
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Search"
                                value={search}
                                onChange={e => (setSearch(e.target.value), setPage(1))} />
                        </div>
                    </div>
                </div>

                <div className="page_card_body">
                
                    <div className="table_filters">
                            
                        
                    </div>



                    {loader ? <div className="program_loader"><ProgramLoader /></div>  :
                        <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">
                        
                            <table className="table table-bordered table-striped" id="section-to-print">

                                <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Options</span></th>
                                        <th><span className="t_text">Booking Date</span></th>
                                        <th><span className="t_text">Program Name</span></th>
                                        <th><span className="t_text">Product Image</span></th>
                                        <th><span className="t_text">Product Name</span></th>
                                        <th><span className="t_text">Product sizes / colors</span></th>
                                        <th><span className="t_text">Quantity</span></th>
                                        <th><span className="t_text">Price</span></th>
                                        <th><span className="t_text">User Name</span></th>
                                        <th><span className="t_text">Camper Name</span></th>
                                        <th><span className="t_text">User Email</span></th>
                                        <th><span className="t_text">Transaction Id</span></th>
                                       
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        bookings.map((booking, i) => (
                                            <>
                                            <tr key={booking.transaction_id}>
                                                <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                                                <td>
                                                <span 
                                                        type="button" 
                                                        className="btn btn-outline-primary" 
                                                        onClick = {() => editExistingDetailsOfProduct(booking?.users?.userId, booking.bookingId,booking.programId, booking.itemId, booking.purchaseId, booking.camperId)}>
                                                        Update
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="t_text">{formatTimestamp(booking.orderedDate)}</span>
                                                    { booking?.subItems?.length > 0 && 
                                                    <button
                                                        className={`btn btn-link m-3`} 
                                                        onClick={() => handleToggleSubItem(booking?._id, booking?.subItems)}>
                                                        {selectedBookingId === booking._id ? 'Hide Add-ons' : 'Show Add-ons'}
                                                    </button>
                                                    }
                                                </td>
                                                <td>{booking?.programName ? booking?.programName : "N/A"}</td>
                                                <td><span className="t_text">
                                                <div className='product_wide_card_img'>
                                                    <img src={booking.image[0]} />
                                                </div>    
                                                </span></td>
                                                <td><span className="t_text">{booking.name}</span></td>
                                                <td><span className="t_text">
                                                    {booking.sizes} /  {booking.color}
                                                </span></td>
                                                <td><span className="t_text">{booking.quantity}</span></td>
                                                <td><span className="t_text">${booking.itemPrice.toFixed(2)}</span></td>
                                                <td><span className="t_text">{booking.users.name}</span></td>
                                                <td>
                                                    <span className="t_text">
                                                        {!(booking.bookingId && booking.programId && booking.camperId) ? 
                                                        booking.camperName : 
                                                        <Link to={`/bookings/${booking.bookingId}/${booking.camperId}/${booking.programId}`}>
                                                            {booking.camperName}
                                                        </Link> 
                                                        }
                                                    </span>
                                                    {/* {!(booking.bookingId && booking.programId && booking.camperId) &&
                                                        
                                                    } */}
                                                </td>
                                                <td><span className="t_text">{booking.users.email}</span></td>
                                                <td><span className="t_text">{booking.transaction_id}</span></td>
                                                
                                            </tr>
                                            
                                            {
                                            (booking?.subItems?.length > 0  && selectedBookingId === booking._id) &&
                                            booking?.subItems?.map( (item, index) => 
                                                <tr className="table-secondary" key={item.itemId}>
                                                    <td><span className="t_text">{`${i + 1}.${((index + 1) + ((page - 1) * limit))}`}</span></td>
                                                    <td></td>
                                                    <td><span className="t_text">{formatTimestamp(booking?.orderedDate)}</span></td>
                                                    <td>{booking?.programName ? booking?.programName : "N/A"}</td>
                                                    <td><span className="t_text">
                                                    <div className='product_wide_card_img'>
                                                      { item?.image && <img src={item?.image[0]} className='h-25' />}
                                                    </div>    
                                                    </span></td>
                                                    <td><span className="t_text">{item?.title}</span></td>
                                                    <td><span className="t_text">
                                                        {item?.sizes} /  {item?.color}
                                                    </span></td>
                                                    <td><span className="t_text">{item?.count}</span></td>
                                                    <td><span className="t_text">${item?.price?.toFixed(2)}</span></td>
                                                    <td><span className="t_text">{booking?.users?.name}</span></td>
                                                    <td><span className="t_text">{booking.bookingId && booking.programId && booking.camperId ? <Link to={`/bookings/${booking.bookingId}/${booking.camperId}/${booking.programId}`}>{booking.camperName}</Link> : booking.camperName}</span></td>
                                                    <td><span className="t_text">{booking?.users?.email}</span></td>
                                                    <td><span className="t_text">{booking?.transaction_id}</span></td>
                                                </tr>
                                            
                                            )}
                                            </>
                                        ))
                                    }
                                    
                                   
                                </tbody>
                            </table>

                        </div>

                        {
                            bookings?.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                        }
                        </div>
                    }

                    





                </div>
                <div className="d-flex justify-content-center mt-5">
                        <PaginatedComponent
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={count}
                            pageRangeDisplayed={3}
                            onPageChange={setPage}/>
                    </div> 

            </div>


        </div>
        </>
    )
}




const mapStateToProps = state => {
    let { userProfile } = state?.user;
    return {
        userProfile
    };
}
  
export default connect(mapStateToProps)(ProductBookings);

