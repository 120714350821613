import React, { useEffect, useState, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const CustomMultiSelectDropdown = ({list, placeHolder, calback, value, err}) => {
    const myRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);


    useEffect(() => {
        if(value?.length){
            let _value = []
            let isObj = value.map(i => i.value);

            if(isObj[0] === undefined){
                _value = value
            }else{
                _value = isObj
            }

            if(_value.includes('None')){
                setSelectedValue(['None']);
            }else{
                setSelectedValue(_value)
            }
        }

    }, [value])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }); 

    const handleClickOutside = e => {
        if(typeof myRef.current !== 'undefined' && myRef.current !== null ){
            if(!myRef.current.contains(e.target)){
                setIsOpen(false);
            }
        }
    };

    const handleToggle = item => {
        if(item === 'None'){
            setSelectedValue(['None']);
            calback(['None'])
        }else{
            let _selectedValue = [...selectedValue];
            if(_selectedValue.includes(item)){
                let filter_res = _selectedValue.filter(i => i !== item);
                setSelectedValue(filter_res);
                calback(filter_res)
            }else{
                _selectedValue.push(item);
                setSelectedValue(_selectedValue);
                calback(_selectedValue)
            }
        }  
    }

    const handleRemove = item => {
        let _selectedValue = [...selectedValue];
        let filter_res = _selectedValue.filter(i => i !== item);
        setSelectedValue(filter_res);
        calback(filter_res)
    }

    return (
        <div className='cm_multi_selects' ref={myRef}>
            <p className={`form-control ${err ? 'is-invalid' : ''}`} onClick={() => setIsOpen(true)}>
                {selectedValue.length ? selectedValue.map(item => <span key={item}>{item} <i onClick={() => handleRemove(item)} className="icon-cross" /> </span>) : placeHolder}
            </p>

            {err ? <OverlayTrigger placement="left" overlay={<Tooltip>{err}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

            {
                isOpen ? (
                    <div className='cm_multi_select_box'>
                        {list.map(item => (
                            <p onClick={() => selectedValue.includes('None') ? null : handleToggle(item)}>
                                <input
                                    key={item}
                                    disabled={selectedValue.includes(item) ? '' : selectedValue.includes('None')}
                                    checked={selectedValue.includes(item)}
                                    type="checkbox" />

                                <b>{item}</b>
                            </p>
                        ))}
                    </div>
                ) : ''
            }
        </div>
    )
}

export default CustomMultiSelectDropdown;