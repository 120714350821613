import React, { useEffect, useState } from 'react';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';


import { lineChartOptions } from '../../helper';
import { getDashboardService } from '../../services/homeService';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const colors = ['#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720','#842014', '#e39189', '#f9a720',]

const data = {
    labels: [0, 20, 30, 25, 40, 50],
    datasets: [{
        data: [0, 20, 30, 25, 40, 50],
        backgroundColor: 'rgba(249, 167, 32, 0.1)',
        borderColor: '#F9A720',
        borderWidth: 3,
        pointRadius: 0,
    }]
}


const data2 = {
    labels: [0, 20, 30, 25, 40, 50],
    datasets: [{
        data: [0, 20, 30, 25, 40, 50],
        backgroundColor: 'rgba(132, 32, 20, 0.1)',
        borderColor: '#842014',
        borderWidth: 3,
        pointRadius: 0,
    }]
}



const dataBar = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      {
        backgroundColor: '#F9A720',
        borderWidth: 0,
        hoverBackgroundColor: '#842014',
        data: [100, 200, 150, 300, 400]
      }
    ]
  };


const Dashboard = props => {

    const [monthlyReport, setMonthlyReport] = useState([]);
    const [registerTarget, setRegisterTarget] = useState('');
    const [purchaseTarget, setPurchaseTarget] = useState('');
    const [newCamper, setNewCamper] = useState('');
    const [totalCamper, setTotalCamper] = useState('');
    const [campName, setCampName] = useState('');
    const [camperAge, setCamperAge] = useState([]);
    const [mostBought, setMostBought] = useState([]);
    const [programHavingMaxBookingCounr, setProgramHavingMaxBookingCounr] = useState({});
    const [year, setYear] = useState(moment().format('YYYY'));


    const getDashboardData = () => {
        getDashboardService(`?campCode=${props.userProfile.campCode}&year=${year}`).then(res => {
            let monthlyReportArr =[];
            if(res?.data?.statusCode){
                setCamperAge(res.data.responseData.result.camperAge);
                setRegisterTarget(res.data.responseData.result.registerTarget);
                setPurchaseTarget(res.data.responseData.result.purchaseTarget);
                setMostBought(res.data.responseData.result.mostBought);
                setNewCamper(res.data.responseData.result.newCamper);
                setTotalCamper(res.data.responseData.result.totalCamper);
                setCampName(res.data.responseData.result.campName);
                setProgramHavingMaxBookingCounr(res.data.responseData.result.programHavingMaxBookingCounr[0] || {});
                let monthlyReportRes = res.data.responseData.result.monthlyReport;
                for(let i= 0; i < months.length; i++){
                  monthlyReportArr.push(findthemonth(months[i], monthlyReportRes))
                }

            }
            setMonthlyReport(monthlyReportArr);
        })
    }


    useEffect(() => {
        getDashboardData();
    }, [year])


const findthemonth = (month, monthrep) => {
       let numberofbooking =  monthrep.filter(item => item.month===month);
      if(numberofbooking.length){
        return numberofbooking[0].numberofbookings;
      }
      else{
        return 0;
      }
}

    return(
        <div className="body_container">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between section_header">
                    <h2>Dashboard {campName ? ` - ${campName}` : ''}</h2>
                </div>
            </div>



            <div className="container">
                <div className="row">
                    
                    <div className="col-lg-4">
                        <div className="dash_card mb-4 h_225">
                            <p>New Participants</p>
                            <div className="d-flex justify-content-between">
                                <h3>{newCamper}</h3>
                                <div className="sm_chart_wrap">
                                    <Line
                                        data={{
                                        labels: [0, newCamper],
                                        datasets: [{
                                            data: [0, newCamper],
                                            backgroundColor: 'rgba(249, 167, 32, 0.1)',
                                            borderColor: '#F9A720',
                                            borderWidth: 3,
                                            pointRadius: 0,
                                        }]
                                    }}
                                        height="100%"
                                        width="100%"
                                        options={lineChartOptions} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="dash_card mb-4 h_225">
                            <p>Total Registered</p>
                            <div className="d-flex justify-content-between">
                                <h3>{totalCamper}</h3>
                                <div className="sm_chart_wrap"><Line data={data2} height="100%"  width="100%" options={lineChartOptions} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="dash_card mb-4 h_225 cm_top_img">


                            <p className="abs_wrap">Top Rated Programs <Link to={`/director/camp/${programHavingMaxBookingCounr?._id?.programId}`}>View</Link> </p>
                            <div className="text-center mt-4"><img src={programHavingMaxBookingCounr.icon} /></div>
                        </div>
                    </div>





                    <div className="col-lg-6">
                        <div className="dash_card mb-4 pl-5 pr-5">
                            <p>Program based on registration target</p>
                            <div className="d-flex justify-content-between">
                                <div className="doughnut">
                                    <Doughnut
                                        options={{
                                            legend: {display: false},
                                            cutoutPercentage: 75,
                                        }}
                                        data={{
                                            labels: ['Registration Target', 'Total Registration'],
                                            datasets: [{
                                                label: [],
                                                data: [registerTarget.registrationTarget, registerTarget.totalRegistration],
                                                backgroundColor: ['#E8E8E8', '#E39189'],
                                                borderColor: [],
                                                borderWidth: 0,
                                                }]
                                            }} />
                                </div>

                                <ul>
                                    <li><b className="dot_ cm_pink" />Registered <span>{(registerTarget.totalRegistration / registerTarget.registrationTarget * 100).toFixed(2) }%</span></li>
                                </ul>

                                
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="dash_card mb-4 pl-5 pr-5">
                            <p>Purchase History </p>
                            <div className="d-flex justify-content-between">
                                <div className="doughnut">
                                    <Doughnut
                                        options={{
                                            legend: {display: false},
                                            cutoutPercentage: 75,
                                        }}
                                        data={{
                                            labels: ['Total Purchase Target', 'New Bookings'],
                                            datasets: [{
                                                label: [],
                                                data: [purchaseTarget.total, purchaseTarget.newBooking],
                                                backgroundColor: ['#F9A720', '#842014'],
                                                borderColor: [],
                                                borderWidth: 0,
                                                }]
                                            }} />


                                   
                                </div>

                                <ul>
                                    <li><b className="dot_ cm_primary" />Repeat Purchase <span>{((purchaseTarget.total / (purchaseTarget.total + purchaseTarget.newBooking)) * 100).toFixed(2)}%</span></li>
                                    <li><b className="dot_ cm_secondery" />New Purchase <span>{((purchaseTarget.newBooking / (purchaseTarget.total + purchaseTarget.newBooking)) * 100).toFixed(2)}%</span></li>
                                </ul>

                                
                            </div>
                        </div>
                    </div>











                    <div className="col-lg-6">
                        <div className="dash_card mb-4 pl-5 pr-5">
                            <p>Distribution of Registered Participant Age</p>
                            <div className="d-flex justify-content-between">
                                <div className="doughnut">
                                    <Doughnut
                                        options={{
                                            legend: {display: false},
                                            cutoutPercentage: 75,
                                        }}
                                        data={{
                                            labels: camperAge.map(item => ` ${item._id} Years Old`),
                                            datasets: [{
                                                label: [],
                                                data: camperAge.map(item => item.count),
                                                backgroundColor: camperAge.map((item, i) => colors[i]),
                                                borderColor: [],
                                                borderWidth: 0,
                                                }]
                                            }} />


                                   
                                </div>

                                <ul>
                                    {
                                        camperAge.map((item, i) => <li><b className="dot_" style={{backgroundColor: colors[i]}} />{item._id} Years Old </li>)
                                    }
                                </ul>

                                
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-6">
                        <div className="dash_card mb-4 pl-5 pr-5">
                            <p>Most sought after</p>
                            <div className="d-flex justify-content-between">
                                <div className="doughnut">
                                {
                                    mostBought.length ? 
                                
                                    <Doughnut
                                        options={{
                                            legend: {display: false},
                                            cutoutPercentage: 75,
                                        }}
                                        data={{
                                            labels: mostBought.map(item => ` ${item.programName}`),
                                            datasets: [{
                                                label: [],
                                                data: mostBought.map(item => item.viewCount === 0 ? 1 : item.viewCount),
                                                backgroundColor: mostBought.map((item, i) => colors[i]),
                                                borderColor: [],
                                                borderWidth: 0,
                                                }]
                                            }} />
                                : ''}
                                            </div>

                                <ul>
                                    {
                                       mostBought.length ? mostBought.map((item, i) => <li><b className="dot_" style={{backgroundColor: colors[i]}} />{item.programName}</li>) : ''
                                    }
                                </ul>

                                
                            </div>
                        </div>
                    </div>









                    <div className="col-lg-12">
                        <div className="dash_card mb-4 pl-5 pr-5">
                            <div className='d-flex justify-content-between'>
                                <p>Monthly Bookings Progress Report</p>
                                <DatePicker
                                    disabledDate={d => d.isSameOrAfter()}
                                    onChange={e => setYear(moment(e._d).format('YYYY'))} picker="year" />
                            </div>
                            
                            
                            <div className="d-flex justify-content-between">
                                {monthlyReport ? 
                               
                                <Bar
                                    data={{
                                        labels: months,
                                        datasets: [
                                        {
                                            backgroundColor: '#F9A720',
                                            borderWidth: 0,
                                            hoverBackgroundColor: '#842014',
                                            data: monthlyReport
                                        }
                                        ]
                                    }}
                                    width={100}
                                    height={400}
                                    options={{
                                        maintainAspectRatio: false,
                                        legend: {
                                            display: false
                                        },
                                        scales: {
                                            xAxes: [{
                                                barPercentage: 0.6,
                                                gridLines: {
                                                    color: "rgba(0, 0, 0, 0)",
                                                }
                                            }]
                                        }
                                    }}
                                    /> : <h3 className='not_found'>Data Not Found</h3> }


                                   
                                    

                                
                            </div>

                            <div className="yAxes">Bookings</div>
                            <div className="xAxes">({year}) Months</div>
                        </div>
                    </div>







                </div>
            </div>


        </div>
    )
}




/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { userProfile } = state.user;
    return{
        userProfile
    }
}


  
export default connect(mapStateToProps)(Dashboard);