import { Modal } from 'react-bootstrap';
import React from 'react'
import HtmlParser from '../../HTMLParser'

const EmailModal = ({showModal, setShowModal, message}) => {
  return (
    <Modal
    show={showModal}
    animation
    size="lg"
    centered
    >
      <Modal.Header>
        <Modal.Title>Subject : {message?.emailDetails?.subject || "No Subject"}</Modal.Title>
          <span className="modal_close" onClick={() => setShowModal()}><i className="icon-cross" /></span>
      </Modal.Header>
      <Modal.Body className="card p-3">

        
        <HtmlParser htmlString={message.message} />
      </Modal.Body>
    </Modal>
  )
}

export default EmailModal
