export const LacroseStatusMessage = Object.freeze({
    PENDING: `<small>Your USA Lacrosse membership is pending and you have not verified your age. Please log 
                into your <a href='https://www.usalacrosse.com/membership' target='_blank' rel="noopener noreferrer">USALacrosse.com</a> (not your child's if you are a parent) and complete 
                any remaining requirements to activate your membership.</small>`,
    INACTIVE: `<small>Your USA Lacrosse membership is inactive and you have not verified your age. Please log 
                into your <a href='https://www.usalacrosse.com/membership' target='_blank' rel="noopener noreferrer">USALacrosse.com</a> account (not your child's if you are a parent) and renew your 
                membership and submit your age verification materials.</small>`,
    SUSPENDED: `<small>Your USA Lacrosse membership is suspended due to failure to complete the age 
                verification process. Please log into your <a href='https://www.usalacrosse.com/membership' target='_blank' rel="noopener noreferrer">USALacrosse.com</a> account (not your child's if 
                you are a parent) and submit your age verification materials.</small>`,
    EXPIRED: `<small>Your USA Lacrosse membership has expired. Please visit <a href='https://www.usalacrosse.com/membership' target='_blank' rel="noopener noreferrer">https://www.usalacrosse.com</a> to renew your 
                membership and then resubmit this form.</small>`,
    ACTIVE:'',
  });


  export const USLMembershipExpMsg = `<small>Your USAL Membership will expire before Program end date. Please visit <a href='https://www.usalacrosse.com/membership' target='_blank' rel="noopener noreferrer">https://www.usalacrosse.com</a> to renew your 
                membership and then resubmit this form.</small>`

  export const uslMemSecCheck = (membershipExpDate,programEndDate ) => {
    const MExdateInMs = new Date(membershipExpDate).getTime();
    console.log(MExdateInMs,programEndDate,  MExdateInMs < programEndDate)
    return MExdateInMs < programEndDate
  }