import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Input } from 'antd';

import { toggleFeedModalAction, newFeedAction } from '../../store/feed';
import { createPost, uploadToS3 } from '../../services/feedService';
import { UploadLoader } from '../reuse/SVG';

const { TextArea } = Input;


const renderMedia = url => {
    let type = url.slice(url.indexOf(':') + 1, url.indexOf(';'));
    switch(type){
        case 'video/mp4':
            return <video src={url} type={type} autoPlay />;
        break;
        case 'video/webm':
            return <video src={url} type={type} autoPlay />;
        break;
        default:
            return <img src={url} />;
    }
}


const FeedModal = props => {
    const [caption, setCaption] = useState('');
    const [captionErr, setCaptionErr] = useState('');
    const [imageLocationArr, setImageLocationArr] = useState([]);
    const [imageLocationArrView, setImageLocationArrView] = useState([]);
    const [uploadErr, setUploadErr] = useState('');
    const [imgsLength, setImgsLength] = useState(3);
    const [uploadLoader, setUploadLoader] = useState(false);


    const handleChange = e => {
        setCaptionErr('');
        setCaption(e.target.value)
    }


    const closeModal = () => {
        props.toggleFeedModalAction();
        setCaption('');
        setCaptionErr('');
        setImageLocationArr([]);
        setImageLocationArrView([]);
        setUploadErr('');
    }


    const viewMore = () => setImgsLength(imageLocationArrView.length);


    const handleFileChange = e => {
        setUploadErr('');
        setCaptionErr('');
        if (e.target.files) {
            const files = Array.from(e.target.files);
            setUploadLoader(true);
            if((files.length + imageLocationArrView.length) > 6){
                setUploadErr('Please select maximum 6 media files')
            }else{

                Promise.all(files.map(file => {
                    return (new Promise((resolve, reject) => {
                        let params = new FormData();
                        params.append('uploadMedia', file);

                       return uploadToS3(params).then(res => {
                            if(res?.data?.statusCode){
                                resolve(res.data.responseData[0]);
                            }   
                        })

                    }));
                }))
                .then(images => {
                    setImageLocationArr([...imageLocationArr, ...images]);
                    setUploadLoader(false);
        
                }, error => {        
                    console.error(error);
                });



                Promise.all(files.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                .then(images => {
                    setImageLocationArrView([...imageLocationArrView, ...images])
        
                }, error => {        
                    console.error(error);
                });
            }       
        }

    }



    const handleRemove = ind => {
        let tempImageLocationArrView = [...imageLocationArrView];
        let tempImageLocationArr = [...imageLocationArr];

        tempImageLocationArrView.splice(ind, 1);
        tempImageLocationArr.splice(ind, 1);

        setImageLocationArrView(tempImageLocationArrView);
        setImageLocationArr(tempImageLocationArr);
    }



    const handleValidate = () => {
        let validate = false;
        if(imageLocationArrView.length || caption.length){
            validate = true
        }else{
             setCaptionErr('Caption or media is required');
             validate = false
        }
        return validate;
    }



    const handleSubmit = () => {
        if(handleValidate()){
            setUploadLoader(true);
            let params = new FormData();
                if(props.userProfile && props.userProfile.UserType === 2){
                    params.append('campCode', props.userProfile.campCode);
                }
                params.append('caption', caption);
                params.append('media', JSON.stringify(imageLocationArr));
                params.append('createdDate', new Date().getTime());
            createPost(params).then(res => {
                setUploadLoader(false);
                if(res?.data?.statusCode){
                    closeModal();
                    props.newFeedAction(true);
                }
            })
        }
        
    }


    return (
            <Modal
                dialogClassName="feed_modal"
                show={props.feedModal}
                animation={false}
            >
                <Modal.Body>
                    <div className="modal_header">
                        <h3>Create Post</h3>
                        <span className="modal_close" onClick={closeModal}><i className="icon-cross"/></span>
                    </div>
                    <div className="own_avtor">
                        <img src={props.userProfile ? props.userProfile.profileImage ? props.userProfile.profileImage : require('../../assets/img/user.png') : require('../../assets/img/user.png')}/>
                        <h3>{props.userProfile ? props.userProfile.name : ''}</h3>
                    </div>
                    <div className="form-group">
                        <TextArea
                            className="create_post_text"
                            value={caption}
                            onChange={handleChange}
                            placeholder="What's in your mind?"
                            autoSize={{ minRows: 2, maxRows: 8 }}
                        />

                        {captionErr ? <span className="cm_err_msg on_feed">{captionErr}</span> : ''}
                    </div>
        
                    <div className="post_upload_wrapper w-100">
                        
                        {
                            imageLocationArrView.length ? 
                            (
                                <div className={imageLocationArrView.length > 2 ? 'more_then' : 'd-flex'}>
                                    {imageLocationArrView.slice(0, imgsLength).map((item, i) => <div className="feed_m_item" key={i}><i className="icon-cross" onClick={() => handleRemove(i)} />{renderMedia(item)}</div>)}
                                    {imageLocationArrView.length > 3 ? <div className="feed_m_item" onClick={viewMore}><h2>+{imageLocationArrView.length - 3}</h2></div> : ''}
                                </div>
                            ) : (
                                <Fragment>
                                    <input type="file" accept="image/x-png,image/jpeg,image/webp,image/avif, .mp4, .webm, .ogg" multiple onChange={handleFileChange}  />
                                    <div className="post_up_icon">
                                        <i className="icon-button"/>
                                        <p>Upload Image</p>
                                    </div>
                                </Fragment>
                            )
                        }
                    </div>
                    {uploadErr ? <span className="cm_upload_err">{uploadErr}</span> : ''}


                    <div className={`d-flex justify-content-${imageLocationArrView.length ? 'between' : 'end'}`}>
                        {imageLocationArrView.length ? <div className="cm_post_media">
                                                            <i className="icon-add-media" />
                                                            <input type="file" multiple onChange={handleFileChange}  />
                                                        </div> : ''}
                        
                        <button disabled={uploadLoader} onClick={handleSubmit} className="btn btn-primary pl-5 pr-5 rounded-0">Post</button>
                        {uploadErr ? '' : uploadLoader ? <div className="upload_loader"><UploadLoader /><span>Upload...</span></div> : ''}
              
                    </div>

                </Modal.Body>
            </Modal>
        );
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { feedModal } = state.feed;
    let { userProfile } = state.user;
    return {
        feedModal,
        userProfile
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleFeedModalAction: () => dispatch(toggleFeedModalAction()),
    newFeedAction: params => dispatch(newFeedAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(FeedModal);