import React, { useState, Fragment, useEffect } from 'react';
import { getInboxService, starConversationService } from '../../services/inboxService';
import { Empty, notification } from 'antd';
import { connect } from 'react-redux';
import { activeUserChatWindowAction, getChatUserListCalbackAction } from '../../store/inbox';
import { getHtml, removeHtmlTags } from '../../helper';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { Col, Container, Row } from 'react-bootstrap';
import { ProgramLoader } from '../reuse/SVG';
import { unreadCountAction } from '../../store/chat';
import PaginatedComponent from '../common/Pagination/PaginationComponent';
import { getcheduleConversationsListService } from '../../services/campersServices';
import UserCard from './UserCard';

const limit = 10
const findIsStarred = (item, userId) => {
    if (item.hasOwnProperty('starredMsg')) {
        if (item?.starredMsg?.length) return item.starredMsg.find(i => i.userId !== userId).isStarred;
        return false;
    }
    return false;
};
const ChatUserList = props => {
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');
    const [chatTab, setChatTab] = useState(1);
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [totalUser, setTotalUser] = useState(0)
    const userId = props.userProfile?._id

    const getList = (pageNo=1) => { 
        setLoader(true)
        if(chatTab === 4){
            getcheduleConversationsListService().then(res => {
                if (res?.data?.statusCode) {
                    setList(res.data.responseData.result)
                    setTotalUser(res.data.responseData?.count || 0);
                    setLoader(false);
                }
            })
        return 
        }
        let query = `?pageNo=${pageNo}&limit=${limit}&chatType=${chatTab}&search=${search}`
        getInboxService(query).then(res => {
            props.getChatUserListCalbackAction(0);

            if (res?.data?.statusCode) {
                setList(res.data.responseData.result)
                setTotalUser(res.data.responseData?.count || 0);
                props.unreadCountAction();
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false)
            console.log(error)
        }).finally(() => {
            setLoader(false)
        })
    }

    useEffect(() => {
        setPage(1)
        getList(1)
    }, [search,chatTab,  props.getChatUserListCalback,]);

    const handlePageNoChange = (page) => {
        getList(page)
        setPage(page)
    }
    const handleClick = (item) => {
        props.activeUserChatWindowAction(item);
    }
    const toggleStar = (e, item) => {
        let isStarred = !findIsStarred(item, userId);
        e.stopPropagation()
        const params = {
            groupId: item.groupId,
            isStarred,
            userId,
        }
        starConversationService(params).then(res => {
            if (res?.data?.statusCode) {
                notification.open({
                    description: `⭐ Successfuly ${isStarred ? "starred" : "un-starred"} conversation for ${item?.userId[0]?.name}.`,
                });
                getList();
            } else {
                notification.open({
                    description: `Something went wrong please try again.`,
                });
            }

        })
    };

    const handleChatTab =(tabNo) => {
        setChatTab(tabNo);
        // setPage(1);
    }
    return (
        <div style={{maxHeight:'calc(100% - 295px)'}}>
            <div className="chat_user_list_wrapper">

                <div className="contact_search">
                    <i className="icon-search" />
                    <input
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        placeholder="Search" />
                </div>

                {/* <div className='me_pro_user'>
<img src={props?.userProfile?.profileImage || require('../../assets/img/user.png')} />
<p>{props?.userProfile?.name}</p>
</div> */}

                <div className='chat_tabs'>
                    <span className={chatTab === 1 ? 'active' : ''} onClick={() => handleChatTab(1)}>All</span>
                    <span className={chatTab === 2 ? 'active' : ''} onClick={() => handleChatTab(2)}>Unread</span>
                    <span className={chatTab === 3 ? 'active' : ''} onClick={() => handleChatTab(3)}>Starred</span>
                    <span className={chatTab === 4 ? 'active' : ''} onClick={() => handleChatTab(4)}>Scheduled</span>
                </div>
                {loader ?
                    <div
                        className="program_loader d-flex align-items-center justify-content-center"
                        style={{ height: '100%' }}
                    >
                        <div>
                            <ProgramLoader />
                        </div>
                    </div> :
                    <ul>
                        {
                            list.length ? list.map(item => (
                                <li 
                                    key={item.groupId} 
                                    className={`d-flex align-items-center 
                                    ${item.groupId === props?.activeUserChatWindow?.groupId ? 'active' : ''}`} 
                                    onClick={item?.scheduleDateTime ? "" :() => handleClick(item)}
                                >
                                    {(item.type === 1) && (item.userId?.length > 0) &&
                                        <UserCard
                                            item={item} 
                                            findIsStarred={findIsStarred} 
                                            toggleStar={toggleStar}
                                            userId= {userId}
                                            timezone={props?.userProfile?.timezone}
                                        />
                                    }

                                    {item.type === 2 ? <Fragment>
                                        <img className="chat_user_img" src={item.groupImage ? item.groupImage : require('../../assets/img/user.png')} alt="" />
                                        <p>
                                            {item.groupName}
                                            <br />
                                            <small>{removeHtmlTags(item.lastMessage)}</small>
                                        </p>

                                    </Fragment> : ''}


                                </li>
                            )) : <li className="cm_empty"><Empty description="Data Not Found" /></li>
                        }
                    </ul>

                }


            </div>
            <div className="">
                <PaginatedComponent
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalUser}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageNoChange}
                    isSearch={false}
                />
            </div>
        </div>

    )
}



const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { getChatUserListCalback, activeUserChatWindow } = state.inbox;
    return {
        userProfile,
        getChatUserListCalback,
        activeUserChatWindow
    };
}

const mapDispatchToProps = dispatch => ({
    getChatUserListCalbackAction: params => dispatch(getChatUserListCalbackAction(params)),
    activeUserChatWindowAction: params => dispatch(activeUserChatWindowAction(params)),
    unreadCountAction: () => dispatch(unreadCountAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatUserList);