import React, {Fragment, useEffect, useState} from 'react';
import {couponlist} from '../../services/campersServices';
import { ProgramLoader } from '../reuse/SVG';
import moment from 'moment';
import {notification } from 'antd';
import PaginatedComponent from '../common/Pagination/PaginationComponent';

const bgColors = ['#F9A720', '#48A7FC', '#9DB638'];

const MyCoupons = () => {
    let currentDate = new Date().getTime();

    const limit = 10;

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);


    const getList = () => {
        let query = `?page=${page - 1}&limit=${limit}`;
        couponlist(query).then(res => {
            setLoading(false)
            if(res?.data?.statusCode){
                setList(res.data.responseData.result || []);
                setTotalCount(res.data.responseData.count || 0);
            }
        })
    }


    useEffect(() => {
        getList();
    },[page])

    return(
        <div className="content_wrapper booking_page">
            <h2>My Coupons</h2>
            {
              loading ? (
                <div className="program_loader">
                    <ProgramLoader />
                </div>
              ) : list.map((item, i) => (
                    <div className={`card coupon_card mb-4 ${item.endDate < currentDate ? 'coupon_expired_bg' : ''}`} key={item}>
                        <div className="off_msg" style={{backgroundImage: `url(${require('../../assets/img/small-owl.svg')})`, backgroundColor: item.type ===1 ?   bgColors[2] : bgColors[0] }}>
                            <h2>{ item.type ===1 ? `$${item.value} OFF` : `${item.value}% OFF` }</h2>
                            <p>Book now and Get { item.type ===1 ? `$${item.value} off` : `${item.value}% off` }</p>
                            {item.camps.length ? <p>Camps: &nbsp; {item.camps.map((camp, i) => <span key={i}>{camp}{i !== item.camps.length - 1 ? ', ' : ''}</span>)}</p> : ''}
                            {item.programname.length ? <p>Programs: &nbsp; {item.programname.map((progrm, i)=><span key={i}>{progrm}{i !== item.programname.length - 1 ? ', ' : ''}</span>)}</p> : ''}
                        </div>
                        <div className="reedem_wrap">
                            {item.endDate < currentDate ? (
                                <div className='coupon_expired'>
                                    <p>Coupon expired</p>
                                    <h4> {moment.utc(item.endDate).format('DD MMMM YYYY')}</h4>
                                </div>
                            ) : (
                                <Fragment>
                                    <p>Use by:</p>
                                    <h4> {moment.utc(item.endDate).format('DD MMMM YYYY')}</h4>
                                    <button onClick={() => (navigator.clipboard.writeText(item.couponCode), notification.open({message: 'Coupon Copied'}))} className="btn btn-primary rounded-0">Copy Coupon Code</button>
                                </Fragment>
                            )}
                           
                        </div>
                    </div>
                ))
            }


                <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={4}
                        onPageChange={setPage}/>
                </div> 
        </div>
    )
}

export default MyCoupons;