import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { addZero } from '../bookings/bookingHelper'
// import { FaEye, FaEyeSlash } from'react-icons/fa'
import { createCustomerCardService } from '../../services/programService'
import { AES } from 'crypto-js'
import settings from '../../config/settings'
import CustomMonthPicker from './CustomMonthPicker'
import SecureInput from './SecureInput'

const AddNewCard = ({ setIsNewCard,email, userId, campCode, getCards, setResErr, eyeColor=""}) => {
    const [addCardBtnLoader, setAddCardBtnLoader] = useState(false);

    const [cardNo, setCardNo] = useState('');
    const [cardNoErr, setCardNoErr] = useState('');
    const [isVisibleCard, setIsVisibleCard] = useState(false);

    const [expDate, setExpDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [expDateErr, setExpDateErr] = useState('');

    const [cvc, setCvc] = useState('');
    const [isVisibleCVC, setIsVisibleCVC] = useState(false);
    const [cvcErr, setCvcErr] = useState('');

    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastName, setLastName] = useState('');

    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');

    const [addPrimary, setAddPrimary] = useState(false);
    
    const handleAddCardSubmit = (e) => {
        setResErr('');
        e.preventDefault();
        e.stopPropagation()
        let validate = true;
        if(cardNo === ''){
            setCardNoErr('card number error');
            validate = false;
        }else if (!(cardNo.length === 18 || cardNo.length === 19)) {
            setCardNoErr('card number lenth error');
            validate = false;    
        }else if(cardNo.includes('_')){
            setCardNoErr('card number lenth error');
            validate = false;    
        }

        if(expDate === ''){
            setExpDateErr('card expired date error');
            validate = false;
        }

        if(zipCode === ''){
            setZipCodeErr('zip code error');
            validate = false;
        }

        if(cvc === ''){
            setCvcErr('cvc error');
            validate = false;
        }else if(cvc.includes('_')){
            setCvcErr('cvc lenth error');
            validate = false;    
        }

        if(firstName === ''){
            setFirstNameErr('first name error');
            validate = false;
        }else if(firstName.length > 50){
            setFirstNameErr('First name is to large max limit 50')
            validate = false;
        }

        if(validate){

            setAddCardBtnLoader(true);
            let _cardNo = cardNo.replace(/\s/g,'');
            const encrypted_cardNo = AES.encrypt(_cardNo, settings.api.mySecretKey).toString();
            const encrypted_cvc = AES.encrypt(cvc, settings.api.mySecretKey).toString();

            let params = {
                cardNo: encrypted_cardNo,
                expDate,
                month,
                year,
                cvc: encrypted_cvc,
                firstName,
                lastName,
                email,
                isWeb: 1,
                campCode,
                zipCode,
                primary:String(addPrimary),
            }
            if(userId){
                params.userId = userId
            }

           
            createCustomerCardService(params).then(res => {
                setAddCardBtnLoader(false);
                if(res?.data?.statusCode){
                    setCardNo('');
                    setExpDate('');
                    setCvc('');
                    setFirstName('');
                    setLastName('');
                    setIsNewCard(false);
                    getCards();
                }else{
                    setResErr(res.data.error.responseMessage)
                }
                
            })
        }
    }


    const handleCardNumberChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCardNo(formattedNumber);
        setCardNoErr('');
      };

      const handleCvcChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCvc(formattedNumber);
        setCvcErr('');
      };

    const handleDateChange = (date) => {
            if (date) {
                const newMonth = addZero(date.month() + 1);
                const newYear = date.year().toString();
                setMonth(newMonth);
                setYear(newYear);
                setExpDate(`${newMonth}${newYear}`);
                setExpDateErr('');
            } else {
                setMonth('');
                setYear('');
                setExpDate(null);
            }
        };
 
          const toggleVisibility = (type) => {
            switch(type){
                case 1:
                    setIsVisibleCard(!isVisibleCard);
                    return;
               case 2:
                setIsVisibleCVC(!isVisibleCVC);
                return;
               default:
                return ""
            }
            
          };


    return (
        <form onSubmit={handleAddCardSubmit} className="card_inputs add_card_form">
            <div className="form-group">
                <label>Card Number</label>
                <SecureInput
                    eyeColor={eyeColor}
                    value={cardNo}
                    name={"cardNumber"}
                    placeholder={"XXXX XXXX XXXX XXXX"}
                    maxLength={19}
                    onChange={handleCardNumberChange}
                />
                {cardNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cardNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
            </div>

            <div className="row">
                <div className="col form-group">
                    <label>Expiry Date</label>

                    <CustomMonthPicker handleDateChange={handleDateChange}/>
                    {expDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{expDateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                </div>
               
                <div className="col form-group">
                    <label>CVC</label>
                    <SecureInput
                        eyeColor={eyeColor}
                        value={cvc}
                        name={"cvc"}
                        placeholder={"XXX"}
                        maxLength={4}
                        onChange={handleCvcChange}
                    />
                    {cvcErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cvcErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                </div>
            </div>

            <div className="form-group">
                <label>Cardholder First Name</label>
                <input
                    value={firstName}
                    type="text"
                    className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                    placeholder="Cardholder First Name"
                    onKeyPress={() => setFirstNameErr('')}
                    onChange={e => setFirstName(e.target.value)} />

                {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
            </div>

            <div className="form-group">
                <label>Cardholder Last Name</label>
                <input
                    value={lastName}
                    type="text"
                    className="form-control"
                    placeholder="Cardholder Last Name"
                    onChange={e => setLastName(e.target.value)} />
            </div>

            <div className="form-group">
                <label>Zip Code</label>
                <input
                    type="text"
                    className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                    placeholder="Zip Code"
                    onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))} />

                {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
            </div>
            <div className='form-group'>
                <div className="check_box_wrapper cm_dark_check pt-1">

                    <input
                        checked={addPrimary}
                        onChange={() => setAddPrimary(prev => !prev)}
                        type="checkbox"
                    />
                    <span className="check_box"><span></span></span>
                    <label>Make this card primary</label>
                </div>
            </div>

            <div className="d-flex justify-content-between">
                <span onClick={() => setIsNewCard(false)} className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                <button type="submit" className={`btn btn-primary rounded-0 w-100  ${addCardBtnLoader ? 'btnLoader' : ''}`}>Add</button>
            </div>
        </form>
    )
}

export default AddNewCard
