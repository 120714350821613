import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { Select } from 'antd';
import moment from 'moment';
import { OverlayTrigger, Tooltip, Dropdown, Modal } from 'react-bootstrap';

import { filterCabinParamsAction } from '../../store/cabin';
import { saveCabinIdAction } from '../../store/ui';
import { counselorlist, getProgramBookingDetail } from '../../services/directorServices';
import { crateCabinService, getCabinService } from '../../services/otherService';
import cabinLogic from './cabinLogic';
import { CustomNumber, CustomSelect } from '../reuse/FormControls';
import { ProgramLoader } from '../reuse/SVG';

const { Option } = Select;


const splitArray = (arrayData, arraySize) => {
    return [].concat.apply([],
        arrayData.map(function (elem, i) {
            return i % arraySize ? [] : [arrayData.slice(i, i + arraySize)];
        })
    );
}


const chunkArray = (myArray, chunk_size) => {
    let results = [];
    while (myArray.length) {
        results.push(myArray.splice(0, chunk_size));
    }   
    return results;
}


const CabinOrganizer = props => {
    const history = useHistory();
    const [expend, setExpend]                          = useState(false);
    const [counselors, setCounselors]                  = useState([]);
    const [cabins, setCabins]                          = useState([]);
    const [campers, setCampers]                        = useState([]);
    const [tempCampers, setTempCampers]                = useState([]);
    const [programDetail, setProgramDetail]            = useState('');
    const [loader, setLoader]                          = useState(true);
    const [isMovable, setIsMovable]                    = useState(true);
    const [moveFromCabinIndex, setMoveFromCabinIndex]  = useState('');
    const [rearrangeModal, setRearrangeModal]          = useState(false);
    const [groupCount, setGroupCount]                   = useState('');
    const [groupCountErr, setGroupCountErr]             = useState('');
    const [groupByGender, setGroupByGender]             = useState(false);
    const [groupByAge, setGroupByAge]                   = useState(false);
    const [groupByGrade, setGroupByGrade]               = useState(false);
    const [ageDropDown, setAgeDropDown]                 = useState([]);
    const [ageTemp, setAgeTemp]                         = useState([]);
    const [age, setAge]                                 = useState([]);
    const [grade, setGrade]                             = useState([]);
    const [gradeTemp, setGradeTemp]                     = useState([]);
    const [gradeDropDown, setGradeDropDown]             = useState([]);
    const [apiRes, setApiRes]                           = useState('');



    useEffect(() => {
        let query = `?programId=${props.match.params.id}`
        getProgramBookingDetail(query).then(res => {
            setLoader(false);
            if(res?.data?.statusCode){
                setCampers(res.data.responseData.result.camperList);
                setTempCampers(res.data.responseData.result.camperList);
                setProgramDetail(res.data.responseData.result);
                let tempArr = [];
                for(let i = res.data.responseData.result.minAge; i <= res.data.responseData.result.maxAge; i++ ){
                    tempArr.push(i);
                }
                setAgeDropDown(tempArr);
                setGradeDropDown(res.data.responseData.result.grade);
            }
        })


        counselorlist('').then(res => {
            if(res?.data?.statusCode){
                setCounselors(res.data.responseData.result.counselor)
            }
        })

        return () => {
            props.filterCabinParamsAction('');
        }
    }, []);



    useEffect(() => {
        if(props.filterData){
            let finalData = cabinLogic(campers, props.filterData);
            if(!props.filterData.age && !props.filterData.grade && !props.filterData.gender){
                let temp_copy2 = [];
                temp_copy2=chunkArray(finalData,props.filterData.groupCount);
                let tempObjArr = temp_copy2.map(item => ({
                    campers: item,
                    cabinName: '',
                    counselorId: []
                }))
                setCabins(tempObjArr);    
            }
            else{
            let temp = []
            if (finalData instanceof Array) {
                if (finalData !== 0)
                    temp.push(finalData)
            }
            else {
                for (const property in finalData) {
                    if (finalData[property] instanceof Array) {
                        if (finalData[property] !== 0)
                            temp.push(finalData[property])
                    }
                    else {
                        for (const property1 in finalData[property]) {
                            if (finalData[property][property1] instanceof Array) {
                                if (finalData[property][property1].length !== 0)
                                    temp.push(finalData[property][property1])
                            }
                            else {
                                for (const property2 in finalData[property][property1]) {
                                    if (finalData[property][property1][property2] instanceof Array) {
                                        if (finalData[property][property1][property2].length !== 0)
                                            temp.push(finalData[property][property1][property2])
                                    }
                                }
                            }
                        }
                    }
                }
            }


            let temp_copy = [].concat(temp);
            let finalList = [];
            temp_copy = temp_copy.map((item, i) => {
                if (item.length) {
                    finalList.push(splitArray(temp_copy[i], props.filterData.groupCount))
                }
                return null
            })
            temp_copy = [].concat.apply([], finalList);

            let tempObjArr = temp_copy.map(item => ({
                campers: item,
                cabinName: '',
                counselorId: []
            }))


            setCabins(tempObjArr);
        }
            

        }else {

            let query = `?programId=${props.match.params.id}`;
            getCabinService(query).then(res => {
                if(res?.data?.statusCode){
                    if(res.data.responseData.result){
                        setCabins(res.data.responseData.result.cabins);
                        setIsMovable(true)
                    }else {
                        history.push(`/director-camps`);
                    }
                    
                }else{
                    history.push(`/director-camps`);
                }
      
            })
        }
        
    }, [campers]);



    const handleNameChange = (e, i) => {
        setApiRes('');
        let tempCabins = [...cabins];
        tempCabins[i].cabinName = e.target.value;
        tempCabins[i].nameErr = '';
        setCabins(tempCabins)
    }


    const handleCounselorChange = (e, i) => {
        let tempCabins = [...cabins];
        tempCabins[i].counselorId = e;
        tempCabins[i].counselorErr = '';
        setCabins(tempCabins)
    }



    const handleCabinSubmit = () => {
        // let validate = cabins.filter(item => (item.cabinName === '' || item.counselorId.length === 0 || item.counselorId === null));
        // if(validate.length === 0){
        //     setLoader(true);
        //     let params = {
        //         programId: props.match.params.id,
        //         cabins: JSON.stringify(cabins)
        //     }

        //     crateCabinService(params).then(res => {
        //         setLoader(false);
        //     })
        // }else {
        //     let arr = cabins.map(item => ({
        //         ...item,
        //         nameErr: item.cabinName === '' ? 'name is required' : '',
        //         counselorErr: item.counselorId === '' ? 'counselor is required' : '',
        //     }));

        //     setCabins(arr);
        // }


        let params = {
            programId: props.match.params.id,
            cabins: JSON.stringify(cabins)
        }
        setLoader(true);
        crateCabinService(params).then(res => {
            if(res?.data?.statusCode){
                setApiRes('Cabin updated successfully');
            }
            setLoader(false);
        })

    }



    const handleMoveChange = cabinIndex => {
        setMoveFromCabinIndex(cabinIndex);
    }

    const handleMoveSelect = (cabinIndex, selectedCabinIndex, camperIndx) => {
        setApiRes('');
        let tempCabins = [...cabins];
        tempCabins[selectedCabinIndex].campers.push(tempCabins[cabinIndex].campers[camperIndx]);
        tempCabins[cabinIndex].campers.splice(camperIndx, 1);
        setCabins(tempCabins);
    }






    const handleRearrangeCabinSubmit = e => {
        e.preventDefault();

        let params = {
            groupCount,
            gender: groupByGender ? 1 : 0
        };
        if(groupByAge){
            params.age = age
        }
        if(groupByGrade){
            params.grade = grade
        }
        

        setExpend(false);


        if(groupCount){
            props.filterCabinParamsAction(params);
            let finalData = cabinLogic(tempCampers, params);
            if(!params.age && !params.grade && !params.gender){
                let temp_copy2 = [];
                temp_copy2=chunkArray(finalData, params.groupCount);
                let tempObjArr = temp_copy2.map(item => ({
                    campers: item,
                    cabinName: '',
                    counselorId: []
                }))
                setCabins(tempObjArr); 
                setRearrangeModal(false)   
            }
            else{
            let temp = []
            if (finalData instanceof Array) {
                if (finalData !== 0)
                    temp.push(finalData)
            }
            else {
                for (const property in finalData) {
                    if (finalData[property] instanceof Array) {
                        if (finalData[property] !== 0)
                            temp.push(finalData[property])
                    }
                    else {
                        for (const property1 in finalData[property]) {
                            if (finalData[property][property1] instanceof Array) {
                                if (finalData[property][property1].length !== 0)
                                    temp.push(finalData[property][property1])
                            }
                            else {
                                for (const property2 in finalData[property][property1]) {
                                    if (finalData[property][property1][property2] instanceof Array) {
                                        if (finalData[property][property1][property2].length !== 0)
                                            temp.push(finalData[property][property1][property2])
                                    }
                                }
                            }
                        }
                    }
                }
            }
            let temp_copy = [].concat(temp);
            let finalList = [];
            temp_copy = temp_copy.map((item, i) => {
                if (item.length) {
                    finalList.push(splitArray(temp_copy[i], params.groupCount))
                }
            })
            temp_copy = [].concat.apply([], finalList);

            let tempObjArr = temp_copy.map(item => ({
                campers: item,
                cabinName: '',
                counselorId: []
            }))


            setCabins(tempObjArr);
            setRearrangeModal(false)
        }

        }else {
            setGroupCountErr('Group count is required')
        }
    }

    

    const closeModal = () => {
        setRearrangeModal(false);
        setGroupByAge(false);
        setGroupByGrade(false);
        setAgeDropDown([]);
        setGradeDropDown([]);
        setGradeTemp([]);
        setAgeTemp([]);
        setGrade([]);
        setAge([]);
        setGroupCount('');
    }



    const addMoreAgeGroup = () => {
        if(ageTemp.length){
            let selectAge = [...age, ageTemp.map(i => i.value)]
            setAge(selectAge);
            setAgeTemp([]);
            let tempArr = [].concat(...selectAge);
            let tempAgeDrop = ageDropDown.filter(item => tempArr.indexOf(item) === -1);
            setAgeDropDown(tempAgeDrop);
        }
    }


    const addMoreGradeGroup = () => {
        if(gradeTemp.length){
            let selectGrade = [...grade, gradeTemp.map(i => i.value)]
            setGrade(selectGrade);
            setGradeTemp([]);
            let tempArr = [].concat(...selectGrade);
            let tempGradeDrop = gradeDropDown.filter(item => tempArr.indexOf(item) === -1);
            setGradeDropDown(tempGradeDrop);
        }
    }



    return(
        <div className="body_container pb-4 cabin_org_wrapper">
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="container">
                <div className="row">
                    <div className="col-md-2" />
                    <div className="col-md-8">

                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h3>Groups</h3>
                                <p className="mb-0 "><b className="color_black">{programDetail.programName},</b> Date : {moment(programDetail.startDate).format('DD MMMM YYYY')}</p>
                                <p className="mb-0">Gender : {programDetail.gender === 1 ? 'Male' : ''}{programDetail.gender === 2 ? 'Female' : ''} {programDetail.gender === 3 ? 'Co-Ed' : ''}  &nbsp; &nbsp; Age : {programDetail.ageGroup} &nbsp; &nbsp; Program Code: {programDetail.programCode}</p>

                                {
                                    props.filterData ? <Fragment>
                                         <p className="mt-3"><b>Groups are organised based on -</b><br />
                                Group Count: {props?.filterData?.groupCount} <br />
                                Gender: {props?.filterData?.gender ? 'Yes' : 'No'} <br />
                                {props?.filterData?.age ? <Fragment>Age: <div className="drop_chip d-inline-block">{props?.filterData?.age.map(item => <span>{item.map(i => <span>{i}</span>)} </span>)}</div></Fragment> : ''}
                                {props?.filterData?.grade ? <Fragment>Grade: <div className="drop_chip d-inline-block">{props?.filterData?.grade.map(item => <span>{item.map(i => <span>{i}</span>)} </span>)}</div></Fragment> : ''}
                                </p></Fragment> : ''
                                }
                               
                                
                                
                            </div>
                            {
                                props.userProfile.UserType === 4 ? '' : <div>
                                <span onClick={() => setRearrangeModal(true)} className="btn btn-primary rounded-0 pl-4 pr-4">Rearrange Groups</span>
                            </div>
                            }
                            
                        </div>


    

                        {
                            cabins.map((item, cabinIndex) => (
                                <div className="cabin_card" key={cabinIndex}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="color_black">Groups Number : {cabinIndex + 1}</div>
                                        <div><i className="icon-download" /></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="color_black">Add Groups Name :</label>
                                                <input
                                                    disabled={props.userProfile.UserType === 4 ? true : false}
                                                    type="text"
                                                    value={item.cabinName}
                                                    onChange={e => handleNameChange(e, cabinIndex)}
                                                    className={`form-control rounded-0 ${item.nameErr ? 'is-invalid' : ''}`}
                                                    placeholder="Enter name" />

                                                    {item.nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="color_black">Add Personnel :</label>
                                                <Select
                                                    mode="multiple"
                                                    disabled={props.userProfile.UserType === 4 ? true : false}
                                                    className={`cm_auto_height form-control ${item.counselorErr ? 'is-invalid' : ''} ${props.userProfile.UserType === 4 ? 'pr-0' : ''}`}
                                                    placeholder="Select"
                                                    value={item.counselorId}
                                                    onChange={e => handleCounselorChange(e, cabinIndex)}
                                                >   
                                                    {counselors.map(item => <Option key={item._id} value={item._id}>{item.name ? item.name : item.email}</Option>)}
                                                </Select>

                                                {item.counselorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.counselorErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>
                                    </div>


                                    {/* {
                                        isMovable ? <Fragment>
                                    <div className="drop_chip">Age: <span>{item.campers.map(itemAge => <span>{itemAge.age}</span>)}</span></div>
                                    <div className="drop_chip">Grade: <span>{item.campers.map(itemGrade => <span>{itemGrade.grade}</span>)}</span></div>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            {props?.filterData?.age ? <div className="drop_chip">Age: <span>{item.campers.map(itemAge => <span>{itemAge.age}</span>)}</span></div> : ''}
                                    {props?.filterData?.grade ? <div className="drop_chip">Age: <span>{item.campers.map(itemGrade => <span>{itemGrade.grade}</span>)}</span></div> : ''}
                                        </Fragment>
                                    } */}

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Total Participants : {item.campers.length}</div>
                                        <div><button onClick={() => setExpend({...expend, [cabinIndex]: !expend[cabinIndex]})} className="btn btn-primary btn-sm rounded-0 pl-4 pr-4">{expend[cabinIndex] ? 'Expand' : 'Collapse'}</button></div>
                                    </div>

                                    <div className="drop_chip criteria"><b>Age: </b><span>{item.campers.map(itemAge => <span>{itemAge.age}</span>)}</span></div>
                                    <div className="drop_chip criteria"><b>Grade: </b><span>{item.campers.map(itemGrade => <span>{itemGrade.currentGrade}</span>)}</span></div>

                                   

                                    

                                    <div className={`cabin_users ${expend[cabinIndex] ? '' : 'cm_expend'}`}>
                                        <ul>
                                            {item.campers.map((camper, camperIndex) => (
                                                <li key={camperIndex} style={{zIndex: 99 - camperIndex}}> 
                                                <span>
                                                    {(camper.gender === 1 && 'M') || (camper.gender === 2 && 'F') || (camper.gender === 3 && 'O')} </span><Link onClick={() => props.saveCabinIdAction(`/cabin-organizer/${props.match.params.id}`)} className="link_color_black" to={`/bookings/${camper.bookingId}/${camper.camperId}/${camper.programId}`}>{camper.camperName}</Link><span>{camper.medical === 'None' || camper.medical === '"None"' ? '' : <i className="icon-hospital" />}
                                                    {camper.allergy === 'None' || camper.allergy === '"None"' ? '' : <i className="icon-fish" />}
                                                    {camper.allergyDeadly === 1 && <i className="icon-deadly-allergy" />}
                                                    {camper.allowDocumentCamper === 1 ? <i className="icon-handshake" /> : ''}

                                                    </span>
                                                    
                                                    {props.userProfile.UserType === 4 ? '' : cabins.length > 1 ? isMovable ? <Dropdown
                                                                    onClick={() => handleMoveChange(cabinIndex)}
                                                                    drop="left">
                                                                    <Dropdown.Toggle className="btn btn-secondary btn-sm">Move To</Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        {cabins.map((item2, i) => i !== moveFromCabinIndex ? <Dropdown.Item onClick={() => handleMoveSelect(cabinIndex, i, camperIndex)} href="javascript:void(0);" key={i}>{item2.cabinName ? item2.cabinName : `Group ${i + 1}`}</Dropdown.Item> : '')}
                        
                                                                    </Dropdown.Menu>
                                                                    </Dropdown> : '' : ''}
                                                    
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))
                        }


                        

                        {
                                props.userProfile.UserType === 4 ? '' : <div className="text-center">
                                    {apiRes ? <p className='res_msg'>{apiRes}</p> : ''}
                                <span className="btn btn-primary" onClick={handleCabinSubmit}>Submit</span>
                            </div>
                            }

                    </div>
                </div>      
            </div>


            <Modal
                dialogClassName="pickup_modal"
                show={rearrangeModal}
                centered >
                
                <Modal.Body>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                <h5 className="text-center mt-4 mb-4">Group Organizer</h5>
                <form onSubmit={handleRearrangeCabinSubmit}>
                    <div className="form-group">
                        <label>How many participants would you like in each group?</label>
                        <CustomNumber
                            value={groupCount}
                            min={0}
                            step={1}
                            precision={0}
                            error={groupCountErr}
                            change={e => (setGroupCount(e), setGroupCountErr(''))}
                            pHolder="Enter" />
                    </div>

                    <div className="form-group">
                        <div className="check_box_wrapper cm_dark_check">
                            <input
                                type="checkbox"
                                onChange={() => setGroupByGender(!groupByGender)}
                                chacked={groupByGender} />
                            <span className="check_box"><span></span> Do you want group by gender?</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="check_box_wrapper cm_dark_check">
                            <input
                            type="checkbox"
                            checked={groupByAge}
                            onChange={() => (setGroupByAge(!groupByAge), setGroupByGrade(false))}
                            chacked={groupByAge} />
                            <span className="check_box"><span></span> Do you want group by age?</span>
                        </div>
                    </div>

                    {
                        groupByAge ? <div style={{position: 'relative', zIndex: '999'}}>
                            
                            <div className="form-group">
                            <label>Create age groups</label>
                            <div className="drop_chip">{age.map(item => <span>{item.map(i => <span>{i}</span>)} </span>)}</div>
                            <CustomSelect
                                isMulti
                                change={e => setAgeTemp(e)}
                                value={ageTemp}
                                options={ageDropDown.map(item => ({value: item, label: item}))}
                                closeMenuOnSelect={false} />

                                {ageTemp.length ? <div className="text-right mt-3"><span onClick={addMoreAgeGroup} className="btn btn-primary btn-sm">Add</span></div> : ''}
                                
                        </div>
                        </div> : '' 
                    }


                    <div className="form-group">
                        <div className="check_box_wrapper cm_dark_check">
                            <input
                            type="checkbox"
                            checked={groupByGrade}
                            onChange={() => (setGroupByGrade(!groupByGrade), setGroupByAge(false))}
                            chacked={groupByGrade} />
                            <span className="check_box"><span></span> Do you want group by grade?</span>
                        </div>
                    </div>

                    {
                        groupByGrade ? <div className="">
                            <div className="form-group">
                            <label>Create grade groups</label>
                            <div className="drop_chip">{grade.map(item => <span>{item.map(i => <span>{i}</span>)} </span>)}</div>
                            <CustomSelect
                                isMulti
                                change={e => setGradeTemp(e)}
                                value={gradeTemp}
                                options={gradeDropDown.map(item => ({value: item, label: item}))}
                                closeMenuOnSelect={false} />
                            
                            {gradeTemp.length ? <div className="text-right mt-3"><span onClick={addMoreGradeGroup} className="btn btn-primary btn-sm">Add</span></div> : ''}
                        </div>
                        </div> : '' 
                    }

                    

                    <button type="submit" className="btn btn-primary btn-block rounded-0">Submit</button>
                </form>
                </Modal.Body>
            </Modal>  

        </div>
    )
}



const mapStateToProps = state => {
    let { filterData } = state.cabin;
    let { userProfile } = state.user;

    return {
        filterData,
        userProfile
    };
}

const mapDispatchToProps = dispatch => ({
    filterCabinParamsAction: params => dispatch(filterCabinParamsAction(params)),
    saveCabinIdAction: params => dispatch(saveCabinIdAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinOrganizer);