import React, { useReducer, Fragment, useRef, useEffect, useState } from 'react';
import { jsPDF } from "jspdf";
import SignaturePad from 'react-signature-canvas';
import moment from 'moment';
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { uploadMedia, userPDFCreate } from '../../services/campersServices';
import { ProgramLoader } from '../reuse/SVG';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  

const Waiver1 = () => {
    let query = useQuery();

    let camperId = query.get("camperId");
    let userId = query.get("userId");

    const signatureRef1 = useRef();
    const signatureRef2 = useRef();
    const [showSucssModal, setShowSucssModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            w_1: '',
            w_2: '',
            w_3: moment().format("MMM Do YYYY"),
            w_4: '',
            w_5: '',
            w_6: moment().format("MMM Do YYYY"),
        }
    );



    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
    }

    const pdfGen = () => {
        setLoader(true);
        const doc = new jsPDF();

        
            doc.setFontSize(9);
            doc.setFont("helvetica", "normal")
            doc.text("Tigard Lacrosse Club", 100, 20, null, null, "center");
            doc.text("Safe Start/Return to Play – COVID-19", 100, 25, null, null, "center");
            doc.setFont('helvetica', "bold");
            doc.setFontSize(10);
            doc.text("Waiver/Release for Communicable Diseases Including COVID-19", 100, 32, null, null, "center");
            doc.setFontSize(8);
            doc.setFont("helvetica", "normal")
            doc.text("In consideration of being allowed to participate in a Tigard Lacrosse event, the undersigned acknowledges,", 20, 39, null, null);
            doc.text("appreciates, certifies and agrees that:", 20, 43, null, null);
            doc.text("1. My participation includes possible exposure to and illness from infectious diseases, including but not limited", 20, 50, null, null);
            doc.text("to MRSA, influenza, and COVID-19. While particular rules and personal discipline may reduce this risk, the risk of", 20, 54, null, null);
            doc.text("serious illness, injury, and death does exist.", 20, 58, null, null);
            doc.text("2. If I have a pre-existing health condition, exposure to COVID-19, or any other infectious disease may be more", 20, 65, null, null);
            doc.text("likely to cause serious illness, injury, or death;", 20, 69, null, null);
            doc.text("3. Tigard Lacrosse Club cannot ensure that all other participants, including coaches and volunteers, are taking", 20, 76, null, null);
            doc.text("precautionary measures to mitigate risks to ensure the health and safety of other participants, coaches, and", 20, 80, null, null);
            doc.text("volunteers, and therefore, participation in a Tigard Lacrosse Club Event involves risk of exposure to infectious disease;", 20, 84, null, null);
            doc.text("and,", 20, 88, null, null);
            doc.text("4. I KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS, both known and unknown, EVEN IF ARISING FROM THE", 20, 95, null, null);
            doc.text("NEGLIGENCE OF THE RELEASEES or others, and assume full responsibility for my participation; and,", 20, 99, null, null);
            doc.text("5. I certify that I have not recently tested positive for, and am not exhibiting symptoms of COVID-19, which include a", 20, 106, null, null);
            doc.text("cough, shortness of breath or difficulty breathing, loss of taste or smell, headache, chills, muscle or body aches and/or sore", 20, 110, null, null);
            doc.text("throat", 20, 114, null, null);
            doc.text("6. I certify that I do not have a household family member/roommate who has recently tested positive for or", 20, 121, null, null);
            doc.text("exhibited the above-referenced symptoms of COVID-19.", 20, 125, null, null);
            doc.text("7. I willingly agree to comply with all recommendations provided by Tigard Lacrosse Club to ensure safe play. If,", 20, 132, null, null);
            doc.text("however, I observe any unusual or significant hazard during my presence or participation, I will remove myself from", 20, 136, null, null);
            doc.text("participation and bring such to the attention of the nearest coach, staff member or volunteer, or official immediately;", 20, 140, null, null);
            doc.text("and,", 20, 144, null, null);
            doc.text("8. I, for myself and on behalf of my heirs, assigns, personal representatives and next of kin, HEREBY RELEASE AND HOLD", 20, 151, null, null);
            doc.text("HARMLESS Tigard Lacrosse CLub, and their officers, officials, agents, and/or employees, other participants, volunteers,", 20, 155, null, null);
            doc.text("sponsoring agencies, sponsors, advertisers, and if applicable, owners and lessors of premises used to conduct the event ", 20, 159, null, null);
            doc.text("(“RELEASEES”), WITH RESPECT TO ANY AND ALL ILLNESS, INJURY, DISABILITY, DEATH, or loss or damage to person or", 20, 163, null, null);
            doc.text("property, WHETHER ARISING FROM THE NEGLIGENCE OF RELEASEES OR OTHERWISE, to the fullest extent permitted by law.", 20, 167, null, null);
            doc.setFont('helvetica', "bold");
            doc.text("I HAVE READ THIS RELEASE OF LIABILITY AND ASSUMPTION OF RISK AGREEMENT, FULLY UNDERSTAND ITS", 20, 174, null, null);
            doc.text("TERMS, UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL RIGHTS BY SIGNING IT, AND SIGN IT FREELY AND ", 20, 178, null, null);
            doc.text("VOLUNTARILY WITHOUT ANY INDUCEMENT.", 20, 182, null, null);
            doc.setFont("helvetica", "normal")
            doc.text("Name of participant:", 20, 189, null, null);
            doc.text(userInput.w_1, 50, 189, null, null);
            doc.line(50, 189, 150, 189);
            doc.text("Participant signature:", 20, 196, null, null);
            //doc.text(userInput.w_2, 50, 196, null, null);
            if(userInput.w_2){
                doc.addImage(userInput.w_2, "JPEG", 100, 196);
            }
            //doc.line(50, 196, 150, 196);
            doc.text("Date signed:", 20, 203, null, null);
            doc.text(userInput.w_3, 40, 203, null, null);
            doc.line(40, 203, 150, 203);
            doc.setFont('helvetica', "bold");
            doc.text("FOR PARTICIPANTS OF MINORITY AGE (UNDER AGE 18 AT THE TIME OF REGISTRATION)", 20, 210, null, null);
            doc.setFont("helvetica", "normal")
            doc.text("This is to certify that I, as parent/guardian, with legal responsibility for this participant, have read and explained the provisions in", 20, 217, null, null);
            doc.text("this waiver/release to my child/ward including the risks of presence and participation and his/her personal responsibilities for", 20, 221, null, null);
            doc.text("adhering to the rules and regulations for protection against communicable diseases. Furthermore, my child/ward understands", 20, 225, null, null);
            doc.text("and accepts these risks and responsibilities. I for myself, my spouse, and child/ward do consent and agree to his/her release", 20, 229, null, null);
            doc.text("provided above for all the Releasees and myself, my spouse, and child/ward do release and agree to indemnify and hold", 20, 233, null, null);
            doc.text("harmless the Releasees for any and all liabilities incident to my minor child’s/ward’s presence or participation in these activities", 20, 237, null, null);
            doc.text("as provided above, EVEN IF ARISING FROM THEIR NEGLIGENCE, to the fullest extent provided by law.", 20, 241, null, null);
            doc.text("Name of parent/guardian:", 20, 248, null, null);
            doc.text(userInput.w_4, 60, 248, null, null);
            doc.text("Parent guardian/signature:", 20, 255, null, null);
            if(userInput.w_5){
                doc.addImage(userInput.w_5, "JPEG", 100, 255);
            }
            doc.text("Date signed:", 20, 262, null, null);
            doc.text(userInput.w_6, 40, 262, null, null);
     


        

        let cmFile = new File([doc.output('blob')], 'waiver', {type: 'application/pdf'});

        const data = new FormData();
        data.append('file', cmFile);
        uploadMedia(data).then(res => {
            if(res.data.responseData.result.fileUrl!==undefined){
               let obj = {
                userId,
                camperId,
                fileUrl: res.data.responseData.result.fileUrl,
                fileType: '1'
               }

               userPDFCreate(obj).then(res => {
                if(res?.data?.statusCode){
                    setLoader(false);
                    setShowSucssModal(true);
                }
               })
            }  
        })

    }

    const handleSignatureRef1Save = name => {
        if(userInput[name] === ''){
            setUserInput({[name]: signatureRef1.current.getTrimmedCanvas().toDataURL('image/png')})
        }
    }

    const handleSignatureRef2Save = name => {
        if(userInput[name] === ''){
            setUserInput({[name]: signatureRef2.current.getTrimmedCanvas().toDataURL('image/png')})
        }
    }

  
    return (
        <div className="pdf_box pdf_box_in_mob">

        <Modal show={showSucssModal} centered>
            <Modal.Body>
                <p className="text-center">
                    Your PDF upload successfully. Please go to the App
                </p>
            </Modal.Body>
        </Modal>

            <div className="text-center">
                <p>Tigard Lacrosse Club</p>
                <p>Safe Start/Return to Play – COVID-19</p>
                <h3>Waiver/Release for Communicable Diseases Including COVID-19</h3>
            </div>

            <p>In consideration of being allowed to participate in a Tigard Lacrosse event, the undersigned acknowledges, </p>
            <p>appreciates, certifies and agrees that:</p>
                    
            <ol>
                <li>My participation includes possible exposure to and illness from infectious diseases, including but not limited to MRSA, influenza, and COVID-19. While particular rules and personal discipline may reduce this risk, the risk of serious illness, injury, and death does exist.</li>
                <li>If I have a pre-existing health condition, exposure to COVID-19, or any other infectious disease may be more likely to cause serious illness, injury, or death;</li>
                <li>Tigard Lacrosse Club cannot ensure that all other participants, including coaches and volunteers, are taking precautionary measures to mitigate risks to ensure the health and safety of other participants, coaches, and volunteers, and therefore, participation in a Tigard Lacrosse Club Event involves risk of exposure to infectious disease; and,</li>
                <li>I KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS, both known and unknown, EVEN IF ARISING FROM THE NEGLIGENCE OF THE RELEASEES or others, and assume full responsibility for my participation; and,</li>
                <li>I certify that I have not recently tested positive for, and am not exhibiting symptoms of COVID-19, which include a cough, shortness of breath or difficulty breathing, loss of taste or smell, headache, chills, muscle or body aches and/or sore throat.</li>
                <li>I certify that I do not have a household family member/roommate who has recently tested positive for or exhibited the above-referenced symptoms of COVID-19.</li>
                <li>I willingly agree to comply with all recommendations provided by Tigard Lacrosse Club to ensure safe play. If, however, I observe any unusual or significant hazard during my presence or participation, I will remove myself from participation and bring such to the attention of the nearest coach, staff member or volunteer, or official immediately; and,</li>
                <li>I, for myself and on behalf of my heirs, assigns, personal representatives and next of kin, HEREBY RELEASE AND HOLD HARMLESS Tigard Lacrosse CLub, and their officers, officials, agents, and/or employees, other participants, volunteers, sponsoring agencies, sponsors, advertisers, and if applicable, owners and lessors of premises used to conduct the event (“RELEASEES”), WITH RESPECT TO ANY AND ALL ILLNESS, INJURY, DISABILITY, DEATH, or loss or damage to person or property, WHETHER ARISING FROM THE NEGLIGENCE OF RELEASEES OR OTHERWISE, to the fullest extent permitted by law.</li>
            </ol>

            <h3>I HAVE READ THIS RELEASE OF LIABILITY AND ASSUMPTION OF RISK AGREEMENT, FULLY UNDERSTAND ITS TERMS, UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL RIGHTS BY SIGNING IT, AND SIGN IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT</h3>

            <p>Name of participant: 
            <input
                type="text"
                name="w_1"
                value={userInput.w_1}
                onChange={handleChange}/>
            </p>

            <p>Participant signature: 

            <div className="sign_box" style={{display: 'block'}}>
                <SignaturePad
                    ref={signatureRef1}
                    canvasProps={{width: 350, height: 70, className: 'sigCanvas'}} />

<div className="sign_box_clear">
                    {userInput.w_2 ? '' : <button disabled={userInput.w_2 ? true : false} className="btn btn-primary btn-sm btn_1" onClick={() => handleSignatureRef1Save('w_2')}>Save</button> }
                    <button className="btn btn-dark btn-sm btn_2" onClick={() => (signatureRef1.current.clear(), setUserInput({w_2: ''}))}>Clear</button>
                </div> 
            </div>

            
            
            </p>

            <p>Date signed: 
            <input
                name="w_3"
                value={userInput.w_3}
                disabled={true}
                onChange={handleChange} />
                </p>

            <h3>FOR PARTICIPANTS OF MINORITY AGE (UNDER AGE 18 AT THE TIME OF REGISTRATION)</h3>
            <p>This is to certify that I, as parent/guardian, with legal responsibility for this participant, have read and explained the provisions in this waiver/release to my child/ward including the risks of presence and participation and his/her personal responsibilities for adhering to the rules and regulations for protection against communicable diseases. Furthermore, my child/ward understands and accepts these risks and responsibilities. I for myself, my spouse, and child/ward do consent and agree to his/her release provided above for all the Releasees and myself, my spouse, and child/ward do release and agree to indemnify and hold harmless the Releasees for any and all liabilities incident to my minor child’s/ward’s presence or participation in these activities as provided above, EVEN IF ARISING FROM THEIR NEGLIGENCE, to the fullest extent provided by law.</p>

            <p>Name of participant: 
            <input
                name="w_4"
                value={userInput.w_4}
                onChange={handleChange}/>
            </p>

            <p>Parent guardian/signature: 

            <div className="sign_box" style={{display: 'block'}}>
                <SignaturePad
                    ref={signatureRef2}
                    canvasProps={{width: 365, height: 70, className: 'sigCanvas'}} />

<div className="sign_box_clear">
                    {userInput.w_5 ? '' : <button disabled={userInput.w_5 ? true : false} className="btn btn-primary btn-sm btn_1" onClick={() => handleSignatureRef2Save('w_5')}>Save</button> }
                    <button className="btn btn-dark btn-sm btn_2" onClick={() => (signatureRef2.current.clear(), setUserInput({w_5: ''}))}>Clear</button>
                </div> 
                
            </div>

            
            {/* <input
                name="w_5"
                value={userInput.w_5}
                onChange={handleChange}
                style={{width: '365px'}} /> */}
            </p>

            <p>Date signed: 
            <input
                type="text"
                name="w_6"
                value={userInput.w_6}
                disabled={true}
                onChange={handleChange} />
            </p>
        

    
    
    <div className="d-flex mt-3">
        <button className="btn btn-dark w-100">Cancel</button>
        <button onClick={pdfGen} className="btn btn-primary w-100 ml-3">Save</button>
    </div>

    {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
</div>

  )
}

export default Waiver1;