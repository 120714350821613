import React, { useState, useEffect, Fragment, useRef } from 'react';
import moment from 'moment';
import { Modal, Dropdown } from 'react-bootstrap';
import { Input } from 'antd';
import { connect } from 'react-redux';
import { Picker } from 'emoji-mart'

import { createCommentService, getCommentsService, postLikeService, getFeedDetailService, deleteCommentService } from '../../services/feedService';
import { RoundLoader } from '../reuse/SVG';
import { viewLoginModalAction } from '../../store/ui';
import { Link } from 'react-router-dom';
import Share from '../static/Share';
import { convertTimeToCurrentTimeZone } from '../../helper';

const { TextArea } = Input;

const renderMedia = file => {
    let { url } = file;
    let type = url.slice(url.lastIndexOf('.') + 1, url.length);
    switch(type){
        case 'mp4':
            return <Fragment><i className="icon-play-button" /><video src={url} type={type} /></Fragment>;
        break;    

        case 'm4v':
            return <Fragment><i className="icon-play-button" /><video src={url} type={type} /></Fragment>;
        break;

        case 'webm':
            return <Fragment><i className="icon-play-button" /><video src={url} type={type} /></Fragment>;
        break;

        default:
            return <img src={url} />;
    }
}

const renderMedia2 = url => {
    let type = url.slice(url.lastIndexOf('.') + 1, url.length);
    switch(type){
        case 'mp4':
            return <video src={url} type={type} autoPlay />;
            break;
        case 'm4v':
            return <video src={url} type={type} autoPlay />;
            break;

        case 'webm':
            return <video src={url} type={type} autoPlay />;
            break;
        default:
            return <img src={url} />;
    }
}

const FeedCard = props => {
    const myRef = useRef();
    const [postId, setPostId] = useState('');
    const [caption, setCaption] = useState('');
    const [commentCount, setCommentCount] = useState('');
    const [isLike, setIsLike] = useState('');
    const [likeCount, setLikeCount] = useState('');
    const [media, setMedia] = useState([]);
    const [user, setUser] = useState('');
    const [created, setCreated] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const [show, setShow] = useState(false);
    const [galleryImgs, setGalleryImgs] = useState([]);
    const [currentImage, setCurrentImage] = useState('');
    const [currentIndex, setCurrentIndex] = useState('');
    const [page, setpage] = useState(0);
    const [loader, setLoader] = useState(false);
    const [likeLoader, setLikeLoader] = useState(false);
    const [campName, setCampName] = useState('');
    const [icon, setIcon] = useState('');
    const [showEmoji, setShowEmoji] = useState(false);


    useEffect(() => {
        setPostId(props.feed._id);
        setCaption(props.feed.caption);
        setCommentCount(props.feed.commentCount);
        setIsLike(props.feed.isLike);
        setLikeCount(props.feed.likeCount);
        setMedia(props.feed.media);
        setUser(props.feed.user);
        setCreated(props.feed.created);
        setCreatedDate(props.feed.createdDate);
        setCampName(props.feed.campName ? props.feed.campName : '');
        setIcon(props.feed.icon ? props.feed.icon : '');
        setComments(props.feed.comment ? [props.feed.comment] : []);

    }, [])



    const handleModalOpen = (obj, index) => {
        let tempGall = obj.map(item => item.url)
        setShow(true);
        setGalleryImgs(tempGall);
        setCurrentIndex(index);
        setCurrentImage(tempGall[index])
    }



    const handlePrev = () => {
        if(currentIndex > 0){
            setCurrentImage(galleryImgs[currentIndex - 1]);
            setCurrentIndex(currentIndex - 1 );
        }
    }


    const handleNext = () => {
        if(currentIndex < galleryImgs.length - 1){
            setCurrentImage(galleryImgs[currentIndex + 1]);
            setCurrentIndex(currentIndex + 1 );
        }
    }


    const handleComment = e => {
        let key = e.which || e.keyCode;

        if(comment.trim() !== ''){
            if(!e.shiftKey){
                if(key === 13) {
                    
                    if(props.loggedIn){
                        let params = {userId: props.userProfile._id, postId, comment, createdDate: new Date().getTime()};
                        setComment('');
                        createCommentService(params).then(res => {
                            if(res?.data?.statusCode){
                                setComments([res.data.responseData.result, ...comments]);
                                let query = `?userId=${props.userProfile._id}&postId=${postId}`;
                                getFeedDetailService(query).then(res => {
                                    if(res?.data?.statusCode){
                                        setCommentCount(res.data.responseData.result.commentCount);
                                        setComments([res.data.responseData.result.comment, ...comments]);
                                    }
                                    
                                })
                                
                            }
                        })
                    }else{
                        props.viewLoginModalAction();
                    }
                    
                }
            }
        }
    }





    const viewMoreComment = () => {
        setLoader(true);
        let query = `?userId=${user._id || ''}&postId=${postId}&page=${page}&limit=10`;

        getCommentsService(query).then(res => {
            setLoader(false);
            if(res?.data?.statusCode){
                if(comments.length === 1 || page === 0){
                    setComments(res.data.responseData.result)
                }else{
                    setComments([...comments, ...res.data.responseData.result])
                }
            }
        })
    }


    useEffect(() => {
        viewMoreComment()
    }, [page])



    const handleLike = val => {
        if(props.loggedIn){
            setLikeLoader(true);
            if(val){
                setLikeCount(likeCount - 1);
                setIsLike(false);
            }else{
                setLikeCount(likeCount + 1);
                setIsLike(true);
            }

            let params = {
                userId: props.userProfile._id,
                postId,
                isLike: val ? '0' : '1'
            }

            postLikeService(params).then(res => {
                setLikeLoader(false);
            })
        }else{
            props.viewLoginModalAction();
        }

        
    }



    const selectEmoji = e => {
        setComment(`${comment} ${e.native}`)
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
      });

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            setShowEmoji(false);
        }
      };



    const handleCommentDelete = commentId => {
        let tempComments = comments.filter(item => item._id !== commentId);
        setComments(tempComments);

        let params = `/?postId=${postId}&commentId=${commentId}&userId=${props.userProfile._id}`;

        deleteCommentService(params).then(res => {
            if(res?.data?.statusCode){
                let query = `?userId=${props.userProfile._id}&postId=${postId}`;
                getFeedDetailService(query).then(res => {
                    if(res?.data?.statusCode){
                        setCommentCount(res.data.responseData.result.commentCount);
                        // if(res.data.responseData.result.comment){
                        //     setComments(res.data.responseData.result)
                        // }else{
                        //     setComments([]);
                        // }
                        
                    }
                    
                })
            }
        })
    }





    return(
        <div className="feed_card">
            <div className="feed_head">
                
                {
                    icon ? <img src={icon} /> : 
                    <img src={user.profileImage ? user.profileImage : require('../../assets/img/user.png')} />
                }


                {
                    campName ? <h3>{campName}, {props.feed.user ? props.feed.user.UserType === 2 ? `Director - ${props.feed.user.name}` : '' : ''}</h3> : 
                    <h3><Link to={`/user/${user._id}`}>{user?.name ? user.name : 'Unnamed'}</Link></h3>
                }
                <time>
                    {convertTimeToCurrentTimeZone(createdDate, props?.userProfile?.timezone,'MMM DD')} at {
                     convertTimeToCurrentTimeZone(createdDate, props?.userProfile?.timezone,'h:mm a')
                    } 
                </time>

                {props?.userProfile?._id === user._id ? <i title="Delete Feed" className="icon-delete post_delete_btn" onClick={() => props.handleFeedDelete(postId)} /> : ''}
            </div>

            <div className="feed_content"><p>{caption}</p></div>

            <div className="feed_media">
                <div className={media.length > 2 ? 'more_then' : 'd-flex'}>
                    {media ? media.slice(0, 3).map((item, i) => <div className="feed_m_item" onClick={() => handleModalOpen(media, i)} key={item._id}>{renderMedia(item)}</div>) : <img src={require('../../assets/img/slider-1.jpg')} />}
                    {media.length > 4 ? <div className="feed_m_item" onClick={() => handleModalOpen(media, 4)} ><h2>+{media.length - 3}</h2></div> : ''}
                </div>
            </div>

            <div className="feed_actions">

                <div className="d-flex justify-content-between">
                    <p><i className="icon-logo-icon" /> {likeCount}</p>
                    <p>{commentCount} comments</p>
                </div>

                <div className="d-flex justify-content-between lcs_wrap">
                    {likeLoader ? <span className="like_loader"><RoundLoader /></span> : <span className={isLike ? 'cm_liked' : ''} onClick={() => handleLike(isLike)}><i className="icon-logo-icon" /> Like</span>}
                    
                    <span><i className="icon-comment" /> Comment</span>
                    
                    <Share pageUrl={`Feed=${postId}`} />
                    
                    
                </div>

            </div>


            <div className="feed_comment">
                <div className="write_comment">
                    <img src={props.userProfile ? props.userProfile.profileImage ? props.userProfile.profileImage : require('../../assets/img/user.png') : require('../../assets/img/user.png')} />
                    <input
                        type='text'
                        className="form-control cm_comment_text"
                        value={comment}
                        onKeyPress={handleComment}
                        onChange={e => setComment(e.target.value)}
                        placeholder="Write a comment"/>

                    <div className="comment_option">
                        <i className="icon-smile-line" onClick={() => setShowEmoji(true)} />
                        <div ref={myRef}>
                        {showEmoji ? <Picker  set='apple' onClick={selectEmoji} /> : ''}
                        </div>
                        
                    </div>
                </div>

                {
                    comments.length ? comments.map(item => (
                    <div className="comment_msg" key={item._id}>
                        <img src={item.fromUserInfo && item.fromUserInfo.profileImage ? item.fromUserInfo.profileImage : require('../../assets/img/user.png')} />
                        <div className="comment_text">
                            <h6>{item.fromUserInfo && item.fromUserInfo.name ? item.fromUserInfo.name : 'Unnamed'}</h6>
                        <time>{moment.utc(item.createdDate).format('MMM DD')} at {moment(item.createdDate).format('h:mm a')}</time>
                        {props?.userProfile?._id === item.fromUserInfo._id ? <i title="Delete Comment" className="icon-delete comment_delete_btn" onClick={() => handleCommentDelete(item._id)} /> : ''}
                        <p>{item.comment}</p>
                        </div>
                    </div>
                    )) : ''
                }

                {loader ? <div className="cm_r_loader"><RoundLoader /></div> : ''}

                {
                    commentCount ? <div className={`d-flex justify-content-${(commentCount <= comments.length) ? 'end' : 'between'} mt-4`}>
                                        {(commentCount <= comments.length) ? '' : <span className="view_more" onClick={() => setpage(page + 1)}>View more comments</span>}
                                        <span>{comments.length} of {commentCount}</span>
                                    </div> : ''
                }
                

            </div>





            <Modal className="gall_popup" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    {currentIndex > 0 ? <div className="gal_prev" onClick={handlePrev}></div> : ''}
                    {currentImage && renderMedia2(currentImage)}
                    {currentIndex < galleryImgs.length - 1 ? <div className="gal_next" onClick={handleNext}></div> : ''}
                </Modal.Body>
            </Modal>





        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { loggedIn, userProfile } = state.user;
    return {
        loggedIn,
        userProfile
    };
}

const mapDispatchToProps = dispatch => ({
    viewLoginModalAction: () => dispatch(viewLoginModalAction()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(FeedCard);